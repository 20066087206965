import axios from 'axios';
import env from '../utils/env.utils';

export const analyticsApi = axios.create({
  baseURL: env.analyticsApiUrl,
});

const api = axios.create({
  baseURL: env.apiUrl,
  withCredentials: true,
});

export default api;
