import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { DEFAULT_PRIMARY_COLOR, DEFAULT_SECONDARY_COLOR } from '../../../common/constants';

const SVGNetworkError: React.FC = () => {
  const theme = useContext(ThemeContext);

  const [primary, setPrimary] = useState(DEFAULT_PRIMARY_COLOR);
  const [secondary, setSecondary] = useState(DEFAULT_SECONDARY_COLOR);
  useEffect(() => {
    if (theme?.primary) setPrimary(theme.primary);
    if (theme?.secondary) setSecondary(theme.secondary);
  }, [theme]);

  return (
    <svg
      width="393"
      height="237"
      viewBox="0 0 393 237"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 95.7259L51.8805 79L60.1597 83.8664"
        stroke={secondary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M25.7387 182.691L2.89331 195.735L28.094 209.728"
        stroke={secondary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path d="M392.298 104.842L363 88" stroke={secondary} strokeWidth="2" strokeMiterlimit="10" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.288 24.1668L151.8 2L190.312 24.1668L151.8 46.5575L113.288 24.1668Z"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.288 24.1668L151.8 46.5575L190.312 202.173L75 135.673L113.288 24.1668Z"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M190.312 24.1668L229.048 180.006L190.312 202.173"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.051 111.715V64.694L139.709 72.5308V119.551L126.051 111.715Z"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.051 138.359V122.686L139.709 130.523V146.196L126.051 138.359Z"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M126.051 111.715L139.709 103.878"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M126.051 138.359L139.709 130.523"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M132.544 168.811L94.0321 191.202L171.056 235.535L286.816 168.811L248.304 146.644L259.052 140.375"
        stroke={secondary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M275.845 130.523L286.816 124.253L247.409 102.534L326 57.9768"
        stroke={secondary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default SVGNetworkError;
