import * as yup from 'yup';
import { additionalInformationEnum } from '../../listing/forms/listing.form';

const CURRENT_YEAR = new Date().getFullYear();
const REQUIRED_FIELD = 'This field is required';
const NUMBER_TYPE_ERROR = 'Value must be a valid number';
const NUMBER_GREATER_THAN_OR_EQUAL_TO_ZERO = 'Value must be greater than or equal to 0';
const NUMBER_GREATER_THAN_ZERO = 'Value must be greater than 0';
const NUMBER_GREATER_THAN_OR_EQUAL_TO_NINETEEN_HUNDRED_EIGHTY =
  'Value must be greater than or equal to 1980';
const NUMBER_LESS_THAN_OR_EQUAL_TO_CURRENT_YEAR = `Value must be less than or equal to ${CURRENT_YEAR}`;
const YEAR_GREATER_THAN_YEAR_BUILT = 'Value must be greater than year built value';
const POSITIVE_NUMBER = 'Value must be a positive number';
const FILL_BOTH_OR_NONE = 'Either fill both fields(positive number) or none';

export enum BuildingFormFieldsNew {
  LOCATION = 'location',
  LONGITUDE = 'longitude',
  LATITUDE = 'latitude',
  FULL_LOCATION = 'fullLocation',
  MEDIA = 'media',
  NAME = 'name',
  DESCRIPTION = 'description',
  BUILDING_TYPE = 'type',
  UNITS = 'units',
  PAYMENT_PLAN = 'paymentPlan',
  PLAN = 'paymentPlan',
  MEDIA_NAME = 'media[0].name',
  MEDIA_URL = 'media[0].url',
  AGENTS = 'agents',
  TEAM_ID = 'teamId',
  NEW_AGENTS = 'agentsEmail',
  MAX_UNITS = 'maxUnits',
  IS_ACTIVE = 'isActive',
}

export enum BuildingUnitCreateFields {
  NAME = 'name',
  PRICE = 'price',
  BED_ROOMS = 'bedrooms',
  BATH_ROOMS = 'bathrooms',
  BUILDING = 'building',
}

export enum UnitFormFields {
  PUBLISHED = 'published',
  IS_PRIMARY = 'published',
  STATUS = 'status',
  PRICE = 'price',
  SIZE = 'size',
  NAME = 'name',
  BEDROOMS = 'bedrooms',
  BATHROOMS = 'bathrooms',
  BEDROOMS_COUNT = 'bedroomsCount',
  DESCRIPTION = 'description',
  MATTERPORT_LINKS = 'matterPortLinks',
  MATTERPORT_DESCRIPTION = 'matterPortDescription',
  BEDROOMS_DESCRIPTION = 'bedroomsDescription',
  APPLIANCES_AMENITIES = 'appliancesAndAmenities',
  INTERIOR = 'interior',
  MEDIA = 'media',
  VIDOE_URL = 'videoUrls',
  FLOOR_PLANS = 'floorPlans',
  PDFS = 'pdfs',
  appliances = 'appliances',
  basement = 'basement',
  floorCovering = 'floorCovering',
  indoorFeatures = 'indoorFeatures',
  rooms = 'rooms',
  buildingAmenities = 'buildingAmenities',
  architecturalStyle = 'architecturalStyle',
  outdoorAmenities = 'outdoorAmenities',
  coolingType = 'coolingType',
  heatingType = 'heatingType',
  heatingFuel = 'heatingFuel',
}

export enum BuildingFeatureList {
  BASKETBALL = 'Basketball court',
  FITNESS_CENTER = 'Fitness center',
  DISABLED_ACCESS = 'Disabled access',
  DOORMAN = 'Doorman',
  ELEVATOR = 'Elevator',
  GATED_ENTRY = 'Gated entry',
  NEAR_TRANSPORTATION = 'Near transportation',
  ACTIVE_COMMUNITY = 'Active community',
  SPORTS_COURT = 'Sports court',
  STORAGE = 'Storage',
  TENNIS_COURT = 'Tennis court',
}
export enum BuildingFeatureFormField {
  BASKETBALL = 'basketballCourt',
  FITNESS_CENTER = 'fitnessCenter',
  DISABLED_ACCESS = 'disabledAccess',
  DOORMAN = 'doorman',
  ELEVATOR = 'elevator',
  GATED_ENTRY = 'gatedEntry',
  NEAR_TRANSPORTATION = 'nearTransportation',
  ACTIVE_COMMUNITY = 'activeCommunity',
  SPORTS_COURT = 'sportsCourt',
  STORAGE = 'storage',
  TENNIS_COURT = 'tennisCourt',
}

//deprecated.. keeping just for reference
// export enum BuildingFormFields {
//   LOCATION = 'location',
//   LONGITUDE = 'longitude',
//   LATITUDE = 'latitude',
//   FULL_LOCATION = 'fullLocation',
//   MEDIA = 'media',
//   PAYMENT_PLAN = 'paymentPlan',
//   SUB_MARKET_NAME = 'subMarketName',
//   SUB_MARKET_ID = 'subMarketId',
//   MEDIA_NAME = 'media[0].name',
//   MEDIA_URL = 'media[0].url',
//   YEAR_BUILT = 'yearBuilt',
//   YEAR_RENOVATED = 'yearRenovated',
//   FLOORS_COUNT = 'floorsCount',
//   BUILDING_SIZE = 'buildingSize',
//   AVERAGE_FLOOR_SIZE = 'averageFloor',
//   PARKING_RATIO_FIRST = 'parkingRatioFirst',
//   PARKING_RATIO_SECOND = 'parkingRatioSecond',
//   COLUMN_SPACING_HEIGHT = 'columnSpacingHeight',
//   COLUMN_SPACING_WIDTH = 'columnSpacingWidth',
//   SLAB_TO_SLAB_HEIGHT = 'slabToSlabHeight',
//   PASSENGER_ELEVATORS = 'passengerElevators',
//   FREIGHT_ELEVATORS = 'freightElevators',
//   TELECOM_PROVIDERS = 'telecomProviders',
//   LEED_CERTIFICATION = 'leedCertification',
//   ENERGY_CERTIFICATION = 'energyCertification',
//   WEBSITE = 'website',
//   OTHER = 'other',
//   BUILDING_HOURS = 'buildingHours',
//   AMENITIES = 'buildingAmenities',
//   AMENITIES_MATTERPORT_LINKS = 'buildingAmenitiesMatterportLinks',
// }
export enum CustomDomainFormFields {
  CUSTOM_DOMAIN = 'customDomain',
}
export const CustomDomainSchema = yup.object().shape({
  [CustomDomainFormFields.CUSTOM_DOMAIN]: yup
    .string()
    .trim()
    .required()
    // .matches(/^(([\w\d]+)|(\.))+[\w\d]+$/, 'Please enter a valid domain like example.com')
    .label('Custom Domain'),
});

// deprecated.. keeping just for reference
// export const BuildingFormSchema = yup.object().shape(
//   {
//     [BuildingFormFields.LOCATION]: yup
//       .string()
//       .trim()
//       .required(REQUIRED_FIELD),
//     [BuildingFormFields.LATITUDE]: yup
//       .string()
//       .trim()
//       .required(REQUIRED_FIELD),
//     [BuildingFormFields.LONGITUDE]: yup
//       .string()
//       .trim()
//       .required(REQUIRED_FIELD),
//     [BuildingFormFields.PAYMENT_PLAN]: yup
//       .string()
//       .trim()
//       .required(REQUIRED_FIELD),
//     [BuildingFormFields.FULL_LOCATION]: yup.mixed(),
//     [BuildingFormFields.SUB_MARKET_NAME]: yup
//       .string()
//       .trim()
//       .required(REQUIRED_FIELD),
//     [BuildingFormFields.SUB_MARKET_ID]: yup.string().trim(),

//     [BuildingFormFields.MEDIA]: yup
//       .array()
//       .of(
//         yup.object().shape({
//           name: yup
//             .string()
//             .trim()
//             .required(REQUIRED_FIELD),
//           url: yup
//             .string()
//             .trim()
//             .required(REQUIRED_FIELD),
//           isPrimary: yup.bool().required(REQUIRED_FIELD),
//         }),
//       )
//       .compact(media => !media.isPrimary)
//       .required(REQUIRED_FIELD),
//     [BuildingFormFields.YEAR_BUILT]: yup
//       .number()
//       .min(1500, NUMBER_GREATER_THAN_OR_EQUAL_TO_NINETEEN_HUNDRED_EIGHTY)
//       .max(CURRENT_YEAR, NUMBER_LESS_THAN_OR_EQUAL_TO_CURRENT_YEAR)
//       .typeError(NUMBER_TYPE_ERROR)
//       .required(REQUIRED_FIELD),
//     [BuildingFormFields.YEAR_RENOVATED]: yup
//       .number()
//       .transform(value => (isNaN(value) ? null : value))
//       .nullable()
//       .moreThan(yup.ref(BuildingFormFields.YEAR_BUILT), YEAR_GREATER_THAN_YEAR_BUILT)
//       .max(CURRENT_YEAR, NUMBER_LESS_THAN_OR_EQUAL_TO_CURRENT_YEAR),
//     [BuildingFormFields.FLOORS_COUNT]: yup
//       .number()
//       .min(0, NUMBER_GREATER_THAN_OR_EQUAL_TO_ZERO)
//       .typeError(NUMBER_TYPE_ERROR)
//       .required(REQUIRED_FIELD),
//     [BuildingFormFields.BUILDING_SIZE]: yup
//       .number()
//       .positive(NUMBER_GREATER_THAN_ZERO)
//       .typeError(NUMBER_TYPE_ERROR)
//       .required(REQUIRED_FIELD),
//     [BuildingFormFields.AVERAGE_FLOOR_SIZE]: yup
//       .number()
//       .positive(NUMBER_GREATER_THAN_ZERO)
//       .typeError(NUMBER_TYPE_ERROR)
//       .required(REQUIRED_FIELD),
//     [BuildingFormFields.PARKING_RATIO_FIRST]: yup
//       .number()
//       .positive(POSITIVE_NUMBER)
//       .when(BuildingFormFields.PARKING_RATIO_SECOND, {
//         is: value => value,
//         then: yup
//           .number()
//           .typeError(FILL_BOTH_OR_NONE)
//           .transform((value: any, originalValue: any) => {
//             return typeof originalValue === 'string' && isNaN(value) && originalValue?.trim() !== ''
//               ? 0
//               : value;
//           }),
//         otherwise: yup
//           .number()
//           .typeError(NUMBER_TYPE_ERROR)
//           .nullable(true)
//           .transform((value: any, originalValue: any) => {
//             return typeof originalValue === 'string' && originalValue?.trim() === '' ? null : value;
//           }),
//       }),
//     [BuildingFormFields.PARKING_RATIO_SECOND]: yup
//       .number()
//       .positive(POSITIVE_NUMBER)
//       .when(BuildingFormFields.PARKING_RATIO_FIRST, {
//         is: value => value,
//         then: yup
//           .number()
//           .typeError(FILL_BOTH_OR_NONE)
//           .transform((value: any, originalValue: any) => {
//             return typeof originalValue === 'string' && isNaN(value) && originalValue?.trim() !== ''
//               ? 0
//               : value;
//           }),
//         otherwise: yup
//           .number()
//           .typeError(NUMBER_TYPE_ERROR)
//           .nullable(true)
//           .transform((value: any, originalValue: any) => {
//             return typeof originalValue === 'string' && originalValue?.trim() === '' ? null : value;
//           }),
//       }),
//     [BuildingFormFields.COLUMN_SPACING_HEIGHT]: yup
//       .number()
//       .positive(POSITIVE_NUMBER)
//       .when(BuildingFormFields.COLUMN_SPACING_WIDTH, {
//         is: value => value,
//         then: yup
//           .number()
//           .typeError(FILL_BOTH_OR_NONE)
//           .transform((value: any, originalValue: any) => {
//             return typeof originalValue === 'string' && isNaN(value) && originalValue?.trim() !== ''
//               ? 0
//               : value;
//           }),
//         otherwise: yup
//           .number()
//           .typeError(NUMBER_TYPE_ERROR)
//           .nullable(true)
//           .transform((value: any, originalValue: any) => {
//             return typeof originalValue === 'string' && originalValue?.trim() === '' ? null : value;
//           }),
//       }),
//     [BuildingFormFields.COLUMN_SPACING_WIDTH]: yup
//       .number()
//       .positive(POSITIVE_NUMBER)
//       .when(BuildingFormFields.COLUMN_SPACING_HEIGHT, {
//         is: value => value,
//         then: yup
//           .number()
//           .typeError(FILL_BOTH_OR_NONE)
//           .transform((value: any, originalValue: any) => {
//             return typeof originalValue === 'string' && isNaN(value) && originalValue?.trim() !== ''
//               ? 0
//               : value;
//           }),
//         otherwise: yup
//           .number()
//           .typeError(NUMBER_TYPE_ERROR)
//           .nullable(true)
//           .transform((value: any, originalValue: any) => {
//             return typeof originalValue === 'string' && originalValue?.trim() === '' ? null : value;
//           }),
//       }),
//     [BuildingFormFields.SLAB_TO_SLAB_HEIGHT]: yup.string().trim(),
//     [BuildingFormFields.PASSENGER_ELEVATORS]: yup
//       .string()
//       .trim()
//       .required(REQUIRED_FIELD),
//     [BuildingFormFields.FREIGHT_ELEVATORS]: yup.string().trim(),
//     [BuildingFormFields.TELECOM_PROVIDERS]: yup.string().trim(),
//     [BuildingFormFields.LEED_CERTIFICATION]: yup.string().trim(),
//     [BuildingFormFields.ENERGY_CERTIFICATION]: yup.string().trim(),
//     [BuildingFormFields.WEBSITE]: yup
//       .string()
//       .trim()
//       .url('Please enter a valid url'),
//     [BuildingFormFields.OTHER]: yup.string().trim(),
//     [BuildingFormFields.BUILDING_HOURS]: yup
//       .array()
//       .of(
//         yup.object().shape({
//           openingDay: yup
//             .string()
//             .trim()
//             .required(REQUIRED_FIELD),
//           closingDay: yup
//             .string()
//             .trim()
//             .required(REQUIRED_FIELD),
//           openingTime: yup
//             .string()
//             .trim()
//             .required(REQUIRED_FIELD),
//           closingTime: yup
//             .string()
//             .trim()
//             .required(REQUIRED_FIELD),
//         }),
//       )
//       .required(REQUIRED_FIELD),
//     [BuildingFormFields.AMENITIES]: yup
//       .array()
//       .of(
//         yup.object().shape({
//           value: yup.string().trim(),
//         }),
//       )
//       .compact(amenity => !amenity.value),
//     [BuildingFormFields.BUILDING_HOURS]: yup
//       .array()
//       .of(
//         yup.object().shape({
//           openingDay: yup.string().trim(),
//           openingTime: yup.string().trim(),
//           closingDay: yup.string().trim(),
//           closingTime: yup.string().trim(),
//         }),
//       )
//       .compact(
//         value => !value.openingDay && !value.openingTime && !value.closingDay && !value.closingTime,
//       )
//       .required(REQUIRED_FIELD),
//     [BuildingFormFields.AMENITIES_MATTERPORT_LINKS]: yup.array().of(
//       yup.object().shape({
//         name: yup.string().trim(),
//         url: yup
//           .string()
//           .strict(true)
//           .trim()
//           .url('Please enter a valid url')
//           .when('name', {
//             is: value => value,
//             then: yup
//               .string()
//               .trim()
//               .required(REQUIRED_FIELD),
//           }),
//       }),
//     ),
//   },
//   [
//     [BuildingFormFields.PARKING_RATIO_FIRST, BuildingFormFields.PARKING_RATIO_SECOND],
//     [BuildingFormFields.COLUMN_SPACING_HEIGHT, BuildingFormFields.COLUMN_SPACING_WIDTH],
//   ],
// );
export const BuildingFormSchemaNew = yup.object().shape({
  [BuildingFormFieldsNew.NAME]: yup.string().trim(),
  [BuildingFormFieldsNew.BUILDING_TYPE]: yup.string().trim(),
  [BuildingFormFieldsNew.LOCATION]: yup
    .string()
    .trim()
    .required(REQUIRED_FIELD),
  [BuildingFormFieldsNew.UNITS]: yup
    .number()
    .positive(NUMBER_GREATER_THAN_ZERO)
    .max(200)
    .typeError(NUMBER_TYPE_ERROR)
    .required(REQUIRED_FIELD),
  [BuildingFormFieldsNew.LATITUDE]: yup
    .string()
    .trim()
    .required(REQUIRED_FIELD),
  [BuildingFormFieldsNew.LONGITUDE]: yup
    .string()
    .trim()
    .required(REQUIRED_FIELD),
  [BuildingFormFieldsNew.PAYMENT_PLAN]: yup
    .string()
    .trim()
    .required(REQUIRED_FIELD),
  [BuildingFormFieldsNew.TEAM_ID]: yup.string().trim(),
  [BuildingFormFieldsNew.FULL_LOCATION]: yup.mixed(),
  [BuildingFormFieldsNew.MEDIA]: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required(REQUIRED_FIELD),
        url: yup
          .string()
          .trim()
          .required(REQUIRED_FIELD),
        isPrimary: yup.bool().required(REQUIRED_FIELD),
      }),
    )
    .compact(media => !media.isPrimary)
    .required(REQUIRED_FIELD),
  [BuildingFormFieldsNew.AGENTS]: yup.array().of(yup.string()),
  [BuildingFormFieldsNew.NEW_AGENTS]: yup.array().of(yup.string()),
});

export const updateBuildingFeaturesSchema = yup.object().shape({
  [BuildingFeatureFormField.BASKETBALL]: yup.object().shape({
    [BuildingFormFieldsNew.DESCRIPTION]: yup.string().trim(),
    [BuildingFormFieldsNew.IS_ACTIVE]: yup.boolean(),
    [BuildingFormFieldsNew.MEDIA]: yup
      .array()
      .of(
        yup.object().shape({
          name: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          url: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          isPrimary: yup.bool().required(REQUIRED_FIELD),
        }),
      )
      .nullable(),
  }),
  [BuildingFeatureFormField.FITNESS_CENTER]: yup.object().shape({
    [BuildingFormFieldsNew.DESCRIPTION]: yup.string().trim(),
    [BuildingFormFieldsNew.IS_ACTIVE]: yup.boolean(),
    [BuildingFormFieldsNew.MEDIA]: yup
      .array()
      .of(
        yup.object().shape({
          name: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          url: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          isPrimary: yup.bool().required(REQUIRED_FIELD),
        }),
      )
      .nullable(),
  }),
  [BuildingFeatureFormField.ACTIVE_COMMUNITY]: yup.object().shape({
    [BuildingFormFieldsNew.DESCRIPTION]: yup.string().trim(),
    [BuildingFormFieldsNew.IS_ACTIVE]: yup.boolean(),
    [BuildingFormFieldsNew.MEDIA]: yup
      .array()
      .of(
        yup.object().shape({
          name: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          url: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          isPrimary: yup.bool().required(REQUIRED_FIELD),
        }),
      )
      .nullable(),
  }),
  [BuildingFeatureFormField.TENNIS_COURT]: yup.object().shape({
    [BuildingFormFieldsNew.DESCRIPTION]: yup.string().trim(),
    [BuildingFormFieldsNew.IS_ACTIVE]: yup.boolean(),
    [BuildingFormFieldsNew.MEDIA]: yup
      .array()
      .of(
        yup.object().shape({
          name: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          url: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          isPrimary: yup.bool().required(REQUIRED_FIELD),
        }),
      )
      .nullable(),
  }),
  [BuildingFeatureFormField.STORAGE]: yup.object().shape({
    [BuildingFormFieldsNew.DESCRIPTION]: yup.string().trim(),
    [BuildingFormFieldsNew.IS_ACTIVE]: yup.boolean(),
    [BuildingFormFieldsNew.MEDIA]: yup
      .array()
      .of(
        yup.object().shape({
          name: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          url: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          isPrimary: yup.bool().required(REQUIRED_FIELD),
        }),
      )
      .nullable(),
  }),
  [BuildingFeatureFormField.SPORTS_COURT]: yup.object().shape({
    [BuildingFormFieldsNew.DESCRIPTION]: yup.string().trim(),
    [BuildingFormFieldsNew.IS_ACTIVE]: yup.boolean(),
    [BuildingFormFieldsNew.MEDIA]: yup
      .array()
      .of(
        yup.object().shape({
          name: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          url: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          isPrimary: yup.bool().required(REQUIRED_FIELD),
        }),
      )
      .nullable(),
  }),
  [BuildingFeatureFormField.NEAR_TRANSPORTATION]: yup.object().shape({
    [BuildingFormFieldsNew.DESCRIPTION]: yup.string().trim(),
    [BuildingFormFieldsNew.IS_ACTIVE]: yup.boolean(),
    [BuildingFormFieldsNew.MEDIA]: yup
      .array()
      .of(
        yup.object().shape({
          name: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          url: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          isPrimary: yup.bool().required(REQUIRED_FIELD),
        }),
      )
      .nullable(),
  }),
  [BuildingFeatureFormField.GATED_ENTRY]: yup.object().shape({
    [BuildingFormFieldsNew.DESCRIPTION]: yup.string().trim(),
    [BuildingFormFieldsNew.IS_ACTIVE]: yup.boolean(),
    [BuildingFormFieldsNew.MEDIA]: yup
      .array()
      .of(
        yup.object().shape({
          name: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          url: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          isPrimary: yup.bool().required(REQUIRED_FIELD),
        }),
      )
      .nullable(),
  }),
  [BuildingFeatureFormField.ELEVATOR]: yup.object().shape({
    [BuildingFormFieldsNew.DESCRIPTION]: yup.string().trim(),
    [BuildingFormFieldsNew.IS_ACTIVE]: yup.boolean(),
    [BuildingFormFieldsNew.MEDIA]: yup
      .array()
      .of(
        yup.object().shape({
          name: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          url: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          isPrimary: yup.bool().required(REQUIRED_FIELD),
        }),
      )
      .nullable(),
  }),
  [BuildingFeatureFormField.DOORMAN]: yup.object().shape({
    [BuildingFormFieldsNew.DESCRIPTION]: yup.string().trim(),
    [BuildingFormFieldsNew.IS_ACTIVE]: yup.boolean(),
    [BuildingFormFieldsNew.MEDIA]: yup
      .array()
      .of(
        yup.object().shape({
          name: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          url: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          isPrimary: yup.bool().required(REQUIRED_FIELD),
        }),
      )
      .nullable(),
  }),

  [BuildingFeatureFormField.DISABLED_ACCESS]: yup.object().shape({
    [BuildingFormFieldsNew.DESCRIPTION]: yup.string().trim(),
    [BuildingFormFieldsNew.IS_ACTIVE]: yup.boolean(),
    [BuildingFormFieldsNew.MEDIA]: yup
      .array()
      .of(
        yup.object().shape({
          name: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          url: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          isPrimary: yup.bool().required(REQUIRED_FIELD),
        }),
      )
      .nullable(),
  }),
});

export const UpdateBuildingExteriorSchema = yup.object().shape({
  [additionalInformationEnum.architecturalStyle]: yup.string(),
  [additionalInformationEnum.exterior]: yup.array().of(yup.string()),
  [additionalInformationEnum.view]: yup.string(),
  [additionalInformationEnum.roof]: yup.string(),
  [additionalInformationEnum.parking]: yup.string(),
});

export const UpdateBuildingDetailsSchema = yup.object().shape({
  [BuildingFormFieldsNew.NAME]: yup.string().trim(),
  [BuildingFormFieldsNew.DESCRIPTION]: yup.string().trim(),
  [BuildingFormFieldsNew.IS_ACTIVE]: yup.boolean(),
  [BuildingFormFieldsNew.MAX_UNITS]: yup.number(),

  [BuildingFormFieldsNew.MEDIA]: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .trim()
        .required(REQUIRED_FIELD),
      url: yup
        .string()
        .trim()
        .required(REQUIRED_FIELD),
      // isPrimary: yup.bool().required(REQUIRED_FIELD),
    }),
  ),
  // .compact(media => !media.isPrimary)
  // .required(REQUIRED_FIELD),
  // [BuildingFormFieldsNew.AGENTS]: yup.array().of(yup.string()),
  // [BuildingFormFieldsNew.NEW_AGENTS]: yup.array().of(yup.string()),
});

export const UnitFormSchema = yup.object().shape({
  units: yup.array().of(
    yup.object().shape({
      [BuildingUnitCreateFields.NAME]: yup
        .string()
        .trim()
        .required(REQUIRED_FIELD),
      [BuildingUnitCreateFields.PRICE]: yup
        .number()
        .min(1, 'Must be greater than one')
        .typeError(NUMBER_TYPE_ERROR)
        .required(REQUIRED_FIELD),
      [BuildingUnitCreateFields.BED_ROOMS]: yup
        .number()
        .min(1, 'Must be greater than one')
        .typeError(NUMBER_TYPE_ERROR)
        .required(REQUIRED_FIELD),
      [BuildingUnitCreateFields.BATH_ROOMS]: yup
        .number()
        .min(1, 'Must be greater than one')
        .typeError(NUMBER_TYPE_ERROR)
        .required(REQUIRED_FIELD),
      [BuildingUnitCreateFields.BUILDING]: yup.string(),

      // isPrimary: yup.bool().required(REQUIRED_FIELD),
    }),
  ),
});

export const SingleUnitGeneralInformationSchema = yup.object().shape({
  [UnitFormFields.PUBLISHED]: yup.boolean(),
  [UnitFormFields.SIZE]: yup
    .number()
    .typeError('must be a number')
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    }),
  [UnitFormFields.PRICE]: yup
    .number()
    .typeError('must be a number')
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    }),
  [UnitFormFields.NAME]: yup.string(),
  [UnitFormFields.STATUS]: yup.string(),
  [UnitFormFields.DESCRIPTION]: yup.string().trim(),
  [UnitFormFields.BEDROOMS_COUNT]: yup
    .number()
    .typeError('must be a number')
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    }),
  // [UnitFormFields.BEDROOMS]: yup.array().of(
  //   yup.object().shape({
  //     description: yup
  //       .string()
  //       .trim()
  //       .required(REQUIRED_FIELD),
  //     media: yup.array().of(
  //       yup.object().shape({
  //         name: yup
  //           .string()
  //           .trim()
  //           .required(REQUIRED_FIELD),
  //         url: yup
  //           .string()
  //           .trim()
  //           .required(REQUIRED_FIELD),
  //         isPrimary: yup.bool().required(REQUIRED_FIELD),
  //       }),
  //     ),
  //   }),
  // ),
  [UnitFormFields.DESCRIPTION]: yup.string(),
  [UnitFormFields.MATTERPORT_DESCRIPTION]: yup.string(),
  [UnitFormFields.BEDROOMS_DESCRIPTION]: yup.string(),
  [UnitFormFields.MATTERPORT_LINKS]: yup.array().of(
    yup.object().shape({
      description: yup
        .string()
        .trim()
        .required(REQUIRED_FIELD),
      media: yup.array().of(
        yup.object().shape({
          name: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          url: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          isPrimary: yup.bool().required(REQUIRED_FIELD),
        }),
      ),
    }),
  ),
});

export const SingleUnitBedroomsSchema = yup.object().shape({
  [UnitFormFields.BEDROOMS_COUNT]: yup.number(),
  [UnitFormFields.BEDROOMS]: yup.array().of(
    yup.object().shape({
      isPrimary: yup.boolean(),
      description: yup
        .string()
        .trim()
        .required(REQUIRED_FIELD),
      media: yup.array().of(
        yup.object().shape({
          name: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          url: yup
            .string()
            .trim()
            .required(REQUIRED_FIELD),
          isPrimary: yup.bool().required(REQUIRED_FIELD),
        }),
      ),
    }),
  ),
});

export const SingleUnitInteriorSchema = yup.object().shape({
  [UnitFormFields.floorCovering]: yup.array().of(yup.string().required(REQUIRED_FIELD)),
  [UnitFormFields.rooms]: yup.array().of(yup.string().required(REQUIRED_FIELD)),
  [UnitFormFields.basement]: yup.string().required(REQUIRED_FIELD),
  [UnitFormFields.indoorFeatures]: yup.array().of(yup.string().required(REQUIRED_FIELD)),
  [UnitFormFields.coolingType]: yup.string().required(REQUIRED_FIELD),
  [UnitFormFields.heatingFuel]: yup.string().required(REQUIRED_FIELD),
  [UnitFormFields.heatingType]: yup.string().required(REQUIRED_FIELD),
});

export const SingleUnitAmenitiesSchema = yup.object().shape({
  [UnitFormFields.appliances]: yup.array().of(yup.string().required(REQUIRED_FIELD)),

  [UnitFormFields.outdoorAmenities]: yup.array().of(yup.string().required(REQUIRED_FIELD)),
});
