import { CompanyActions, CompanySubDomainStateInterface } from './company.types';
import { GetState } from '../../../types/GetStateInterface';

const defaultState: CompanySubDomainStateInterface = {
  companyInfo: GetState,
};

export default function CompanyReducer(state = defaultState, action: any) {
  switch (action.type) {
    case CompanyActions.GET_COMPANY_INFO_INIT:
      return {
        ...state,
        companyInfo: {
          ...state.companyInfo,
          loading: true,
          error: null,
        },
      };

    case CompanyActions.GET_COMPANY_INFO_FAILURE:
      return {
        ...state,
        companyInfo: {
          ...state.companyInfo,
          loading: false,
          error: action.payload,
          hasData: true,
        },
      };

    case CompanyActions.GET_COMPANY_INFO_SUCCESSFUL:
      return {
        ...state,
        companyInfo: {
          ...state.companyInfo,
          loading: false,
          error: null,
          data: action.payload,
          hasData: true,
        },
      };

    default:
      return state;
  }
}
