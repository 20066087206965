import React from 'react';
import { useSelector } from 'react-redux';
import { selectSubdomainBuilding } from '../../../../../../state/subdomain/building/building.selector';
import './index.css';
import Images from '../../../../../../utils/img.utils';
interface Props {
  buildingData?: any;
}
const BuildingExterior = ({ buildingData }: Props) => {
  const building = useSelector(selectSubdomainBuilding()) || buildingData;

  return building?.exterior?.length ? (
    <div>
      <div className="exterior-header-container">
        <img src={Images.BuildingExteriorIcon} />
        <span className="exterior-header-title">Exterior</span>
      </div>
      <div className="exterior-properties-container">
        <div className="exterior-properties-col">
          <span className="exterior-property-title">Exterior</span>
          {(building?.exterior || []).map((property: any) => (
            <span className="exterior-property-item">{property}</span>
          ))}
        </div>
        <div className="exterior-properties-col">
          <span className="exterior-property-title">Roof</span>
          <span className="exterior-property-item">{building?.roof}</span>
          <span className="exterior-property-title">Architectural style</span>
          <span className="exterior-property-item">{building?.architecturalStyle}</span>
          <span className="exterior-property-title">View</span>
          <span className="exterior-property-item">{building?.view}</span>
          <span className="exterior-property-title">Parking</span>
          <span className="exterior-property-item">{building?.parking}</span>
        </div>
      </div>
    </div>
  ) : null;
};

export default BuildingExterior;
