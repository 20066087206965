import { CancelToken, AxiosPromise } from 'axios';
import { CompanyInfoInterface } from './company.types';
import api from '../../../utils/api.utils';

export const getCompanyInfoAPI = (
  subdomain: string,
  ct?: CancelToken,
): AxiosPromise<CompanyInfoInterface> => {
  return api.get(`/company/domain/single/${subdomain}`, {
    cancelToken: ct,
  });
};
