import React from 'react';
import cx from 'classnames';
import './index.css';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'sm' | 'lg';
  variant?: 'primary' | 'white' | 'primary-outline' | 'white-outline' | 'blue-outline';
}

const Button: React.FC<Props> = ({ size = 'sm', variant = 'primary', className = '', ...rest }) => {
  const buttonClassName = cx('button', {
    'button-sm': size === 'sm',
    'button-lg': size === 'lg',
    'button-primary': variant === 'primary',
    'button-primary-outline': variant === 'primary-outline',
    'button-white': variant === 'white',
    'button-white-outline': variant === 'white-outline',
    'button-blue-outline': variant === 'blue-outline',
    [className]: true,
  });
  return <button className={buttonClassName} {...rest} />;
};

export default Button;
