import React from 'react';

const BuildingLocationShimmer: React.FC = () => {
  return (
    <div className="map--block map--block-shimmer text-center">
      <span className="shimmer__heading-lg shimmer__style animate mb-5"></span>
      <MapShimmer />
    </div>
  );
};

export const MapShimmer: React.FC = () => {
  return <div className="map--box shimmer__style animate" />;
};

export default BuildingLocationShimmer;
