import {
  CallState,
  PopulatedRoomInterface,
  GetTourInfo,
  TourActionTypes as actionTypes,
  ListingInfo,
  TourNeighborhood,
} from './tour.types';
import fetchActions from './../../../utils/state.utils';

export const initialCallState: CallState = {
  participants: {},
  participantNames: {},
  tourVideoTrack: null,
  leaderSessionId: null,
  hasTourStarted: false,
  hasTourEnded: false,
  isAudioMuted: true,
  isVideoMuted: true,
  isRecording: false,
};

export const buildRoomUrl = (tourRoom: PopulatedRoomInterface): string => {
  const roomUrl = tourRoom?.dailyCoRoom?.url;
  if (!roomUrl) return '';
  return roomUrl;
};

function simplifyParticipant(participant: any): any {
  const {
    video: hasVideo,
    videoTrack,
    audio: hasAudio,
    audioTrack,
    user_name: userName,
    session_id: sessionId,
  } = participant;
  return {
    hasVideo,
    videoTrack,
    hasAudio,
    audioTrack,
    userName,
    sessionId,
  };
}

export function simplifyParticipants(participants: any): any {
  const simplified: any = {};
  for (const [id, participant] of Object.entries(participants)) {
    simplified[id] = simplifyParticipant(participant);
  }
  return simplified;
}

export const updateParticipants = (data: any) => {
  const leader = Object.values(data).find((participant: any) => participant.screen);

  fetchActions.dispatch({
    type: actionTypes.PARTICIPANTS_CHANGE,
    payload: {
      leader,
      participants: data,
    },
  });
};
export const updateNames = (message: any) => {
  fetchActions.dispatch({
    type: actionTypes.NAME_RECEIVED,
    sessionId: message.fromId || 0,
    name: message.data?.name || '',
  });
};
export const recordingChange = (e: boolean) => {
  fetchActions.dispatch({
    type: actionTypes.RECORDING_CHANGE,
    value: e,
  });
};
export const endTour = () => {
  fetchActions.dispatch({
    type: actionTypes.TOUR_ENDED,
  });
};

export const changeTourInfo = (tour: GetTourInfo) => {
  fetchActions.dispatch({
    type: actionTypes.SD_GET_SINGLE_TOUR_INFO,
    payload: tour,
  });
};

export const changeTourListing = (listing?: ListingInfo | null) => {
  fetchActions.dispatch({
    type: actionTypes.SD_CHANGE_TOUR_LISTING,
    payload: listing || null,
  });
};

export const changeTourNeighbour = (neighbour: TourNeighborhood | null) => {
  fetchActions.dispatch({
    type: actionTypes.SD_CHANGE_TOUR_NEIGHBOUR,
    payload: neighbour,
  });
};
