import { GetState, GetStateInterface } from '../../types/GetStateInterface';
import { useCallback, useEffect, useState } from 'react';
import {
  addBuildingAPI,
  getBuildingDetailsAPI,
  getBuildingsListAPI,
  updateBuildingAPI,
  deleteBuildingAPI,
  getBuildingBrokersAPI,
  getBuildingWalkScoresAPI,
  getRemoveListingsAndBrokersFromPlanAPI,
  addCustomDomainAPI,
  deleteCustomDomainAPI,
  addBuildingAPI_V2,
  getAllBuildingsAPI_V2,
  createUnitAPI,
} from './building.api';
import {
  BuildingDataPayloadInterface,
  GetBuildingsListResponseInterface,
  UpdateSingleBuildingDataInterface,
  GetAllBuildingsQueryInterface,
  GetAllBuildingsDataInterface,
  GetBuildingWalkScoresResponseInterface,
  RemoveListingBrokersFromPlanInterface,
  AddCustomDomainRequestInterface,
  BuildingActions,
  SingleBuildingInterface_V2,
  CreateUnitsInterface,
} from './building.types';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../index';
import { clearAllBuildings, getAllBuildings, updateSingleBuilding } from './building.actions';
import { DEFAULT_BUILDING_LIMIT, LISTING_STATUS } from '../../common/constants';
import { BuildingActions as actionName } from './building.types';
import { UnitActions } from './building.types';
import fetchActions from '../../utils/state.utils';
import axios from 'axios';

export const useGetBuildingDetails = () => {
  const [res, setRes] = useState(GetState);

  const getBuildingDetails = useCallback(async (buildingId: string) => {
    setRes((prevState: any) => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.GET_SINGLE_BUILDING);
    try {
      const res = await getBuildingDetailsAPI(
        buildingId,
        fetchActions.setCT(actionName.GET_SINGLE_BUILDING),
      );
      updateSingleBuilding(res.data);
      setRes({ data: res.data, hasData: true, loading: false, error: null });
    } catch (err) {
      if (!axios.isCancel(err)) setRes({ data: null, error: err, hasData: true, loading: false });
    }
  }, []);

  return { res, getBuildingDetails };
};

export const useGetBuildingsList = () => {
  const [res, setRes] = useState<GetStateInterface<Array<GetBuildingsListResponseInterface>>>(
    GetState,
  );

  const getBuildingsList = useCallback(async (companyId: string) => {
    setRes((prevState: any) => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.GET_ALL_BUILDINGS);
    try {
      const res = await getBuildingsListAPI(
        companyId,
        fetchActions.setCT(actionName.GET_ALL_BUILDINGS),
      );
      setRes({ data: res.data, hasData: true, loading: false, error: null });
    } catch (err) {
      if (!axios.isCancel(err)) setRes({ data: null, error: err, hasData: true, loading: false });
    }
  }, []);

  return { res, getBuildingsList };
};

// depricated, keeping for referece. Client can change requirements again
export const useAddBuilding = () => {
  const [res, setRes] = useState<GetStateInterface>(GetState);

  const addBuilding = useCallback(async (data: BuildingDataPayloadInterface) => {
    setRes((prevState: any) => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.ADD_BUILDING);
    try {
      const res = await addBuildingAPI(data, fetchActions.setCT(actionName.ADD_BUILDING));
      setRes({ data: res.data, hasData: true, loading: false, error: null });
    } catch (err) {
      if (!axios.isCancel(err)) setRes({ data: null, error: err, hasData: true, loading: false });
    }
  }, []);

  return { res, addBuilding };
};

export const useAddBuilding_v2 = () => {
  const [res, setRes] = useState<GetStateInterface>(GetState);

  const addBuilding = useCallback(async (data: SingleBuildingInterface_V2) => {
    setRes((prevState: any) => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.ADD_BUILDING);
    try {
      const res = await addBuildingAPI_V2(data, fetchActions.setCT(actionName.ADD_BUILDING));
      setRes({ data: res.data, hasData: true, loading: false, error: null });
    } catch (err) {
      if (!axios.isCancel(err)) setRes({ data: null, error: err, hasData: true, loading: false });
    }
  }, []);

  return { res, addBuilding };
};

export const useAddUnits = () => {
  const [res, setRes] = useState<GetStateInterface>(GetState);

  const addUnits = useCallback(async (data: CreateUnitsInterface) => {
    setRes((prevState: any) => ({ ...prevState, loading: true }));
    fetchActions.callCT(UnitActions.ADD_UNIT);
    try {
      const res = await createUnitAPI(data, fetchActions.setCT(UnitActions.ADD_UNIT));
      setRes({ data: res.data, hasData: true, loading: false, error: null });
    } catch (err) {
      if (!axios.isCancel(err)) setRes({ data: null, error: err, hasData: true, loading: false });
    }
  }, []);

  return { res, addUnits };
};

export const useGetAllBuildings = (companyId: string, searchTerm?: string) => {
  const { data, loading, hasData, error } = useSelector(
    (state: AppStateInterface) => state.building.allBuildings,
  );

  useEffect(() => {
    if (companyId) {
      const initialQuery: GetAllBuildingsQueryInterface = {
        companyId: companyId,
        searchTerm: searchTerm,
        limit: DEFAULT_BUILDING_LIMIT,
        skip: 0,
      };
      getAllBuildings(initialQuery);
    }
  }, [companyId]);

  const getAllBuildingForCompany = useCallback(
    async (query: GetAllBuildingsQueryInterface, prev: GetAllBuildingsDataInterface[] = []) => {
      try {
        await getAllBuildings(query, prev);
      } catch (e) {
        if (!axios.isCancel(e)) {
          console.log(e);
        }
      }
    },
    [],
  );

  return { data, loading, hasData, error, getAllBuildingForCompany };
};
export const useGetAllBuildings_V2 = () => {
  const [res, setRes] = useState<GetStateInterface<any>>(GetState);

  const getAllBuildings = useCallback(async () => {
    setRes((prevState: any) => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.GET_ALL_BUILDINGS);
    try {
      const res = await getAllBuildingsAPI_V2(fetchActions.setCT(actionName.GET_ALL_BUILDINGS));
      setRes({ data: res.data as any, hasData: true, loading: false, error: null });
    } catch (err) {
      if (!axios.isCancel(err)) setRes({ data: null, error: err, hasData: true, loading: false });
    }
  }, []);

  return { res, getAllBuildings };
};
export const useUpdateBuilding = () => {
  const [res, setRes] = useState<GetStateInterface<Array<GetBuildingsListResponseInterface>>>(
    GetState,
  );

  const updateBuilding = useCallback(async (buildingId: string, data: any) => {
    setRes((prevState: any) => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.UPDATE_SINGLE_BUILDING);
    try {
      const res = await updateBuildingAPI(
        buildingId,
        data,
        fetchActions.setCT(actionName.UPDATE_SINGLE_BUILDING),
      );
      setRes({ data: res.data as any, hasData: true, loading: false, error: null });
    } catch (err) {
      if (!axios.isCancel(err)) setRes({ data: null, error: err, hasData: true, loading: false });
    }
  }, []);

  return { res, updateBuilding };
};
export const useRemoveListingsBrokersFromPlan = () => {
  const [res, setRes] = useState(GetState);

  const removeListingsBrokersFromPlan = useCallback(
    async (data: RemoveListingBrokersFromPlanInterface) => {
      setRes((prevState: any) => ({ ...prevState, loading: true }));
      fetchActions.callCT(actionName.REMOVE_BUILDIG_BROKERS_FROM_PLAN);
      try {
        const res = await getRemoveListingsAndBrokersFromPlanAPI(
          data,
          fetchActions.setCT(actionName.REMOVE_BUILDIG_BROKERS_FROM_PLAN),
        );
        setRes({ data: res.data as any, hasData: true, loading: false, error: null });
      } catch (err) {
        if (!axios.isCancel(err)) setRes({ data: null, error: err, hasData: true, loading: false });
      }
    },
    [],
  );

  return { res, removeListingsBrokersFromPlan };
};

export const useDeleteBuilding = () => {
  const [res, setRes] = useState(GetState);
  const deleteBuilding = useCallback(async (buildingId: string) => {
    setRes((prevState: any) => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.DELETE_BUILDING);
    try {
      const res = await deleteBuildingAPI(
        buildingId,
        fetchActions.setCT(actionName.DELETE_BUILDING),
      );
      setRes({ data: res.data, hasData: true, loading: false, error: null });
    } catch (err) {
      if (!axios.isCancel(err)) setRes({ data: null, error: err, hasData: true, loading: false });
    }
  }, []);
  return { res, deleteBuilding };
};

export const useClearAllBuildings = () => {
  return { clearAllBuildings };
};

export const useUpdateSingleBuilding = () => {
  const [res, setRes] = useState<GetStateInterface<any>>(GetState);

  const updateBuilding = useCallback(async (buildingId: string, data: any, useToken = false) => {
    setRes((prevState: any) => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.UPDATE_SINGLE_BUILDING);
    try {
      const res = await updateBuildingAPI(
        buildingId,
        {
          ...data,
        },
        useToken ? fetchActions.setCT(actionName.UPDATE_SINGLE_BUILDING) : undefined,
      );
      updateSingleBuilding(res.data);
      setRes({
        data: { ...res.data },
        hasData: true,
        loading: false,
        error: null,
      });
    } catch (err) {
      console.log(err);
      if (!axios.isCancel(err)) setRes({ data: null, error: err, hasData: true, loading: false });
      // throw err;
    }
  }, []);
  return { res, updateBuilding, updateSingleBuilding };
};

export const useGetBuildingBrokers = () => {
  const [res, setRes] = useState(GetState);
  const getBuildingBrokers = useCallback(
    async (buildingId: string, listingStatus = LISTING_STATUS.PUBLISHED) => {
      setRes((prevState: any) => ({ ...prevState, loading: true }));
      fetchActions.callCT(actionName.GET_SINGLE_BUILDING_BROKERS);
      try {
        const res = await getBuildingBrokersAPI(
          buildingId,
          listingStatus,
          fetchActions.setCT(actionName.GET_SINGLE_BUILDING_BROKERS),
        );
        setRes({ data: res.data, hasData: true, loading: false, error: null });
      } catch (err) {
        if (!axios.isCancel(err)) setRes({ data: null, error: err, hasData: true, loading: false });
      }
    },
    [],
  );
  return { res, getBuildingBrokers };
};

export const useGetBuildingWalkScores = () => {
  const [res, setRes] = useState<GetStateInterface<GetBuildingWalkScoresResponseInterface>>(
    GetState,
  );
  const getBuildingWalkScores = useCallback(async (buildingId: string) => {
    setRes((prevState: any) => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.GET_SINGLE_BUILDING_WALK_SCORES);
    try {
      const res = await getBuildingWalkScoresAPI(
        buildingId,
        fetchActions.setCT(actionName.GET_SINGLE_BUILDING_WALK_SCORES),
      );
      setRes({ data: res.data, hasData: true, loading: false, error: null });
    } catch (err) {
      if (!axios.isCancel(err)) setRes({ data: null, error: err, hasData: true, loading: false });
    }
  }, []);
  return { res, getBuildingWalkScores };
};
export const useAddCustomDomain = () => {
  const [res, setRes] = useState<GetStateInterface<AddCustomDomainRequestInterface>>(GetState);

  const addCustomDomain = useCallback(async (payload: AddCustomDomainRequestInterface) => {
    setRes(prevState => ({ ...prevState, loading: true }));

    fetchActions.callCT(BuildingActions.ADD_CUSTOM_DOMAIN);
    try {
      const res = await addCustomDomainAPI(
        payload,
        fetchActions.setCT(BuildingActions.ADD_CUSTOM_DOMAIN),
      );
      setRes({ data: res?.data, loading: false, error: null, hasData: true });
    } catch (error) {
      console.log(error);
      if (!axios.isCancel(error)) {
        setRes({ data: null, loading: false, error: error, hasData: true });
      }
    }
  }, []);
  return { res, addCustomDomain };
};
export const useDeleteCustomDomain = () => {
  const [res, setRes] = useState<GetStateInterface<any>>(GetState);

  const deleteCustomDomain = useCallback(async (payload: string) => {
    setRes(prevState => ({ ...prevState, loading: true }));

    fetchActions.callCT(BuildingActions.DELETE_CUSTOM_DOMAIN);
    try {
      const res = await deleteCustomDomainAPI(
        payload,
        fetchActions.setCT(BuildingActions.DELETE_CUSTOM_DOMAIN),
      );
      setRes({ data: res?.data, loading: false, error: null, hasData: true });
    } catch (error) {
      console.log(error);
      if (!axios.isCancel(error)) {
        setRes({ data: null, loading: false, error: error, hasData: true });
      }
    }
  }, []);
  return { res, deleteCustomDomain };
};
