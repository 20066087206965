import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { DEFAULT_PRIMARY_COLOR, DEFAULT_SECONDARY_COLOR } from '../../../common/constants';

const SVG404: React.FC = () => {
  const theme = useContext(ThemeContext);

  const [primary, setPrimary] = useState(DEFAULT_PRIMARY_COLOR);
  const [secondary, setSecondary] = useState(DEFAULT_SECONDARY_COLOR);
  useEffect(() => {
    if (theme?.primary) setPrimary(theme.primary);
    if (theme?.secondary) setSecondary(theme.secondary);
  }, [theme]);

  return (
    <svg
      width="372"
      height="261"
      viewBox="0 0 372 261"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M167.59 73.1172L236.859 112.98V160.889"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M241.622 174.543L185.547 206.848"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M229.282 173.568L195.93 192.707"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M155.74 133.461L132.895 146.505"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M157.45 126.023L132.895 140.042"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M157.449 126.024L241.623 174.542V109.932L157.449 61.4141V126.024Z"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M185.548 206.848L133.016 176.494"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M229.283 173.568L173.086 141.264"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M247.973 203.922L304.17 236.227L293.414 242.679"
        stroke={secondary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M27.9141 143.833L55.7946 127.107L64.0737 131.974"
        stroke={secondary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M164.535 200.873L185.548 212.942L206.561 200.873"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.879 134.192L133.014 122.123V194.778L143.52 188.683V212.942L133.014 219.037V243.296L111.879 255.365V231.106L80.3594 249.27V225.133L111.879 134.192Z"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M80.3592 249.269L48.8398 231.105V206.968L80.3592 116.027L111.878 134.191"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M133.014 122.123L101.372 103.959L80.3594 116.028"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M54.8242 210.383L75.1041 222.086"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M143.518 188.682L133.012 182.709"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M111.88 255.365L90.8672 243.297"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.508 200.872V147.844L98.9297 214.404L122.508 200.872Z"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M106.871 191.852L122.509 200.873"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M291.101 31.426L312.114 19.3574V92.0127L322.621 85.9174V110.176L312.114 116.272V140.531L291.101 152.599V128.34L259.582 146.504V122.367L291.101 31.426Z"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M228.551 102.374L259.581 13.2617L291.101 31.4255"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M259.58 146.504L241.621 136.264"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M312.114 19.3572L280.595 1.19336L259.582 13.2619"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M241.621 112.004L254.327 119.318"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M322.62 85.9167L312.113 79.9434"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M291.099 152.6L270.086 140.531"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M301.609 98.1067V45.0781L278.152 111.638L301.609 98.1067Z"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M286.094 89.0859L301.609 98.1069"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M208.199 141.461V146.28"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M202.547 140.437V141.975C202.547 142.591 202.855 143.206 203.472 143.514L203.677 143.616C204.499 144.129 205.63 143.514 205.63 142.488V140.95C205.63 140.335 205.322 139.719 204.705 139.412L204.499 139.309C203.677 138.797 202.547 139.412 202.547 140.437Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M197.82 137.771V139.309C197.82 139.925 198.129 140.54 198.745 140.848L198.951 140.95C199.773 141.463 200.903 140.848 200.903 139.822V138.284C200.903 137.669 200.595 137.053 199.978 136.746L199.773 136.643C198.848 136.131 197.82 136.746 197.82 137.771Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M193.09 135.002V136.54C193.09 137.155 193.398 137.77 194.015 138.078L194.22 138.181C195.042 138.693 196.173 138.078 196.173 137.053V135.514C196.173 134.899 195.864 134.284 195.248 133.976L195.042 133.874C194.117 133.361 193.09 133.976 193.09 135.002Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M216.316 146.076V150.998"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M210.664 145.154V146.692C210.664 147.307 210.972 147.923 211.589 148.23L211.794 148.333C212.617 148.846 213.747 148.23 213.747 147.205V145.667C213.747 145.052 213.439 144.436 212.822 144.129L212.617 144.026C211.692 143.513 210.664 144.129 210.664 145.154Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M224.332 150.793V155.613"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M218.68 149.767V151.305C218.68 151.921 218.988 152.536 219.605 152.844L219.81 152.946C220.632 153.459 221.763 152.844 221.763 151.818V150.28C221.763 149.665 221.454 149.05 220.838 148.742L220.632 148.639C219.81 148.127 218.68 148.742 218.68 149.767Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M174.184 121.875V126.695"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.531 120.849V122.388C168.531 123.003 168.84 123.618 169.456 123.926L169.662 124.028C170.484 124.541 171.614 123.926 171.614 122.9V121.362C171.614 120.747 171.306 120.132 170.689 119.824L170.484 119.721C169.662 119.209 168.531 119.824 168.531 120.849Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M182.301 126.592V131.411"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.648 125.566V127.104C176.648 127.72 176.957 128.335 177.573 128.642L177.779 128.745C178.601 129.258 179.731 128.642 179.731 127.617V126.079C179.731 125.464 179.423 124.848 178.806 124.541L178.601 124.438C177.779 123.925 176.648 124.541 176.648 125.566Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M190.418 131.207V136.027"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M184.77 130.181V131.72C184.77 132.335 185.078 132.95 185.694 133.258L185.9 133.36C186.722 133.873 187.852 133.258 187.852 132.232V130.694C187.852 130.079 187.544 129.464 186.928 129.156L186.722 129.053C185.797 128.541 184.77 129.156 184.77 130.181Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M211.59 134.898V139.718"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.832 133.873V135.411C205.832 136.026 206.14 136.641 206.757 136.949L206.962 137.052C207.785 137.564 208.915 136.949 208.915 135.924V134.386C208.915 133.77 208.607 133.155 207.99 132.847L207.785 132.745C206.962 132.232 205.832 132.847 205.832 133.873Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M201.109 131.105V132.643C201.109 133.259 201.418 133.874 202.034 134.182L202.24 134.284C203.062 134.797 204.192 134.182 204.192 133.156V131.618C204.192 131.003 203.884 130.387 203.267 130.08L203.062 129.977C202.24 129.465 201.109 130.08 201.109 131.105Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.379 128.439V129.977C196.379 130.593 196.687 131.208 197.304 131.516L197.509 131.618C198.331 132.131 199.462 131.516 199.462 130.49V128.952C199.462 128.337 199.154 127.721 198.537 127.414L198.331 127.311C197.509 126.799 196.379 127.414 196.379 128.439Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M219.605 139.514V144.333"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M213.953 138.488V140.026C213.953 140.641 214.261 141.257 214.878 141.564L215.084 141.667C215.906 142.18 217.036 141.564 217.036 140.539V139.001C217.036 138.386 216.728 137.77 216.111 137.463L215.906 137.36C215.084 136.847 213.953 137.463 213.953 138.488Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M227.723 144.23V149.05"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M222.07 143.205V144.743C222.07 145.358 222.379 145.974 222.995 146.281L223.201 146.384C224.023 146.896 225.153 146.281 225.153 145.256V143.718C225.153 143.102 224.845 142.487 224.228 142.179L224.023 142.077C223.098 141.564 222.07 142.179 222.07 143.205Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M177.574 115.314V120.134"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M171.922 114.289V115.827C171.922 116.442 172.23 117.057 172.847 117.365L173.052 117.468C173.874 117.98 175.005 117.365 175.005 116.34V114.802C175.005 114.186 174.697 113.571 174.08 113.263L173.874 113.161C172.95 112.648 171.922 113.263 171.922 114.289Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M167.195 111.52V113.058C167.195 113.673 167.504 114.288 168.12 114.596L168.326 114.698C169.148 115.211 170.278 114.596 170.278 113.571V112.032C170.278 111.417 169.97 110.802 169.353 110.494L169.148 110.392C168.223 109.879 167.195 110.597 167.195 111.52Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M185.691 119.928V124.747"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179.938 118.901V120.439C179.938 121.054 180.246 121.669 180.862 121.977L181.068 122.079C181.89 122.592 183.02 121.977 183.02 120.951V119.413C183.02 118.798 182.712 118.183 182.096 117.875L181.89 117.773C181.068 117.26 179.938 117.978 179.938 118.901Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M193.707 124.645V129.464"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M188.059 123.619V125.157C188.059 125.772 188.367 126.388 188.983 126.695L189.189 126.798C190.011 127.31 191.142 126.695 191.142 125.67V124.132C191.142 123.516 190.833 122.901 190.217 122.593L190.011 122.491C189.189 121.978 188.059 122.593 188.059 123.619Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M208.199 114.186V119.005"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M202.547 113.16V114.698C202.547 115.313 202.855 115.929 203.472 116.236L203.677 116.339C204.499 116.851 205.63 116.236 205.63 115.211V113.673C205.63 113.057 205.322 112.442 204.705 112.134L204.499 112.032C203.677 111.519 202.547 112.134 202.547 113.16Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M197.82 110.39V111.928C197.82 112.544 198.129 113.159 198.745 113.467L198.951 113.569C199.773 114.082 200.903 113.467 200.903 112.441V110.903C200.903 110.288 200.595 109.672 199.978 109.365L199.773 109.262C198.848 108.852 197.82 109.467 197.82 110.39Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M193.09 107.724V109.263C193.09 109.878 193.398 110.493 194.015 110.801L194.22 110.903C195.042 111.416 196.173 110.801 196.173 109.775V108.237C196.173 107.622 195.864 107.007 195.248 106.699L195.042 106.596C194.117 106.084 193.09 106.699 193.09 107.724Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M216.316 118.801V123.723"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M210.664 117.773V119.311C210.664 119.927 210.972 120.542 211.589 120.849L211.794 120.952C212.617 121.465 213.747 120.849 213.747 119.824V118.286C213.747 117.671 213.439 117.055 212.822 116.748L212.617 116.645C211.692 116.235 210.664 116.85 210.664 117.773Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M224.332 123.518V128.337"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M218.68 122.492V124.03C218.68 124.645 218.988 125.261 219.605 125.568L219.81 125.671C220.632 126.184 221.763 125.568 221.763 124.543V123.005C221.763 122.389 221.454 121.774 220.838 121.467L220.632 121.364C219.81 120.851 218.68 121.467 218.68 122.492Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M174.184 94.5996V99.4191"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.531 93.574V95.1121C168.531 95.7274 168.84 96.3427 169.456 96.6503L169.662 96.7528C170.484 97.2655 171.614 96.6503 171.614 95.6248V94.0867C171.614 93.4714 171.306 92.8562 170.689 92.5486L170.484 92.446C169.662 91.9333 168.531 92.5486 168.531 93.574Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M182.301 99.2129V104.135"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.648 98.1891V99.7273C176.648 100.343 176.957 100.958 177.573 101.265L177.779 101.368C178.601 101.881 179.731 101.265 179.731 100.24V98.7018C179.731 98.0866 179.423 97.4713 178.806 97.1637L178.601 97.0611C177.779 96.651 176.648 97.2662 176.648 98.1891Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M190.418 103.932V108.751"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M184.77 102.904V104.442C184.77 105.057 185.078 105.673 185.694 105.98L185.9 106.083C186.722 106.596 187.852 105.98 187.852 104.955V103.417C187.852 102.802 187.544 102.186 186.928 101.879L186.722 101.776C185.797 101.263 184.77 101.879 184.77 102.904Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M211.59 125.465V130.284"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.832 124.439V125.977C205.832 126.593 206.14 127.208 206.757 127.516L206.962 127.618C207.785 128.131 208.915 127.516 208.915 126.49V124.952C208.915 124.337 208.607 123.721 207.99 123.414L207.785 123.311C206.962 122.799 205.832 123.414 205.832 124.439Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M201.109 121.672V123.21C201.109 123.825 201.418 124.44 202.034 124.748L202.24 124.85C203.062 125.363 204.192 124.748 204.192 123.722V122.184C204.192 121.569 203.884 120.954 203.267 120.646L203.062 120.544C202.24 120.133 201.109 120.749 201.109 121.672Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.379 119.006V120.544C196.379 121.159 196.687 121.774 197.304 122.082L197.509 122.184C198.331 122.697 199.462 122.082 199.462 121.056V119.518C199.462 118.903 199.154 118.288 198.537 117.98L198.331 117.878C197.509 117.365 196.379 117.98 196.379 119.006Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M219.605 130.078V135"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M213.953 129.055V130.593C213.953 131.208 214.261 131.823 214.878 132.131L215.084 132.234C215.906 132.746 217.036 132.131 217.036 131.106V129.568C217.036 128.952 216.728 128.337 216.111 128.029L215.906 127.927C215.084 127.414 213.953 128.132 213.953 129.055Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M227.723 134.795V139.614"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M222.07 133.771V135.309C222.07 135.925 222.379 136.54 222.995 136.848L223.201 136.95C224.023 137.463 225.153 136.848 225.153 135.822V134.284C225.153 133.669 224.845 133.053 224.228 132.746L224.023 132.643C223.098 132.131 222.07 132.746 222.07 133.771Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M177.574 105.879V110.698"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M171.922 104.853V106.391C171.922 107.007 172.23 107.622 172.847 107.93L173.052 108.032C173.874 108.545 175.005 107.93 175.005 106.904V105.366C175.005 104.751 174.697 104.135 174.08 103.828L173.874 103.725C172.95 103.213 171.922 103.828 171.922 104.853Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M167.195 102.086V103.624C167.195 104.239 167.504 104.854 168.12 105.162L168.326 105.264C169.148 105.777 170.278 105.162 170.278 104.136V102.598C170.278 101.983 169.97 101.368 169.353 101.06L169.148 100.958C168.223 100.547 167.195 101.163 167.195 102.086Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M185.691 110.494V115.416"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179.938 109.468V111.007C179.938 111.622 180.246 112.237 180.862 112.545L181.068 112.647C181.89 113.16 183.02 112.545 183.02 111.519V109.981C183.02 109.366 182.712 108.751 182.096 108.443L181.89 108.34C181.068 107.93 179.938 108.546 179.938 109.468Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M193.707 115.211V120.03"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M188.059 114.185V115.723C188.059 116.339 188.367 116.954 188.983 117.262L189.189 117.364C190.011 117.877 191.142 117.262 191.142 116.236V114.698C191.142 114.083 190.833 113.468 190.217 113.16L190.011 113.057C189.189 112.545 188.059 113.16 188.059 114.185Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M211.59 107.52V112.442"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.832 106.597V108.136C205.832 108.751 206.14 109.366 206.757 109.674L206.962 109.776C207.785 110.289 208.915 109.674 208.915 108.648V107.11C208.915 106.495 208.607 105.88 207.99 105.572L207.785 105.469C206.962 104.957 205.832 105.572 205.832 106.597Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M201.109 103.828V105.366C201.109 105.981 201.418 106.597 202.034 106.904L202.24 107.007C203.062 107.519 204.192 106.904 204.192 105.879V104.341C204.192 103.725 203.884 103.11 203.267 102.802L203.062 102.7C202.24 102.187 201.109 102.802 201.109 103.828Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.379 101.058V102.596C196.379 103.212 196.687 103.827 197.304 104.135L197.509 104.237C198.331 104.75 199.462 104.135 199.462 103.109V101.571C199.462 100.956 199.154 100.34 198.537 100.033L198.331 99.9303C197.509 99.5201 196.379 100.135 196.379 101.058Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M219.605 112.236V117.056"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M213.953 111.211V112.749C213.953 113.364 214.261 113.979 214.878 114.287L215.084 114.39C215.906 114.902 217.036 114.287 217.036 113.262V111.723C217.036 111.108 216.728 110.493 216.111 110.185L215.906 110.083C215.084 109.57 213.953 110.185 213.953 111.211Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M227.723 116.852V121.774"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M222.07 115.826V117.364C222.07 117.979 222.379 118.595 222.995 118.902L223.201 119.005C224.023 119.517 225.153 118.902 225.153 117.877V116.339C225.153 115.723 224.845 115.108 224.228 114.8L224.023 114.698C223.098 114.288 222.07 114.903 222.07 115.826Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M177.574 88.0371V92.8566"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M171.922 87.0115V88.5496C171.922 89.1649 172.23 89.7802 172.847 90.0878L173.052 90.1903C173.874 90.703 175.005 90.0878 175.005 89.0623V87.5242C175.005 86.9089 174.697 86.2937 174.08 85.9861L173.874 85.8835C172.95 85.3708 171.922 85.9861 171.922 87.0115Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M167.195 84.242V85.7801C167.195 86.3954 167.504 87.0106 168.12 87.3183L168.326 87.4208C169.148 87.9335 170.278 87.3183 170.278 86.2928V84.7547C170.278 84.1394 169.97 83.5241 169.353 83.2165L169.148 83.114C168.223 82.6013 167.195 83.2165 167.195 84.242Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M185.691 92.6504V97.4699"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179.938 91.6248V93.1629C179.938 93.7782 180.246 94.3934 180.862 94.7011L181.068 94.8036C181.89 95.3163 183.02 94.7011 183.02 93.6756V92.1375C183.02 91.5222 182.712 90.907 182.096 90.5993L181.89 90.4968C181.068 89.9841 179.938 90.5993 179.938 91.6248Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M193.707 97.2656V102.188"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M188.059 96.2399V97.778C188.059 98.3933 188.367 99.0086 188.983 99.3162L189.189 99.4187C190.011 99.9315 191.142 99.3162 191.142 98.2908V96.7526C191.142 96.1374 190.833 95.5221 190.217 95.2145L190.011 95.1119C189.189 94.7017 188.059 95.317 188.059 96.2399Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M25.3415 228.33L2.49609 241.374L27.6968 255.366"
        stroke={secondary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M370.707 143.326L318.008 173.568"
        stroke={secondary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M370.708 143.326L341.41 126.484"
        stroke={secondary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default SVG404;
