import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useGetBuildingDetails } from '../../../../../state/building/building.hook';
import {
  selectSubdomainBuilding,
  selectSubdomainCompanyLoading,
} from '../../../../../state/subdomain/building/building.selector';
import Loader from '../../../../common/loader';
import Header from './Header';
import './index.css';
import Footer from './Footer';
import BasicTemplate from './BasicTemplate';
import ModernTemplate from './ModernTemplate';
import ClassicTemplate from './ClassicTemplate';
import { BUILDING_TEMPLATES } from '../../../../../common/constants';
import { useHistory, useParams } from 'react-router';

const BuildingWebsite = () => {
  const loadingCompany = useSelector(selectSubdomainCompanyLoading());
  const { res, getBuildingDetails } = useGetBuildingDetails();
  const building = useSelector(selectSubdomainBuilding());
  const { buildingId } = useParams<{ buildingId: string }>();
  const history = useHistory();
  useEffect(() => {
    if (buildingId) {
      getBuildingDetails(buildingId);
    }
  }, []);

  if (building && !building?.published) {
    history.push('Error/404');
  }

  // if (loadingCompany || !res.data || res.loading) return <Loader />;
  const template = building?.websiteTemplate || BUILDING_TEMPLATES.BASIC;
  return (
    <div>
      <Header />
      {template === BUILDING_TEMPLATES.BASIC && <BasicTemplate />}
      {template === BUILDING_TEMPLATES.MODERN && <ModernTemplate />}
      {template === BUILDING_TEMPLATES.CLASSIC && <ClassicTemplate />}
      <Footer />
    </div>
  );
};

export default BuildingWebsite;
