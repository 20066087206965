import { combineReducers } from 'redux';
import me from './me/me.reducer';
import leftSidebar from './leftSidebar/leftSidebar.reducer';
import users from './users/users.reducer';
import listing from './listing/listing.reducer';
import building from './building/building.reducer';
import review from './review/review.reducer';
import tour from './tour/tour.reducer';
import product from './product/product.reducer';
import subdomain, { SubDomainAppStateInterface } from './subdomain';

import { RESET } from '../common/constants';

import { MeStateInterface } from './me/me.types';
import { LeftSidebarStateInterface } from './leftSidebar/leftSidebar.types';
import { UsersStateInterface } from './users/users.types';
import { ListingStateInterface } from './listing/listing.types';
import { BuildingStateInterface } from './building/building.types';
import { ReviewStateInterface } from './review/review.types';
import { TourStateInterface } from './tour/tour.types';
import { ProductStateInterface } from './product/product.types';

export interface AppStateInterface {
  me: MeStateInterface;
  users: UsersStateInterface;
  leftSidebar: LeftSidebarStateInterface;
  listing: ListingStateInterface;
  building: BuildingStateInterface;
  subdomain: SubDomainAppStateInterface;
  review: ReviewStateInterface;
  tour: TourStateInterface;
  product: ProductStateInterface;
}

export type GetState = () => AppStateInterface;

const appReducer = combineReducers<AppStateInterface>({
  me,
  users,
  leftSidebar,
  listing,
  building,
  subdomain,
  review,
  tour,
  product,
});

const rootReducer = (state: AppStateInterface | undefined, action: any) => {
  if (action.type === RESET) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
