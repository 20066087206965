import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { PLAN_TYPES } from '../../../../../../common/constants';
import {
  selectSubdomainBuilding,
  selectSubdomainCompanyInfo,
} from '../../../../../../state/subdomain/building/building.selector';
import Images from '../../../../../../utils/img.utils';
import { getPublishedUnits, numberWithCommas } from '../../../../../../utils/misc.utils';
import ImgTile from '../../../../../media/imgTile';
import TourContactForm from '../../../../../subdomain/listing/listDetail/contactForm';
import TourRequestForm from '../../../../../subdomain/listing/listDetail/requestForm';
import Button from '../Button';
import './index.css';

const ContactBoard = () => {
  const [requestForm, setRequestForm] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);
  const tourRequestHandler = () => {
    setRequestForm(true);
  };
  const contactInfoHandler = () => {
    setShowContactForm(true);
  };
  const building = useSelector(selectSubdomainBuilding());
  const company = useSelector(selectSubdomainCompanyInfo());
  let cheapestListing;
  if (building?.listings.length)
    cheapestListing = building?.listings.reduce(
      (a, b) => (a.price < b.price ? a : b),
      building.listings[0],
    );
  const buildingAgents =
    company?.users?.filter(({ _id }) => building?.agents?.includes(_id)) || building?.agents || [];
  return (
    <div className="contact-board-container">
      <div className="contact-units-container">
        <img src={Images.UnitIcon} />
        <span>This building has {getPublishedUnits(building?.listings) || 0} units</span>
      </div>
      <hr className="hr" />
      <div className="price-container">
        <span className="price-title">
          {building?.type === PLAN_TYPES.RENTAL ? 'Monthly rent from' : 'Prices starting from'}
        </span>
        <div>
          <span className="price-value">${numberWithCommas(cheapestListing?.price || 0)}</span>

          {building?.type === PLAN_TYPES.RENTAL && <span className="price-subtitle">/mo</span>}
        </div>
      </div>
      <hr className="hr" />
      <span className="request-tour-title">Would you like to tour this building?</span>
      <div className="w-100">
        <Button className="w-100" size="lg" onClick={tourRequestHandler}>
          Request tour
        </Button>
      </div>
      <hr className="hr" />
      <div className="contact-title">Contact</div>

      {// @ts-ignore
      buildingAgents.map(({ _id, firstName, lastName, avatar, email }) => (
        <div key={_id} className="agent-container">
          <ImgTile src={avatar} default={Images.defaultAvatar} className="agent-thumbnail" />
          <div>
            <div className="agent-name">
              {firstName} {lastName}
            </div>
            <div className="agent-email">{email}</div>
          </div>
        </div>
      ))}

      <div className="w-100">
        <Button className="w-100" size="lg" variant="primary-outline" onClick={contactInfoHandler}>
          Request information
        </Button>
      </div>
      {requestForm && (
        <TourRequestForm
          buildingId={building?._id}
          hideForm={() => {
            setRequestForm(false);
          }}
        />
      )}
      {showContactForm && (
        <TourContactForm buildingId={building?._id} hideForm={() => setShowContactForm(false)} />
      )}
    </div>
  );
};

export default ContactBoard;
