import React from 'react';
import BuildingDescriptionSection from './BuildingDescriptionSection';
import BuildingExterior from './BuildingExterior';
import BuildingFeaturesSection from './BuildingFeaturesSection';

const BuildingCommonSection = () => {
  return (
    <>
      <BuildingDescriptionSection />
      <hr className="hr" />
      <div className="building-features-heading">
        Find out more about this building and its features
      </div>
      <div>
        <BuildingFeaturesSection />
      </div>
      <hr className="hr" />
      <BuildingExterior />
    </>
  );
};

export default BuildingCommonSection;
