import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import {
  useClearSingleListing,
  useGetSingleListingAction,
} from '../../../state/subdomain/listing/listing.hook';
import SubDomainRightSidebar from '../../../components/subdomain/rightSidebar';
import SubdomainFooter from '../../../components/subdomain/footer';
import SingleListingShimmer from './singleListingShimmer';
import withTheme from '../../../components/hoc/withTheme';
import ImgTile from '../../../components/media/imgTile';
import images from '../../../utils/img.utils';
const SingleListing = lazy(() => import('./../../../components/subdomain/listing/listDetail'));
interface Props {
  companyData?: any;
}
const SingleListingsView: React.FC<Props> = ({ companyData }) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const company = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );
  const listingInfo = useSelector(
    (state: AppStateInterface) => state.subdomain.listing.singleListing,
  );
  const { listingId } = useParams<{ listingId: string }>();
  const { clearSingleListing } = useClearSingleListing();
  const { getSingleListing } = useGetSingleListingAction();
  if (!listingId) history.push('/listings');

  useEffect(() => {
    if (listingId) getSingleListing(listingId);
  }, [listingId]);
  useEffect(() => {
    return () => clearSingleListing();
  }, []);

  useEffect(() => {
    if (!listingInfo.loading && listingInfo.error) {
      history.push('error/404');
    }
  }, [listingInfo]);

  return (
    <div className="">
      {!listingInfo.loading ? (
        <Suspense fallback={<SingleListingShimmer />}>
          <React.Fragment>
            <div className="">
              {/* <div className="container container--large off--canvas-content"> */}
              <div>
                {/* <div className="d-flex align-items-center justify-content-between">
                  <a
                    className="available--listing-logo"
                    href={company?.settings?.officialWebsite || '#'}
                    target={company?.settings?.officialWebsite ? '_blank' : '_self'}
                    rel="noopener noreferrer"
                  >
                    <ImgTile
                      src={company?.settings?.darkLogo?.url || ''}
                      default={images.darkLogo}
                      alt="Logo"
                    />
                  </a>
                  <SubDomainRightSidebar show={show} setShow={setShow} />
                </div> */}
                <SingleListing company={company || companyData} />
              </div>
            </div>
            {/* <SubdomainFooter /> */}
          </React.Fragment>
        </Suspense>
      ) : (
        <SingleListingShimmer />
      )}
    </div>
  );
};

export default withTheme(SingleListingsView);
