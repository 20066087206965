import { useState, useLayoutEffect, useRef, useCallback } from 'react';
import { GetState } from '../../types/GetStateInterface';
import { uploadMediaAPI, uploadSingleMediaAPI } from './media.api';
import { UploadMediaInterface } from './media.types';

export const useUploadMedia = () => {
  const [res, setRes] = useState(GetState);
  const [progress, setProgress] = useState({ progressBar: 0, name: '' });

  const uploadMedia = async (media: UploadMediaInterface[]) => {
    if (media && media.length) {
      const data = new FormData();
      for (const img of media) {
        data.append('file', img.data);
      }
      setRes(prevState => ({ ...prevState, loading: true }));
      try {
        const res = await uploadMediaAPI(data);
        setRes({
          data: res.data,
          loading: false,
          error: null,
          hasData: true,
        });
      } catch (error) {
        setRes({ data: null, loading: false, error, hasData: true });
      }
    }
  };

  const uploadSingleMedia = async (media: UploadMediaInterface, needNameOnError?: boolean) => {
    if (media) {
      const data = new FormData();
      data.append('file', media.data);
      setRes(prevState => ({ ...prevState, loading: true }));
      try {
        const res = await uploadSingleMediaAPI(data, (e: number) =>
          setProgress({ name: media.name, progressBar: e }),
        );
        setRes({
          data: res.data,
          loading: false,
          error: null,
          hasData: true,
        });
      } catch (error) {
        setRes({
          data: needNameOnError ? { name: media.name } : null,
          loading: false,
          error,
          hasData: true,
        });
      }
    }
  };

  return { progress, res, uploadMedia, uploadSingleMedia };
};

export const useMediaSize = () => {
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const reference = useRef<any>(null);

  const calculateWidthHeight = useCallback((clientHeight, clientWidth) => {
    if (clientHeight && clientWidth) {
      setWidth(clientWidth);
      setHeight(clientHeight);
    }
  }, []);

  useLayoutEffect(() => {
    if (reference?.current) {
      const ref = reference.current;
      if (width !== ref.clientWidth || height !== ref.clientHeight)
        calculateWidthHeight(ref.clientHeight, ref.clientWidth);
    }
  }, []); // if we apply dependency then height and width does not apply to every image on the webpage

  return { reference, width, height };
};
