import { GetListingObject, ListingInterface, PhotoInterface } from '../../listing/listing.types';
import { TOUR_CONTROLLER_TYPE, TOUR_TYPES } from '../../../common/constants';
import { UploadedMediaInterface } from '../../media/media.types';
import { SingleListingInterfaceNew } from '../../building/building.types';

export enum TourActions {
  GET_TOUR = 'GET_TOUR',
  DELETE_TOUR = 'DELETE_TOUR',
  CREATE_TOUR = 'CREATE_TOUR',
  CREATE_POST_TOUR = 'CREATE_POST_TOUR',
  GET_POST_TOUR_LINK = 'GET_POST_TOUR_LINK',
}

export interface TourStateInterface {
  name: string;
  callObject: any;
  callState: CallState;
  message: any;

  tourInfo: GetTourInfo;
  tourListing: any | null;
  tourNeighbour: TourNeighborhood | null;
}

export interface SimpleParticipant {
  hasVideo: boolean;
  videoTrack: MediaStream;
  hasAudio: boolean;
  audioTrack: MediaStream;
  sessionId: string;
  userName: string;
  userEmail: string;
  isSpeaking: boolean;
  isMe?: boolean;
}

export interface HostScreenDimensions {
  screenWidth: number;
  screenHeight: number;
  shareableHeight: number;
  shareableWidth: number;
}

export const initialHostScreenDimensions: HostScreenDimensions = {
  screenWidth: 0,
  screenHeight: 0,
  shareableHeight: 0,
  shareableWidth: 0,
};

export interface Host extends SimpleParticipant, HostScreenDimensions {
  screenTrack?: MediaStream;
  hasScreenTrack?: boolean;
}

export interface ParticipantsInterface {
  [key: string]: SimpleParticipant;
}

export interface ParticipantPersonalDetails {
  [key: string]: {
    name: string;
    email: string;
  };
}

export interface CallState {
  participants: ParticipantsInterface;
  participantNames: any; // map session id to name
  tourVideoTrack: MediaStream | null;
  leaderSessionId: string | null;
  hasTourStarted: boolean;
  hasTourEnded: boolean;
  isAudioMuted: boolean;
  isVideoMuted: boolean;
  isRecording: boolean;
}

export interface TourInterface {
  callState: CallState;
  dailyCoCall: any;
}

export enum TourActionTypes {
  PARTICIPANTS_CHANGE = 'PARTICIPANTS_CHANGE',
  NAME_RECEIVED = 'NAME_RECEIVED',
  UPDATE_CALL = 'UPDATE_CALL',
  RECORDING_CHANGE = 'RECORDING_CHANGE',
  GOT_MESSAGE = 'GOT_MESSAGE',
  TOUR_ENDED = 'TOUR_ENDED',
  SD_CHANGE_TOUR_LISTING = 'SD_CHANGE_TOUR_LISTING',
  SD_CHANGE_TOUR_NEIGHBOUR = 'SD_CHANGE_TOUR_NEIGHBOUR',
  SD_GET_SINGLE_TOUR_INFO = 'SD_GET_SINGLE_TOUR_INFO',
}
export interface DailyCoRoomInterface {
  id: string;
  name: string;
  privacy: string;
  apiCreated: boolean;
  url: string;
  config: any;
  createdAt: string;
}

export interface TourResponseInterface {
  _id: string;
  dailyCoRoom: DailyCoRoomInterface;
  listing: GetListingObject;
  expiredAt: number;
  isActive?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface PopulatedRoomInterface {
  _id: string;
  dailyCoRoom: DailyCoRoomInterface;
  listing: GetListingObject;
  createdBy: {
    _id: string;
    firstName: string;
    lastName: string;
    avatar: string;
  };
  createdAt: string;
  updatedAt: string;
}

export enum TourStates {
  NOT_INITIALIZED = 'NOT_INITIALIZED',
  NOT_JOINED_NOT_STARTED = 'NOT_JOINED_NOT_STARTED',
  NOT_JOINED = 'NOT_JOINED',
  JOINED_NOT_STARTED = 'JOINED_NOT_STARTED',
  STARTED = 'STARTED',
  ENDED = 'ENDED',
}
export enum JoinStates {}

export enum AppMessageTypes {
  REQUEST_PERSONAL_DETAILS = 'REQUEST_PERSONAL_DETAILS',
  PERSONAL_DETAILS = 'PERSONAL_DETAILS',
  END_TOUR = 'END_TOUR',
  REQUEST_SCREEN_DETAILS = 'REQUEST_SCREEN_DETAILS',
  SCREEN_DETAILS = 'SCREEN_DETAILS',
}

export interface SortedParticipantsInterface {
  tenants: SimpleParticipant[];
  brokers: SimpleParticipant[];
}

// holds the daily call w/in itself
export interface VideoCallInterface extends SortedParticipantsInterface {
  updateAllHostDimensions: (screenDimensions: HostScreenDimensions) => void;
  joinCall: (name: string, email: string, isBroker?: boolean) => void;
  startCall: (name: string, email: string) => void;
  leaveCall: (endTour?: boolean) => void;
  muteAudio: (enabled?: boolean) => void;
  muteVideo: (enabled?: boolean) => void;
  reselectTab: () => void;
  tourState: TourStates;
  isRecording: boolean;
  host: Host | null;
  isHost: boolean;
  browser: BrowserInterface | null;
  networkQuality: number;
}

export interface TourOption {
  tourType: TOUR_TYPES;
  clientInfo: string;
  clientLogo?: UploadedMediaInterface;
}
export interface TourListingSelected {
  _id: string;
  name: string;
}

export interface CreateTourInterface {
  companyId: string;
  tourType: TOUR_TYPES;
  clientCompany: string;
  clientCompanyLogo?: UploadedMediaInterface;
  listings: string[];
}

export interface GetTourInfo {
  _id: string;
  additionalUsers: AdditionalUser[];
  isActive: boolean;
  dailyCoRoom: any;
  listings: ListingInfo[];
  neighborhoods?: TourNeighborhood[];
  clientInfo: ClientInfo;
  tourType: TOUR_TYPES;
  expiredAt: number;
  company: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface TourNeighborhood {
  _id: string;
  neighborhood: TourNeighborhoodInterface;
  media: UploadedMediaInterface[];
  createdAt: string;
  updatedAt: string;
  role: string;
}

export interface TourNeighborhoodInterface {
  _id: string;
  name: string;
  centrePoint: LocationBoundary;
  zoomLevel: number;
  locationBoundaries: LocationBoundary[];
  market: string;
  __v: number;
}

interface LocationBoundary {
  lat: string;
  lng: string;
}
interface ClientInfo {
  _id: string;
  company: string;
  companyLogo?: UploadedMediaInterface;
}

export interface ListingInfo {
  _id?: string;
  listing: SingleListingInterfaceNew;
  additionalMedia: UploadedMediaInterface[];
  additionalFloorPlans: UploadedMediaInterface[];
  additionalPdfs?: UploadedMediaInterface[];
  createdAt?: string;
  updatedAt?: string;
}

export interface AdditionalUser {
  _id: string;
  avatar: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface BrowserInterface {
  mobile: boolean;
  name: string;
  supported: boolean;
  supportsScreenShare: boolean;
  supportsSfu: boolean;
  version: string;
}

export enum TourSection {
  HOME = 'HOME',
  DETAILS = 'DETAILS',
  TOURS = 'TOURS',
  LOCATION = 'LOCATION',
  GALLERY = 'GALLERY',
  SPECS = 'SPECS',
  FLOOR_PLANS = 'FLOOR_PLANS',
  PDFS = 'PDFS',
  VIDEOS = 'VIDEOS',
}

export enum ClickAndTimeSections {
  DETAILS = 'details',
  VIDEOS = 'video',
  TOURS = 'matterPortLink',
  GALLERY = 'media',
  LOCATION = 'location',
  SPECS = 'specs',
  FLOOR_PLANS = 'floorPlan',
  PDFS = 'pdfs',
  MEDIA_DETAILS = 'mediaDetails',
  PDF_DETAILS = 'pdfDetails',
  VIDEO_DETAILS = 'videoDetails',
}

export interface AvailableTourSection {
  AMENITIES: boolean;
  DETAILS: boolean;
  TOURS: boolean;
  LOCATION: boolean;
  GALLERY: boolean;
  SPECS: boolean;
  FLOOR_PLANS: boolean;
  PDFS: boolean;
  VIDEOS: boolean;
}

export interface VideoUrls extends PhotoInterface {
  uploadedByStaff: boolean;
}
export interface TenantListInterface {
  name: string;
  email: string;
}

export interface AllTenantsInterface {
  name: string;
  email: string;
  sessionId: string;
}

export interface NewSlideInterface {
  id: string;
  type: TOUR_CONTROLLER_TYPE;
}

export interface UserInterface {
  firstName: string;
  lastName: string;
  email: string;
  _id: string;
  avatar: string;
}
