import { AppStateInterface } from '../..';

export const selectSubdomainCompanyInfo = () => (state: AppStateInterface) => {
  return state.subdomain.company.companyInfo.data;
};

export const selectSubdomainCompanyLoading = () => (state: AppStateInterface) => {
  return state.subdomain.company.companyInfo.loading;
};

export const selectSubdomainBuilding = () => (state: AppStateInterface) => {
  return state.building.singleBuilding;
};
