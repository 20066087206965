import React from 'react';
import { useSelector } from 'react-redux';
import {
  BuildingFeatureFormField,
  BuildingFeatureList,
} from '../../../../../../state/building/forms/building.form';
import { selectSubdomainBuilding } from '../../../../../../state/subdomain/building/building.selector';
import './index.css';
import Images from '../../../../../../utils/img.utils';
interface Props {
  buildingData?: any;
}
const featuresMap: {
  [key in BuildingFeatureFormField]: { icon: string; label: BuildingFeatureList };
} = {
  activeCommunity: { icon: Images.Over55Icon, label: BuildingFeatureList.ACTIVE_COMMUNITY },
  basketballCourt: { icon: Images.BasketballCourtIcon, label: BuildingFeatureList.BASKETBALL },
  disabledAccess: { icon: Images.DisabledAccessIcon, label: BuildingFeatureList.DISABLED_ACCESS },
  doorman: { icon: Images.DoormanIcon, label: BuildingFeatureList.DOORMAN },
  elevator: { icon: Images.ElevatorIcon, label: BuildingFeatureList.ELEVATOR },
  fitnessCenter: { icon: Images.FitnessCenterIcon, label: BuildingFeatureList.FITNESS_CENTER },
  gatedEntry: { icon: Images.GatedEntryIcon, label: BuildingFeatureList.GATED_ENTRY },
  nearTransportation: {
    icon: Images.NearTransportationIcon,
    label: BuildingFeatureList.NEAR_TRANSPORTATION,
  },
  sportsCourt: { icon: Images.SportsCourtIcon, label: BuildingFeatureList.SPORTS_COURT },
  storage: { icon: Images.StorageIcon, label: BuildingFeatureList.STORAGE },
  tennisCourt: { icon: Images.TennisCourtIcon, label: BuildingFeatureList.TENNIS_COURT },
};

const BuildingFeaturesGrid = ({ buildingData }: Props) => {
  const building = useSelector(selectSubdomainBuilding()) || buildingData;
  return (
    <div className="grid-container">
      {Object.entries(featuresMap).map(([key, { label, icon }]) => {
        const feature = building?.[key as BuildingFeatureFormField];
        if (!feature || !feature.isActive) return null;
        return (
          <span key={key} className="grid-item">
            <span>
              <img src={icon} />
            </span>
            <span>{label}</span>
          </span>
        );
      })}
    </div>
  );
};

export default BuildingFeaturesGrid;
