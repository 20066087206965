import store from '../store/configureStore';
import axios, { CancelToken } from 'axios';
import { RESET } from '../common/constants';
const cancelToken = axios.CancelToken;

const CancellationTokens: any = {};

const INIT = '_INIT',
  SUCCESS = '_SUCCESSFUL',
  FAIL = '_FAILURE',
  dispatch = store.dispatch,
  getState = store.getState;

function init(actionName: string): void {
  dispatch({ type: actionName + INIT });
}

function success(actionName: string, payload: any): void {
  dispatch({ type: actionName + SUCCESS, payload });
}

function failure(actionName: string, payload: any): void {
  dispatch({ type: actionName + FAIL, payload });
}

function setCT(actionName: string): CancelToken {
  return new cancelToken((ct: any) => (CancellationTokens[actionName] = ct));
}

function callCT(actionName: string): void {
  if (CancellationTokens[actionName]) {
    CancellationTokens[actionName]();
  }
}

function resetState(): void {
  dispatch({ type: RESET });
}

export default { resetState, init, success, failure, setCT, callCT, dispatch, getState };
