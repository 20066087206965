import React from 'react';
import { SingleListingInterfaceNew } from '../../../../../../state/building/building.types';
import ImgTile from '../../../../../media/imgTile';
import cx from 'classnames';
import { numberWithCommas } from '../../../../../../utils/misc.utils';
import { useSelector } from 'react-redux';
import { selectSubdomainBuilding } from '../../../../../../state/subdomain/building/building.selector';
import images from '../../../../../../utils/img.utils';
import { useHistory } from 'react-router';

interface Props {
  unit: SingleListingInterfaceNew;
}

const UnitCard: React.FC<Props> = ({ unit }) => {
  const building = useSelector(selectSubdomainBuilding());
  let primaryImage = unit.media?.find(({ isPrimary }) => isPrimary);
  const unitAvailable = unit.status === 'available';
  const unitStatusClass = cx('unit-status', { 'unit-status-unavailable': !unitAvailable });
  const history = useHistory();
  const clickHandler = () => {
    history.push(`/unit/${unit?._id}`);
  };
  return (
    <div className="unit-container cursor-pointer" onClick={clickHandler}>
      {primaryImage && <ImgTile className="unit-primary-image" src={primaryImage?.url} />}
      <div className="unit-content-container">
        <div className="unit-info-container">
          <div>
            <div className={unitStatusClass}>
              {unitAvailable ? 'This unit is available' : 'This unit is occupied'}
            </div>
            <div className="unit-name">{unit.name}</div>
          </div>
          <div className="unit-price-container">
            <span className="unit-price">${numberWithCommas(unit.price)}</span>
            {building?.type === 'RENTAL' ? <span className="unit-price-duration">/mo</span> : null}
          </div>
        </div>
        <div className="unit-details-container">
          <div className="unit-details-item-container">
            <img src={images.SizeIcon} />
            <span className="unit-details-item-title">
              {unit?.size ? `${numberWithCommas(unit?.size)} SF` : 'Pending to specify'}
            </span>
          </div>

          <div className="unit-details-item-container">
            <img src={images.RoomIcon} />
            <span className="unit-details-item-title">
              {numberWithCommas(unit.bedrooms.length)} bd
            </span>
          </div>

          <div className="unit-details-item-container">
            <img src={images.BathroomIcon} />
            <span className="unit-details-item-title">{numberWithCommas(unit.bathrooms)} ba</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitCard;
