import { GetStateInterface } from '../../types/GetStateInterface';

export interface ProductStateInterface {
  product: GetStateInterface<ProductResponseInterface>;
}

export interface ProductResponseInterface {
  _id: string;
  name: string;
  includes: string[];
  prices: any;
}

export interface ProductRequestInterface {
  _id: string;
}

export enum ProductActions {
  PRODUCT = 'PRODUCT',
  GET_SINGLE_PRICE = 'GET_SINGLE_PRICE',
  PRODUCT_INIT = 'PRODUCT_INIT',
  PRODUCT_SUCCESSFULL = 'PRODUCT_SUCCESSFULL',
  PRODUCT_FAILURE = 'PRODUCT_FAILURE',
  PRODUCT_UPDATE = 'PRODUCT_UPDATE',
  GET_SINGLE_LISTING_CLEAR = 'GET_SINGLE_LISTING_CLEAR',
}
