import React from 'react';
import SVG500 from '../../../components/common/svg/500';
import withTheme from '../../../components/hoc/withTheme';
import images from '../../../utils/img.utils';
import ImgTile from '../../../components/media/imgTile';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';

const InternalServerErrorPage: React.FC = () => {
  const me = useSelector((state: AppStateInterface) => state.me.me.data);
  const company = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );
  return (
    <div className="internalServerError sec--vertical sec--vertical-md">
      <div className="container container--large">
        <a className="listing__logo" href="/">
          <ImgTile
            src={me?.company?.settings?.darkLogo?.url || company?.settings?.darkLogo?.url || ''}
            default={images.darkLogo}
            alt=""
          />
        </a>
        <div className="notfound--content sec--vertical-middle">
          <div className="container--xs">
            <div className="notfound--detail text-center">
              <span className="notfound__img">
                <SVG500 />
              </span>
              <h3 className="heading__medium heading__medium-black font-weight-normal">
                Unexpected error!
              </h3>
              <p className="notfound__paragraph text__gray">
                Sorry, we are working to fix it. Please, be back soon.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTheme(InternalServerErrorPage);
