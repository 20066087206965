import { BUILDING_TEMPLATE_STATUS, LISTING_STATUS } from '../../../common/constants';
import { GetStateInterface } from '../../../types/GetStateInterface';
import { PhotoInterface } from '../../listing/listing.types';
import { BuildingValueInNumber, BuildingValueInString } from '../../building/building.types';

export interface BuildingStateInterface {
  allBuildings: GetStateInterface<GetAllSdBuildingsResponseInterface>;
}

export enum BuildingActions {
  SD_GET_ALL_BUILDINGS = 'SD_GET_ALL_BUILDINGS',
  SD_GET_ALL_BUILDINGS_INIT = 'SD_GET_ALL_BUILDINGS_INIT',
  SD_GET_ALL_BUILDINGS_CLEAR = 'SD_GET_ALL_BUILDINGS_CLEAR',
  SD_GET_ALL_BUILDINGS_FAILURE = 'SD_GET_ALL_BUILDINGS_FAILURE',
  SD_GET_ALL_BUILDINGS_SUCCESSFUL = 'SD_GET_ALL_BUILDINGS_SUCCESSFUL',
}

export interface GetAllSdBuildingsQueryInterface {
  companyId: string;
  subMarketId?: string;
  searchTerm?: string;
  limit?: number;
  skip?: number;
  listingStatus?: LISTING_STATUS;
  buildingStatus?: BUILDING_TEMPLATE_STATUS;
  sortBy?: sortByTypes;
  orderBy?: orderByTypes;
}

export interface GetAllSdBuildingsResponseInterface {
  data: GetAllSdBuildingsDataInterface[];
  hasMore: boolean;
  skip: number;
}

export interface GetAllSdBuildingsSingleBuildingInterface {
  _id: string;
  media: PhotoInterface[];
  location: string;
  fullLocation: any;
  updatedAt: string;
  totalListings: number;
  buildingSize: BuildingValueInNumber;
  latitude: BuildingValueInString;
  longitude: BuildingValueInString;
  publishedTemplate?: string;
  selectedTemplate?: string;
  roomId?: string;
}

export interface GetAllSdBuildingsDataInterface {
  _id: string;
  market: string;
  subMarket: string;
  buildings: Array<GetAllSdBuildingsSingleBuildingInterface>;
}

export type sortByTypes = 'size' | undefined;

export type orderByTypes = 'ascending' | 'descending' | undefined;

export interface SortAndOrderInterface {
  sortBy: sortByTypes;
  orderBy: orderByTypes;
}
