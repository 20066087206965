import { LeftSidebarActions, LeftSidebarStateInterface } from './leftSidebar.types';

const defaultState: LeftSidebarStateInterface = {
  show: true,
};

export default function LeftSidebarReducer(state = defaultState, action: any) {
  switch (action.type) {
    case LeftSidebarActions.TOGGLE_SHOW:
      return {
        ...state,
        show: !state.show,
      };

    default:
      return state;
  }
}
