import { REVIEW_STATE, RECORDING_STATE } from '../../common/constants';
import { AudioVoiceOverInterface } from '../listing/listing.types';

export interface VideoProgress {
  played: number;
  playedSeconds: number;
  loaded: number;
  loadedSeconds: number;
}

export interface ReviewStateInterface {
  currentSegment: number;
  videoTimeStamp: string;
  isRecording: boolean;
  reviewState: REVIEW_STATE;
  recordingState: RECORDING_STATE;
  comments: any;
  audios: AudioVoiceOverInterface[];
}

export enum ReviewActionTypes {
  REVIEW_UPDATE = 'REVIEW_UPDATE',
  CHANGE_SEGMENT = 'CHANGE_SEGMENT',
  CHANGE_VIDEO_STATUS = 'CHANGE_VIDEO_STATUS',
  CHANGE_AUDIO_STATUS = 'CHANGE_AUDIO_STATUS',
  SET_VIDEO_TIME = 'SET_VIDEO_TIME',
  SET_AUDIOS = 'SET_AUDIOS',
}

export interface PostBlobInterface {
  listingId: string;
  name: string;
  url: string;
  segment: number;
  playVideoStartTimestamp: string;
}
