import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { ContactForm } from '../../../../../state/subdomain/listing/listing.form';
import { errorMsg } from '../../listing.component';
import InputMask from 'react-input-mask';
import { MatterPortForm } from '../../../../../state/tour/tour.form';
import { StyledFormControl } from '../../../../../common/styled';

const PlaceHolder = {
  [ContactForm.name]: 'Name*',
  [ContactForm.email]: 'Email*',
  [ContactForm.message]: 'Your Message*',
};
interface Props {
  reg: any;
  name: ContactForm;
  full?: boolean;

  err?: any;
}
const FormInput: React.FC<Props> = props => {
  const { name, reg, full, err } = props;

  return (
    <React.Fragment>
      <div className={`col col-12 ${!full && 'col-sm-12'}`}>
        <Form.Group className="forms--group forms--group-radius mb-4">
          <StyledFormControl
            as={full ? 'textarea' : 'input'}
            placeholder={PlaceHolder[name]}
            name={name}
            ref={reg}
            className={`form-control ${err && 'error'}`}
          />
          {errorMsg(err)}
        </Form.Group>
      </div>
    </React.Fragment>
  );
};
export default FormInput;
