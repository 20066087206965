import { TourActionTypes as actionName, GetTourInfo, TourStateInterface } from './tour.types';
import { initialCallState, simplifyParticipants } from './tour.action';

const defaultState: TourStateInterface = {
  name: '',
  callObject: null,
  callState: initialCallState,
  message: null,

  tourInfo: {} as GetTourInfo,
  tourNeighbour: null,
  tourListing: null,
};

export default function TourReducer(state = defaultState, action: any) {
  switch (action.type) {
    case actionName.PARTICIPANTS_CHANGE: {
      const data = action.payload;
      const leader = data.leader;
      const participants = data.participants;

      return {
        ...state,
        callState: {
          ...state.callState,
          participants: simplifyParticipants(participants),
          tourVideoTrack: leader?.screenVideoTrack,
          tourAudioTrack: leader?.audioTrack,
          hasTourStarted: Boolean(leader),
          leaderSessionId: leader?.session_id,
          isAudioMuted: !participants?.local?.audio,
          isVideoMuted: !participants?.local?.video,
        },
      };
    }
    case actionName.NAME_RECEIVED:
      return {
        ...state,
        callState: {
          ...state.callState,
          participantNames: {
            ...state.callState.participantNames,
            [action.sessionId]: action.name,
          },
        },
      };

    case actionName.RECORDING_CHANGE:
      return {
        ...state,
        callState: {
          ...state.callState,
          isRecording: action.value,
        },
      };

    case actionName.TOUR_ENDED:
      return {
        ...state,
        callState: {
          ...state.callState,
          ...initialCallState,
          hasTourEnded: true,
        },
      };

    case actionName.GOT_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };

    case actionName.SD_GET_SINGLE_TOUR_INFO:
      return {
        ...state,
        tourInfo: action.payload,
      };

    case actionName.SD_CHANGE_TOUR_LISTING:
      return {
        ...state,
        tourListing: action.payload,
        tourNeighbour: null,
      };

    case actionName.SD_CHANGE_TOUR_NEIGHBOUR:
      return {
        ...state,
        tourNeighbour: action.payload,
        tourListing: null,
      };

    default:
      return state;
  }
}
