import React from 'react';

import { DEFAULT_PRIMARY_COLOR } from '../../../common/constants';

const SVGDarkLogo: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="188.315"
      height="30.702"
      viewBox="0 0 188.315 30.702"
      fill={DEFAULT_PRIMARY_COLOR}
    >
      <g transform="translate(-69.751 -47.551)">
        <path
          className="a"
          d="M18.6-8.556a8.424,8.424,0,0,0-8.711-8.8,8.424,8.424,0,0,0-8.711,8.8C1.178-3.193,4.867.279,9.734.279A8.556,8.556,0,0,0,18.6-8.556Zm-13.826,0c0-3.875,2.387-5.735,5.053-5.735,2.635,0,5.146,1.86,5.146,5.735S12.369-2.79,9.734-2.79C7.068-2.79,4.774-4.681,4.774-8.556ZM22.537,0H26.1V-14.2h3.162v-2.883H26.1V-18.29c0-1.953.744-2.7,2.914-2.7v-2.945c-4.464,0-6.479,1.7-6.479,5.642v1.209H20.522V-14.2h2.015ZM32.829,0h3.565V-14.2h3.162v-2.883H36.394V-18.29c0-1.953.744-2.7,2.914-2.7v-2.945c-4.464,0-6.479,1.7-6.479,5.642v1.209H30.814V-14.2h2.015Zm9.858,0h3.534V-17.081H42.687Zm1.8-19.344a2.23,2.23,0,0,0,2.232-2.263,2.23,2.23,0,0,0-2.232-2.263,2.236,2.236,0,0,0-2.263,2.263A2.236,2.236,0,0,0,44.485-19.344ZM49.693-8.556c0,5.363,3.472,8.835,8.37,8.835a7.672,7.672,0,0,0,7.874-5.673H62.124a3.9,3.9,0,0,1-4.061,2.7C55.211-2.7,53.32-4.8,53.32-8.556c0-3.72,1.891-5.828,4.743-5.828a3.992,3.992,0,0,1,4.061,2.7h3.813c-.961-3.6-3.689-5.673-7.874-5.673C53.165-17.36,49.693-13.888,49.693-8.556Zm26.97-5.828c2.635,0,4.712,1.674,4.774,4.309H71.982A4.652,4.652,0,0,1,76.663-14.384Zm8,9.3H80.848A4.135,4.135,0,0,1,76.818-2.7a4.7,4.7,0,0,1-4.867-4.526H85a10.9,10.9,0,0,0,.124-1.736,7.966,7.966,0,0,0-8.308-8.4c-5.053,0-8.525,3.441-8.525,8.8S71.92.279,76.818.279A7.8,7.8,0,0,0,84.661-5.084Zm4.5.341C89.156-1.271,91.047,0,94.085,0h2.79V-2.945h-2.17c-1.488,0-1.984-.527-1.984-1.8V-14.2h4.154v-2.883H92.721v-4.247H89.156v4.247H87.141V-14.2h2.015ZM116.4-8.556a8.424,8.424,0,0,0-8.711-8.8,8.424,8.424,0,0,0-8.711,8.8c0,5.363,3.689,8.835,8.556,8.835A8.556,8.556,0,0,0,116.4-8.556Zm-13.826,0c0-3.875,2.387-5.735,5.053-5.735,2.635,0,5.146,1.86,5.146,5.735s-2.6,5.766-5.239,5.766C104.873-2.79,102.579-4.681,102.579-8.556Zm32.8-8.525h-3.534v9.517c0,3.131-1.705,4.743-4.309,4.743-2.573,0-4.278-1.612-4.278-4.743v-9.517h-3.5V-7.037c0,4.774,3.038,7.285,7.037,7.285a6.358,6.358,0,0,0,5.053-2.294V0h3.534Zm8.184,8.091c0-3.627,1.612-4.712,4.216-4.712h.9V-17.36a5.564,5.564,0,0,0-5.115,2.759v-2.48h-3.534V0h3.534Z"
          transform="translate(109.39 74)"
        />
        <g transform="translate(70 48.001)">
          <path
            className="a"
            d="M26.636,69.939c.59,1.992,1.3,4.233,0,5.727-1.179,1.494-4.125,1.37-5.775.872C12.609,73.8,4,64.087,2.471,55.621a4.441,4.441,0,0,1,.472-3.486A3.788,3.788,0,0,1,6.008,50.89a17.373,17.373,0,0,1,6.484,2.117l.825-1.619A18.093,18.093,0,0,0,5.3,49.147C2,49.4-.121,51.761.7,56.119c1.415,7.843,8.015,15.936,14.734,20.044a18.267,18.267,0,0,0,6.6,2.739c2.593.25,5.3-.373,6.483-2.864,1.061-2.24.236-4.855-.471-7.1a18.676,18.676,0,0,0-2.123-4.731l-1.531,1A13.317,13.317,0,0,1,26.636,69.939Z"
            transform="translate(-0.015 -49.147)"
          />
          <path
            className="a"
            d="M18.589,80.021A13.356,13.356,0,1,0,5.232,66.664,13.371,13.371,0,0,0,18.589,80.021Zm0-24.033A10.678,10.678,0,1,1,7.913,66.664,10.688,10.688,0,0,1,18.589,55.988Z"
            transform="translate(-5.232 -51.071)"
          />
          <path
            className="a"
            d="M32.194,66.665A13.606,13.606,0,1,1,18.589,53.06,13.606,13.606,0,0,1,32.194,66.665Zm-3.178,0A10.427,10.427,0,1,0,18.589,77.093,10.427,10.427,0,0,0,29.017,66.665Zm2.678,0A13.106,13.106,0,1,0,18.589,79.771,13.106,13.106,0,0,0,31.694,66.665Zm-2.178,0A10.927,10.927,0,1,1,18.589,55.738,10.927,10.927,0,0,1,29.517,66.665Z"
            transform="translate(-5.232 -51.071)"
          />
          <path
            className="a"
            d="M5.32,48.7a18.533,18.533,0,0,1,8.6,2.508L12.68,53.628a17.136,17.136,0,0,0-6.725-2.291,3.339,3.339,0,0,0-2.65,1.071,3.99,3.99,0,0,0-.391,3.132c1.507,8.327,10.02,17.891,18.077,20.566,1.577.476,4.284.557,5.306-.737,1.137-1.309.466-3.426-.049-5.161a12.563,12.563,0,0,0-2.5-5.112l2.323-1.511a19.167,19.167,0,0,1,2.415,5.221c.693,2.2,1.6,4.995.449,7.424-1.249,2.64-4.076,3.4-6.983,3.112C12.285,77.3,2.041,66.066.262,56.2-.513,52.113,1.206,49,5.32,48.7ZM1.146,56.036c1.392,7.718,7.916,15.7,14.527,19.744a17.854,17.854,0,0,0,6.46,2.683c2.46.233,4.925-.381,5.983-2.616.972-2.051.228-4.481-.494-6.768a18.3,18.3,0,0,0-1.835-4.237l-.744.484a14.556,14.556,0,0,1,2.067,4.629c.608,2.049,1.285,4.373-.134,6.007-1.267,1.606-4.323,1.588-6.245,1.008-8.438-2.8-17.143-12.653-18.7-21.267a4.878,4.878,0,0,1,.528-3.8,4.225,4.225,0,0,1,3.5-1.463,17.767,17.767,0,0,1,6.24,1.948l.415-.815A17.658,17.658,0,0,0,5.281,49.6C1.8,49.868.484,52.538,1.146,56.036Z"
            transform="translate(-0.015 -49.147)"
          />
        </g>
      </g>
    </svg>
  );
};

export default SVGDarkLogo;
