import React from 'react';

interface Props {
  className?: string;
}

const SmallLoader: React.FC<Props> = (props: Props) => {
  return (
    <div className={props?.className ? `lds-spinner ${props.className}` : 'lds-spinner'}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default SmallLoader;
