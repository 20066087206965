import { ListingActions, ListingStateInterface } from './listing.types';
import { GetState } from './../../../types/GetStateInterface';

const defaultState: ListingStateInterface = {
  allListings: GetState,
  singleListing: GetState,
};

export default function ListingReducer(state = defaultState, action: any) {
  switch (action.type) {
    case ListingActions.SD_GET_ALL_LISTINGS_INIT:
      return {
        ...state,
        allListings: {
          ...state.allListings,
          loading: true,
        },
      };
    case ListingActions.SD_GET_ALL_LISTINGS_FAILURE:
      return {
        ...state,
        allListings: {
          ...state.allListings,
          loading: false,
          error: action.payload,
          hasData: true,
        },
      };
    case ListingActions.SD_GET_ALL_LISTINGS_SUCCESSFUL:
      return {
        ...state,
        allListings: {
          ...state.allListings,
          loading: false,
          error: null,
          data: action.payload,
          hasData: true,
        },
      };
    case ListingActions.SD_GET_ALL_LISTINGS_CLEAR:
      return {
        ...state,
        allListings: {
          ...state.allListings,
          loading: false,
          error: null,
          data: null,
          hasData: false,
        },
      };

    case ListingActions.SD_GET_SINGLE_LISTING_INIT:
      return {
        ...state,
        singleListing: {
          ...state.singleListing,
          loading: true,
          hasData: false,
          data: null,
          error: null,
        },
      };
    case ListingActions.SD_GET_SINGLE_LISTING_FAILURE:
      return {
        ...state,
        singleListing: {
          ...state.singleListing,
          loading: false,
          error: action.payload,
          hasData: true,
          data: null,
        },
      };
    case ListingActions.SD_GET_SINGLE_LISTING_SUCCESSFUL:
      return {
        ...state,
        singleListing: {
          ...state.singleListing,
          loading: false,
          error: null,
          data: action.payload,
          hasData: true,
        },
      };
    case ListingActions.SD_GET_SINGLE_LISTING_CLEAR:
      return {
        ...state,
        singleListing: {
          ...state.singleListing,
          loading: false,
          error: null,
          data: null,
          hasData: false,
        },
      };

    case ListingActions.CHANGE_TOUR_LISTING:
      return {
        ...state,
        singleListing: {
          ...state.singleListing,
          loading: false,
          data: action.payload,
          hasData: true,
          error: null,
        },
      };
    default:
      return state;
  }
}
