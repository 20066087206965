import React from 'react';
import { StyledAnchor } from '../../../common/styled';
import withTheme from '../../../components/hoc/withTheme';
import SVGNetworkError from '../../../components/common/svg/network-error';
import SVGDarkLogo from '../../../components/common/svg/dark-logo';
import { DEFAULT_PRIMARY_COLOR } from '../../../common/constants';

const NetworkErrorPage: React.FC = () => {
  return (
    <div className="networkError sec--vertical sec--vertical-md">
      <div className="container container--large">
        <a href="/" className="listing__logo">
          <SVGDarkLogo />
        </a>
        <div className="notfound--content sec--vertical-middle">
          <div className="container--xs">
            <div className="notfound--detail text-center">
              <span className="notfound__img">
                <SVGNetworkError />
              </span>
              <h3 className="heading__medium heading__medium-black font-weight-normal">
                Oops, your internet connection seems off...
              </h3>
              <p className="notfound__paragraph text__gray">
                Please check your internet connection and{' '}
                <StyledAnchor
                  style={{
                    color: DEFAULT_PRIMARY_COLOR,
                  }}
                  className="plain av__link text-nowrap"
                  onClick={() => window?.location?.reload()}
                >
                  reload
                </StyledAnchor>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTheme(NetworkErrorPage);
