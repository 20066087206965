import React from 'react';
import { useSelector } from 'react-redux';
import { selectSubdomainBuilding } from '../../../../../../state/subdomain/building/building.selector';
import BuildingLocation from '../../../../../subdomain/listing/listDetail/building/location';
import './index.css';

const BuildingDescriptionSection = () => {
  const building = useSelector(selectSubdomainBuilding());
  return (
    <>
      <div className="building-intro-container">
        <div className="building-description">{building?.description}</div>
        <div className="building-location">{building?.location}</div>
      </div>
      <div className="building-map">
        <BuildingLocation
          lat={parseFloat(building?.latitude || '0')}
          lng={parseFloat(building?.longitude || '0')}
          className="building-map"
        />
      </div>
    </>
  );
};

export default BuildingDescriptionSection;
