import React, { useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
  ContactFormSchema,
  ContactForm as TF,
} from '../../../../../state/subdomain/listing/listing.form';
import FormInput from './input';
import { useParams } from 'react-router';
import { TourContactInterface } from '../../../../../state/subdomain/listing/listing.types';
import { useTourContact } from '../../../../../state/subdomain/listing/listing.hook';
import { showNotification } from '../../../../../utils/misc.utils';
import { errorExtractor } from '../../../../../utils/error.utils';
import SmallLoader from '../../../../common/small.loader';
import { useYupValidationResolver } from '../../../../../utils/form.utils';
import { StyledFilledButton } from '../../../../../common/styled';

interface Props {
  isCompleted: () => void;
  hide: () => void;
  listingId?: string;
  buildingId?: string;
}
const RequestForm: React.FC<Props> = props => {
  const { isCompleted: handleComplete, hide, listingId, buildingId } = props;
  const { res: requestRes, postContactRequest } = useTourContact();
  const { register, handleSubmit, errors } = useForm({
    resolver: useYupValidationResolver(ContactFormSchema),
  });

  const onSubmit = (data: any, e: any) => {
    e.preventDefault();
    if (listingId) {
      const finalData = { listingId, ...data } as TourContactInterface;
      postContactRequest(finalData);
    }
    if (buildingId) {
      const finalData = { buildingId, ...data } as TourContactInterface;
      postContactRequest(finalData);
    }
  };

  useEffect(() => {
    if (!requestRes.loading && requestRes.hasData) {
      if (requestRes.error)
        showNotification('error', 'Tour Request Error', errorExtractor(requestRes.error));
      else if (requestRes.data) {
        handleComplete();
      }
    }
  }, [requestRes]);

  return (
    <div className="listingModal--tour">
      <h3 className="heading__medium heading__medium-black mb-4 pb-3 font-semibold">Contact Us</h3>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-100">
          <div className="row row--space-10">
            <FormInput reg={register} name={TF.name} err={errors[TF.name]} />
            <FormInput reg={register} name={TF.email} err={errors[TF.email]} />
            <FormInput reg={register} name={TF.message} full err={errors[TF.message]} />
          </div>
          <div className="row row--space-10 flex-sm-row-reverse btn--canvas-sl">
            <div className="col col-12 col-sm-6">
              <StyledFilledButton
                type="submit"
                className="admin__button button__radius w-100 mb-3 mb-sm-0"
                disabled={requestRes.loading}
              >
                {!requestRes.loading && 'Send'}
                {requestRes.loading && (
                  <React.Fragment>
                    Submitting <SmallLoader />
                  </React.Fragment>
                )}
              </StyledFilledButton>
            </div>
            <div className="col col-12 col-sm-6">
              <Button onClick={hide} className="outline__button button__radius w-100">
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default RequestForm;
