import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import SVG404 from '../../../components/common/svg/404';
import { StyledAnchor } from '../../../common/styled';
import withTheme from '../../../components/hoc/withTheme';
import ImgTile from '../../../components/media/imgTile';
import { MAIN_APP_URL } from '../../../common/constants';
import images from '../../../utils/img.utils';

const NotFoundPage: React.FC = () => {
  const history = useHistory();
  const me = useSelector((state: AppStateInterface) => state.me.me.data);
  const company = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );

  const goBack = () => {
    if (history?.goBack) {
      history.goBack();
    } else {
      window.location.href = MAIN_APP_URL;
    }
  };

  const goToHomePage = () => {
    if (me || company) {
      history?.push('/');
    } else {
      window.location.href = MAIN_APP_URL;
    }
  };

  return (
    <div className="notfound sec--vertical sec--vertical-md">
      <div className="container container--large">
        <a className="listing__logo" onClick={goToHomePage}>
          <ImgTile
            src={me?.company?.settings?.darkLogo?.url || company?.settings?.darkLogo?.url || ''}
            default={images.darkLogo}
            alt=""
          />
        </a>
        <div className="notfound--content sec--vertical-middle">
          <div className="container--xs">
            <div className="notfound--detail text-center">
              <span className="notfound__img">
                <SVG404 />
              </span>
              <h3 className="heading__medium heading__medium-black font-weight-normal">
                Page Not Found!
              </h3>
              <p className="notfound__paragraph text__gray">
                Sorry, we couldn’t find the page you are looking for. Please check the URL or{' '}
                <StyledAnchor className="plain av__link text-nowrap" onClick={goBack}>
                  go back
                </StyledAnchor>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTheme(NotFoundPage);
