import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSubdomainBuilding } from '../../../../../../state/subdomain/building/building.selector';
import images from '../../../../../../utils/img.utils';
import Dropdown from '../Dropdown';
import './index.css';
import UnitCard from './UnitCard';

enum SORT_BY_OPTIONS {
  ALL = 'All',
  PRICE_LOW_TO_HIGH = 'Price: Low to high',
  PRICE_HIGH_TO_LOW = 'Price: High to low',
}

const dropdownOptions = [
  SORT_BY_OPTIONS.ALL,
  SORT_BY_OPTIONS.PRICE_LOW_TO_HIGH,
  SORT_BY_OPTIONS.PRICE_HIGH_TO_LOW,
].map(val => ({ label: val, value: val }));

const Units = () => {
  const [showAvailable, setShowAvailable] = useState(true);
  const [sortOrder, setSortOrder] = useState(SORT_BY_OPTIONS.ALL);
  const [showSortDropdown, setShowSortDropdown] = useState(false);
  const building = useSelector(selectSubdomainBuilding());

  const toggleShowAvailable = () => setShowAvailable(state => !state);
  const toggleShowSortDropdown = () => setShowSortDropdown(state => !state);

  let units = building?.listings || [];
  if (showAvailable) units = units.filter(({ status }) => status === 'available');
  if (sortOrder === SORT_BY_OPTIONS.PRICE_LOW_TO_HIGH)
    units = units.sort((a, b) => a.price - b.price);

  if (sortOrder === SORT_BY_OPTIONS.PRICE_HIGH_TO_LOW)
    units = units.sort((a, b) => b.price - a.price);

  units = units.filter(unit => {
    return unit.published;
  });

  return (
    <>
      <div className="unit-filters-container">
        <div className="available-units-filter-container">
          <span className="units-filter-title">Only available units</span>
          <div>
            <label className="switch m-0">
              <input type="checkbox" checked={showAvailable} onChange={toggleShowAvailable} />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        <div className="available-units-filter-container">
          <span className="units-filter-title" onClick={toggleShowSortDropdown}>
            Sort by: {sortOrder}
          </span>
          <img src={images.UnitsSortIcon} />
          <Dropdown
            options={dropdownOptions}
            onChange={option => setSortOrder(option.value)}
            show={showSortDropdown}
            toggle={toggleShowSortDropdown}
          />
        </div>
      </div>
      <div className="units-container">
        {units.map(unit => (
          <UnitCard unit={unit} key={unit._id} />
        ))}
      </div>
    </>
  );
};

export default Units;
