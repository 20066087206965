import { TourOrderBy, TourSortBy } from '../state/tour/tour.types';
import { RecentOrderBy, RecentSortBy } from '../state/listing/listing.types';

export const DEFAULT_PRIMARY_COLOR = '#0c1332';

export const DEFAULT_SECONDARY_COLOR = '#6a7074';

export const RESET = 'RESET';

export const MAIN_APP_URL = 'https://app.hometour.com';

export const MALFORMED_URL_ERROR = `The link you entered does not look like a valid link. If someone gave you this link, you may need to ask them to check that it's correct.`;

export const IMAGE_LIMIT = 48;
export const ALL_MEDIA_LIMIT = 15;
export const TOUR_UPLOAD_LIMIT = 1000;
export const DOC_LIMIT = 15;
export const DOWNLOAD_GAP = 2;
export enum AccountTypeEnum {
  TRIAL = 'TRIAL',
  PAID = 'PAID',
}
export enum ListingTemplateStatusEnum {
  PUBLISHED = 'published',
  UNPUBLISHED = 'unpublished',
}
export enum SEPARATORS {
  DASH = '-',
  COMMA = ',',
  SEMI_COLON = ';',
  COLON = ':',
  SPACE = ' ',
  DOT = '.',
  SLASH = '/',
}
export enum LISTING_STATUS {
  DRAFT = 'draft',
  REVIEW = 'review',
  PUBLISHED = 'published',
  AVAILABLE = 'available',
}
export enum VIDEO_STATUS {
  PENDING = 'pending',
  REJECTED = 'rejected',
  ACCEPTED = 'accepted',
  UPLOADED = 'uploaded',
  VOICEOVER_SUBMITTED = 'voiceover_submitted',
}

export const DEFAULT_BUILDING_LIMIT = 10;

export const DEFAULT_LISTING_LIMIT = 10;

export enum USER_ROLES {
  ADMIN = 'admin',
  SUPER_ADMIN = 'super_admin',
  BROKER = 'broker',
}

export const ALLOWED_SUBDOMAINS_MAIN_APP = [
  'test',
  'staging',
  'app',
  'localhost',
  'www',
  'hometour',
];

export const COLOR_OPTIONS = [
  {
    color: '#0638E7',
    title: 'Blue',
  },
  {
    color: '#0099FF',
    title: 'Light Blue',
  },
  {
    color: '#621EB8',
    title: 'Purple',
  },
  {
    color: '#EA5C0C',
    title: 'Orange',
  },
  {
    color: '#ED2C2C',
    title: 'Red',
  },
  {
    color: '#219D35',
    title: 'Green',
  },
  {
    color: '#C2FFF0',
    title: 'Mint Green',
  },
  {
    color: '#000000',
    title: 'Black',
  },
];

export const DEFAULT_LOC = { lat: 47.7511, lng: -120.7401 };

export const ALLOWED_LOGO_IMAGE_MIME_TYPES = [
  'image/png',
  'image/svg+xml',
  'image/jpeg',
  'image/jpg',
];

export const ALLOWED_LOGO_IMAGE_TYPES = 'png, jpeg, jpg & svg';

export const ALLOWED_EMAIL_LOGO_IMAGE_MIME_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];

export const ALLOWED_EMAIL_LOGO_IMAGE_TYPES = 'png, jpeg & jpg';

export const ALLOWED_LOGO_MAX_SIZE_IN_MB = 1;
export const DATE_FORMAT = 'MM-DD-YYYY';

export enum NODE_ENV {
  PRODUCTION = 'production',
  STAGING = 'staging',
  TEST = 'test',
  DEVELOPMENT = 'development',
}

export const MS_BETWEEN_SPEAKER_CHANGE = 350;
export const MS_BETWEEN_NAME_REQUESTS = 2500;

export enum DailyCo {
  APP_MESSAGE = 'app-message',
  START_RECORD = 'recording-started',
  STOP_RECORD = 'recording-stopped',
  MEETING_JOIN = 'joined-meeting',
  PARTICIPANT_JOIN = 'participant-joined',
  PARTICIPANT_UPDATE = 'participant-updated',
  PARTICIPANT_LEFT = 'participant-left',
  MEETING_LEFT = 'left-meeting',
  SPEAKER_CHANGE = 'active-speaker-change',
}
export enum DailyMessage {
  SEND_NAME = 'sendName',
  REQUEST_NAME = 'requestName',
  END_TOUR = 'endTour',
}
export const GALLERY_IMAGES = 6;

export const MAXIMUM_AMENITIES_ALLOWED = 10;

export const MAXIMUM_BUILDING_HOURS_ALLOWED = 10;

export enum REVIEW_STATE {
  IDLE = 'idle',
  INITIALIZE = 'initialize',
  START = 'start',
  READY = 'ready',
  PAUSE = 'pause',
  RESUME = 'resume',
  STOP = 'stopped',
  FINISH = 'finish',
  DISABLE = 'disable',
  ERROR = 'failed',
}
export enum RECORDING_STATE {
  IDLE = 'idle',
  ACQUIRE = 'acquiring_media',
  READY = 'ready',
  RECORD = 'recording',
  STOP = 'stopped',
  ERROR = 'failed',
}
export const REVIEW_SEGMENTS = 1;
export const VIDEO_TIMER = 5;
export const VIDEO_TIME_INTERVAL = 100;
export const AUDIO_TIME_INVERVAL = 1000;
export const HOST_TOUR_WISTIA_ID = 'ekm8duecux';

export const JOIN_TOUR_WISTIA_ID = 'mrgzejrvc0';

export const COMMENT_REQUIRED_ERROR = 'Either text or image is required';

export const ALLOWED_COMMENT_IMAGE_MIME_TYPES = [
  'image/svg+xml',
  'image/jpeg',
  'image/jpg',
  'image/png',
];

export const ALLOWED_COMMENT_IMAGE_TYPES = 'png, jpeg, jpg & svg';

export const ALLOWED_COMMENT_IMAGE_MAX_SIZE_IN_MB = 10;

export enum TOUR_TYPES {
  LIVE = 'liveIntegratedVideoTour',
  PRESENT = 'presentationStyleTourOnly',
  BUILDING = 'buildingPresentationStyleTourOnly',
  POST = 'postTour',
}

export enum REQUEST_MEDIA_TYPES {
  VIDEO_TOUR = 'matterportVideoTour',
  PHOTOGRAPHY = 'matterportPhotography',
}

export const DEFAULT_UPCOMING_TOURS_LIMIT = 3;

export const DEFAULT_RECENT_LISTINGS_LIMIT = 20;

export const DEFAULT_TOURS_LIMIT = 10;

export const RECENT_LISTINGS_SORTING_OPTIONS = [
  {
    eventKey: 'createdAt/descending',
    value: 'Most Recent',
  },
  {
    eventKey: 'updatedAt/descending',
    value: 'Last Modified',
  },
  {
    eventKey: 'availableNow/ascending',
    value: 'Available Now',
  },
  {
    eventKey: 'availableInNinety/descending',
    value: 'Available in 90 Days',
  },
  {
    eventKey: 'size/descending',
    value: 'SF High to Low',
  },
  {
    eventKey: 'size/ascending',
    value: 'SF Low to High',
  },
  {
    eventKey: 'monthlyRent/descending',
    value: 'Price High to Low',
  },
  {
    eventKey: 'monthlyRent/ascending',
    value: 'Price Low to High',
  },
];
export const BUILDING_LISTINGS_SORTING_OPTIONS = [
  {
    eventKey: 'updatedAt/descending',
    value: 'Last Modified',
  },
  {
    eventKey: 'available/ascending',
    value: 'Available Now',
  },
  {
    eventKey: 'availableInNinety/descending',
    value: 'Available in 90 Days',
  },
  {
    eventKey: 'size/descending',
    value: 'SF High to Low',
  },
  {
    eventKey: 'size/ascending',
    value: 'SF Low to High',
  },
  {
    eventKey: 'monthlyRent/descending',
    value: 'Price High to Low',
  },
  {
    eventKey: 'monthlyRent/ascending',
    value: 'Price Low to High',
  },
];
export const TOURS_SORTING_OPTIONS = [
  {
    eventKey: 'updatedAt/descending',
    value: 'Last Modified',
  },
  {
    eventKey: 'tourType/ascending',
    value: 'Live Integrated Video (Descending)',
  },
  {
    eventKey: 'tourType/descending',
    value: 'Tour Only (Descending)',
  },
];

export const DEFAULT_TOUR_OPTION_VALUE = 'Last Modified';

export const DEFAULT_RECENT_LISTINGS_OPTION_VALUE = 'Recently Created';

export const DEFAULT_TOUR_SORT_BY: TourSortBy = 'updatedAt';

export const DEFAULT_TOUR_ORDER_BY: TourOrderBy = 'descending';

export const DEFAULT_RECENT_LISTING_SORT_BY: RecentSortBy = 'createdAt';

export const DEFAULT_RECENT_LISTING_ORDER_BY: RecentOrderBy = 'descending';

export const DEFAULT_INTERCOM_CUSTOM_LAUNCHER_ID = 'needHelp';

export const DEFAULT_LISTING_COUNT_IN_TOUR = 2;

export const DEFAULT_LISTING_OPTION_VALUE = 'Select One';

// decricated keeping for reference
export enum BUILDING_SEARCH_TYPE {
  LISTINGS = 'listings',
  LOCATION = 'location',
  MEDIA = 'media',
  FLOOR_PLANS = 'floor plans',
  SPECS = 'specs',
  AMENITIES = 'amenities',
  HOURS = 'building hours',
}

export enum BUILDING_DETAILS_SUB_TABS {
  DETAILS = 'building details',
  FEATURES = 'features',
  EXTERIOR = 'exterior',
}
// depricated, keeping for reference
export enum BUILDING_TOP_TAB_TYPES {
  OVERVIEW = 'overview',
  BROKERS = 'brokers',
  PRICING_PLAN = 'pricingPlan',
}

export enum BUILDING_TOP_TAB_TYPES_NEW {
  UNITS = 'units',
  AGENTS = 'agents',
  ABOUT = 'about',
  WEBSITE = 'website',
}
export enum BUILDING_UNIT_TAB_TYPES {
  GENERAL = 'general',
  BEDROOMS = 'bedrooms',
  INTERIOR = 'interior',
  APPLIANCES_AMENITIES = 'Appliances & Amenities',
  MEDIA = 'media',
}

export enum LISTING_SEARCH_TYPE {
  MEDIA = 'media',
  MAP = 'map',
  FLOOR_PLANS = 'floor plans',
  DETAILS = 'details',
  INTERIOR = 'interior',
  EXTERIOR = 'exterior',
  AMENITIES = 'amenities',
  DESCRIPTION = 'description',
}

export const DEFAULT_LEED_CERTIFICATIONS = [
  {
    key: 'Leed Certification',
    value: '',
  },
  {
    key: 'Certified',
    value: 'Certified',
  },
  {
    key: 'Silver',
    value: 'Silver',
  },
  {
    key: 'Gold',
    value: 'Gold',
  },
  {
    key: 'Platinum',
    value: 'Platinum',
  },
];
export const ALLOWED_BROWSERS_FOR_PARTICIPANT = ['Chrome', 'Safari', 'Firefox'];

export const ALLOWED_BROWSERS_FOR_HOST = ['Chrome', 'Firefox'];

export const LBNumFields = [
  'yearBuilt',
  'yearRenovated',
  'floorsCount',
  'buildingSize',
  'averageFloor',
  'columnSpacingHeight',
  'columnSpacingWidth',
  'monthlyRent',
  'size',
];

export const pdfExtensions = ['pdf'];
export const vidExtensions = ['mov', 'mp4', 'webm'];

export enum LEFT_SIDEBAR {
  BUILDINGS = 'buildings',
  SEARCH = 'search',
  DASHBOARD = 'dashboard',
  TOUR = 'tour',
}

export const DEFAULT_IMG_QUALITY = 75;
export const DEFAULT_MIN_IMG_HEIGHT = 300;
export const DEFAULT_MIN_IMG_WIDTH = 300;
export const DEFAULT_MAX_IMG_HEIGHT = 1125;
export const DEFAULT_MAX_IMG_WIDTH = 2000;

export enum TOUR_CONTROLLER_TYPE {
  LISTING = 'listing',
  NEIGHBORHOOD = 'neighborhood',
}
export enum PLAN_TYPES {
  SALE = 'SALE',
  RENTAL = 'RENTAL',
}

export enum PLAN_NAMES {
  ALPHA = 'Alpha',
  STANDARD = 'Standard',
}

export enum UPGRADE_PLAN_TYPES {
  SAVE_FOR_LATER = 'SAVE_FOR_LATER',
  REMOVE_BUILDING = 'REMOVE_BUILDING',
  SELECT_PLAN = 'SELECT_PLAN',
  STANDARD_PLAN = 'STANDARD_PLAN',
}

export const DEFAULT_STATIC_GRID = 2;
export const DEFAULT_STATIC_AMENITIES = 4;
export const DEFAULT_STATIC_PDFS = 4;
export const TOUR_PULSE_ACTIVITY = {
  veryRecent: {
    message: 'This tour was sent very recently. We are still giving client some time to get to it.',
    icon: 'icon-send',
  },
  notMuchActivity: {
    message:
      "We haven't noticed much activity on this tour. Check the logs and think on following up with the client",
    icon: 'icon-eye-slash-alt',
  },
  someActivity: {
    message:
      'This tour started to get some extra attention. Check out where people are spending their time.',
    icon: 'icon-pointer',
  },
  highActivity: {
    message:
      'You are doing something right! People seem to be spending a lot of time on this tour.',
    icon: 'icon-heart',
  },
};

export enum ANALYTICS_TYPE {
  TIME = 'time',
  CLICKS = 'clicks',
}

export enum USER_ANALYTICS_SORT_BY {
  VIDEO = 'videoClicksOrTime',
  TOURS = 'matterPortLinkClicksOrTime',
  LOCATION = 'locationClicksOrTime',
  GALLERY = 'mediaClicksOrTime',
  SPECS = 'specsClicksOrTime',
  FLOOR_PLANS = 'floorPlanClicksOrTime',
  PDFS = 'pdfClicksOrTime',
  DETAILS = 'detailsClicksOrTime',
}

export enum ANALYTICS_SORT_ORDER {
  ASCENDING = 'ascending',
  ASC = 'asc',
  DESCENDING = 'descending',
  DESC = 'desc',
}

export const DEFAULT_TOURS_PULSE_LIMIT = 10;
export const DEFAULT_LISTING_ANALYTICS_LIMIT = 10;

export enum LISTING_ANALYTICS_SORT_BY {
  VIDEO = 'totalVideo',
  TOURS = 'totalMatterPortLink',
  LOCATION = 'totalLocation',
  GALLERY = 'totalMedia',
  SPECS = 'totalSpecs',
  FLOOR_PLANS = 'totalFloorPlan',
  PDFS = 'totalPdf',
  DETAILS = 'totalDetails',
}

export const ANALYTICS_TABLE_HEADERS = {
  VIDEO: { icon: 'icon-play-outline', value: 'Video' },
  TOURS: { icon: 'icon-tour', value: '3D Tour' },
  LOCATION: { icon: 'icon-map-marker-light', value: 'Location' },
  GALLERY: { icon: 'icon-gallery', value: 'Gallery' },
  SPECS: { icon: 'icon-building', value: 'Specs' },
  FLOOR_PLANS: { icon: 'icon-floorplan', value: 'Floor Plan' },
  PDFS: { icon: 'icon-pdf-alt', value: 'PDFs' },
  DETAILS: { icon: 'icon-details', value: 'Details' },
};

export enum ANALYTICS_TABLE_COLUMNS_ENCODING {
  VIDEO = 'videoClicksOrTime',
  TOURS = 'matterPortLinkClicksOrTime',
  LOCATION = 'locationClicksOrTime',
  GALLERY = 'mediaClicksOrTime',
  SPECS = 'specsClicksOrTime',
  FLOOR_PLANS = 'floorPlanClicksOrTime',
  PDFS = 'pdfClicksOrTime',
  DETAILS = 'detailsClicksOrTime',
}

export enum BUILDING_TEMPLATES {
  BASIC = 'basic',
  CLASSIC = 'classic',
  MODERN = 'modern',
}

export enum BUILDING_TEMPLATE_STATUS {
  PUBLISHED = 'published',
  UNPUBLISHED = 'unpublished',
}

export const PAYMENT_STATUS = {
  SIGNUP: 'Before we continue, enter your email',
  NAV: {
    NAV_ITEM_1: 'How it works',
    NAV_ITEM_2: 'About',
    NAV_ITEM_3: 'Pricing',
    NAV_ITEM_4: 'Contact',
    NAV_ITEM_5: 'Login',
  },
  METHOD: {
    METHOD_ITEM_1: 'Credit Card',
    METHOD_ITEM_2: 'Change Plan',
    METHOD_ITEM_3: 'Confirm',
  },
  BILLING: {
    BILLING_ITEM_1: 'Buildings x ',
    BILLING_ITEM_2: '(1 Building = 100,000 SF Max)',
    BILLING_ITEM_3: '/ea',
    BILLING_ITEM_4: 'Website addon',
    BILLING_ITEM_5: '$345/bldg',
    BILLING_ITEM_6: 'Min spend',
    BILLING_ITEM_7: '$1,725/mo',
    BILLING_ITEM_8: 'Subtotal',
    BILLING_ITEM_9: '$5,975',
    BILLING_ITEM_10: 'Total (Includes Premium Discount)',
    BILLING_ITEM_11: '$5,700',
    BILLING_ITEM_12: '/month',
    BILLING_ITEM_13: '(1 Building = 50,000 SF Max)',
    BILLING_ITEM_14: '$550/ea',
    BILLING_ITEM_15: '$290/bldg',
    BILLING_ITEM_16: '$1,525/mo',
    BILLING_ITEM_17: '$',
    BILLING_ITEM_18: '$5,000',
  },
  BANNER: {
    BANNER_ITEM_1: 'Payment Done!',
    BANNER_ITEM_2: 'Finish setting up your account',
  },
  FORM: {
    FORM_ITEM_1: 'Almost there!',
    FORM_ITEM_2: 'Please go ahead and create a password to finish creating your account',
    FORM_ITEM_3: 'I agree to ',
    FORM_ITEM_4: 'HomeTour terms.',
    FORM_ITEM_5: 'Finish Account',
  },
};

export const BROKER_SEAT_PRICE = 19;
export const SMALL_BUILDING_PRICE = 695;
export const PERSONAL_BUILDING_PRICE = 199;
export const LARGE_BUILDING_PRICE = 895;
export const WEBSITE_BUILDING_PRICE = 199;
export const BANDWIDTH_QUALITY_TIMER = 60000 * 3;

export const BUILDING_DELETE = 'DELETE';
