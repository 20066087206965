import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectSubdomainBuilding,
  selectSubdomainCompanyInfo,
} from '../../../../../../state/subdomain/building/building.selector';
import ImgTile from '../../../../../media/imgTile';
import './index.css';
import Images from '../../../../../../utils/img.utils';
import Button from '../Button';
import TourRequestForm from '../../../../../subdomain/listing/listDetail/requestForm';
import TourContactForm from '../../../../../subdomain/listing/listDetail/contactForm';

const Header = () => {
  const [showContactForm, setShowContactForm] = useState(false);
  const contactInfoHandler = () => {
    setShowContactForm(true);
  };
  const companyInfo = useSelector(selectSubdomainCompanyInfo());
  const building = useSelector(selectSubdomainBuilding());

  return (
    <div className="header-container website-padding">
      <div className="header-subcontainer">
        <ImgTile className="header-logo" src={companyInfo?.settings.darkLogo?.url} />
        <div className="header-contact-container">
          {companyInfo?.settings?.officialPhone && (
            <div className="phone-number-container">
              <img className="mr-10" src={Images.PhoneIcon} />
              <span className="header-contact-number">{companyInfo?.settings.officialPhone}</span>
            </div>
          )}
          <Button onClick={contactInfoHandler}>Contact us</Button>
        </div>
      </div>
      {showContactForm && (
        <TourContactForm buildingId={building?._id} hideForm={() => setShowContactForm(false)} />
      )}
    </div>
  );
};

export default Header;
