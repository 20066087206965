import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { TourForm } from '../../../../../state/subdomain/listing/listing.form';
import { errorMsg } from '../../listing.component';
import { StyledFormControl } from '../../../../../common/styled';

const PlaceHolder = {
  [TourForm.firstName]: 'First Name*',
  [TourForm.lastName]: 'Last Name*',
  [TourForm.email]: 'Email*',
  [TourForm.phone]: 'Phone Number*',
  // [TourForm.companyName]: 'Client’s Company Name*',
  [TourForm.questions]: 'Do you have any questions?',
  // [TourForm.brokerCompany]: 'Brokerage Company Name',
  [TourForm.time]: 'time',
};
interface Props {
  reg: any;
  name: TourForm;
  full?: boolean;
  options?: boolean;
  err?: any;
}
const FormInput: React.FC<Props> = props => {
  const { name, reg, full, options, err } = props;

  return (
    <React.Fragment>
      {options ? (
        <div className="col col-12">
          <Form.Group className="forms--group forms--group-radius select--group w-100 mb-4">
            <StyledFormControl
              as="select"
              ref={reg}
              name={TourForm.time}
              defaultValue=""
              className={`form-control ${err && 'error'}`}
            >
              <option value="" disabled>
                Time of Day Preference*
              </option>
              <option>Morning</option>
              <option>Noon</option>
              <option>Afternoon</option>
              <option>Evening</option>
            </StyledFormControl>
            <span className="select__caret-down">
              <i className="icon-caret-down"></i>
            </span>
            {errorMsg(err)}
          </Form.Group>
        </div>
      ) : (
        <div className={`col col-12 ${!full && 'col-sm-6'}`}>
          <Form.Group className="forms--group forms--group-radius mb-4">
            <StyledFormControl
              as={full ? 'textarea' : 'input'}
              placeholder={PlaceHolder[name]}
              name={name}
              ref={reg}
              className={`form-control ${err && 'error'}`}
            />
            {errorMsg(err)}
          </Form.Group>
        </div>
      )}
    </React.Fragment>
  );
};
export default FormInput;
