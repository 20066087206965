import * as yup from 'yup';

const REQUIRED_FIELD_ERROR = 'This field is required';
const CURRENT_YEAR = new Date().getFullYear();
export enum additionalInformationEnum {
  appliances = 'appliances',
  basement = 'basement',
  floorCoverning = 'floorCovering',
  indoorFeatures = 'indoorFeatures',
  rooms = 'rooms',
  buildingAmenities = 'buildingAmenities',
  architecturalStyle = 'architecturalStyle',
  exterior = 'exterior',
  parking = 'parking',
  outdoorAmenities = 'outdoorAmenities',
  roof = 'roof',
  view = 'view',
  coolingType = 'coolingType',
  heatingType = 'heatingType',
  heatingFuel = 'heatingFuel',
}
export enum homeFacts {
  homeType = 'homeType',
  dimensions = 'dimensions',
  fullBaths = 'fullBaths',
  totalRooms = 'totalRooms',
  threeByFourBaths = 'threeByFourBaths',
  oneByTwoBaths = 'oneByTwoBaths',
  oneByFourBaths = 'oneByFourBaths',
  squareFeet = 'squareFeet',
  unit = 'unit',
  yearBuild = 'yearBuild',
  remodelYear = 'remodelYear',
  hoaDues = 'hoaDues',
}
export enum OpenHouse {
  date = 'date',
  startTime = 'startTime',
  endTime = 'endTime',
}

export enum ListingForm {
  LOCATION = 'location',
  LONGITUDE = 'longitude',
  ADDRESS = 'address',
  LATITUDE = 'latitude',
  FULL_LOCATION = 'fullLocation',
  SUB_MARKET_NAME = 'subMarketName',
  SUB_MARKET_ID = 'subMarketId',
  SIZE = 'size',
  PRICE = 'price',
  listingType = 'listingType',
  listingDescription = 'listingDescription',
  propertyType = 'propertyType',
  hideAddress = 'hideAddress',
  homeFacts = 'homeFacts',
  media = 'media',
  matterPortLink = 'matterPortLink',
  videoDescription = 'videoDescription',
  floorPlans = 'floorPlans',
  pdfs = 'pdfs',
  users = 'users',
  openHouse = 'openHouse',
}

export const InteriorSchema = yup.object().shape({
  [additionalInformationEnum.floorCoverning]: yup
    .array()
    .of(yup.string().required(REQUIRED_FIELD_ERROR)),
  [additionalInformationEnum.rooms]: yup.array().of(yup.string().required(REQUIRED_FIELD_ERROR)),
  [additionalInformationEnum.basement]: yup.string().required(REQUIRED_FIELD_ERROR),
  [additionalInformationEnum.indoorFeatures]: yup
    .array()
    .of(yup.string().required(REQUIRED_FIELD_ERROR)),
  [additionalInformationEnum.coolingType]: yup.string().required(REQUIRED_FIELD_ERROR),
  [additionalInformationEnum.heatingFuel]: yup.string().required(REQUIRED_FIELD_ERROR),
  [additionalInformationEnum.heatingType]: yup.string().required(REQUIRED_FIELD_ERROR),
});

export const ExteriorSchema = yup.object().shape({
  [additionalInformationEnum.architecturalStyle]: yup.string().required(REQUIRED_FIELD_ERROR),
  [additionalInformationEnum.exterior]: yup.array().of(yup.string().required(REQUIRED_FIELD_ERROR)),
  [additionalInformationEnum.view]: yup.string().required(REQUIRED_FIELD_ERROR),
  [additionalInformationEnum.roof]: yup.string().required(REQUIRED_FIELD_ERROR),
  [additionalInformationEnum.parking]: yup.string().required(REQUIRED_FIELD_ERROR),
});

export const AmentiesSchema = yup.object().shape({
  [additionalInformationEnum.appliances]: yup
    .array()
    .of(yup.string().required(REQUIRED_FIELD_ERROR)),

  [additionalInformationEnum.buildingAmenities]: yup
    .array()
    .of(yup.string().required(REQUIRED_FIELD_ERROR)),

  [additionalInformationEnum.outdoorAmenities]: yup
    .array()
    .of(yup.string().required(REQUIRED_FIELD_ERROR)),
});
export const FirstStepListingSchema = yup.object().shape({
  [ListingForm.PRICE]: yup
    .number()
    .required(REQUIRED_FIELD_ERROR)
    .typeError('should be a number'),
  [ListingForm.LOCATION]: yup
    .string()
    .trim()
    .required(REQUIRED_FIELD_ERROR),
  [ListingForm.listingType]: yup
    .string()
    .trim()
    .required(REQUIRED_FIELD_ERROR),
});
export const ListingFormSchema = yup.object().shape({
  [ListingForm.listingDescription]: yup
    .string()
    .trim()
    .required(REQUIRED_FIELD_ERROR),

  [ListingForm.SIZE]: yup
    .number()
    .required(REQUIRED_FIELD_ERROR)
    .typeError('should be a number')
    .typeError('should be a number')
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    }),

  [ListingForm.SUB_MARKET_NAME]: yup
    .string()
    .trim()
    .required(REQUIRED_FIELD_ERROR),
  [ListingForm.propertyType]: yup
    .string()
    .trim()
    .required(REQUIRED_FIELD_ERROR),
  [ListingForm.hideAddress]: yup.bool().required(REQUIRED_FIELD_ERROR),
  [ListingForm.homeFacts]: yup.object().shape({
    [homeFacts.homeType]: yup.string().required(REQUIRED_FIELD_ERROR),
    [homeFacts.dimensions]: yup.number().typeError('should be a number'),
    [homeFacts.fullBaths]: yup.number().typeError('should be a number'),
    [homeFacts.hoaDues]: yup.number().typeError('should be a number'),
    [homeFacts.oneByFourBaths]: yup.number().typeError('should be a number'),
    [homeFacts.oneByTwoBaths]: yup.number().typeError('should be a number'),
    [homeFacts.remodelYear]: yup
      .number()
      .moreThan(yup.ref(homeFacts.yearBuild), 'should be greater than built year')
      .max(CURRENT_YEAR, 'should be less than current year')
      .typeError('should be between 1800 to current year')
      .transform((cv, ov) => {
        return ov === '' ? undefined : cv;
      }),
    [homeFacts.squareFeet]: yup.number().typeError('should be a number'),
    [homeFacts.threeByFourBaths]: yup.number().typeError('should be a number'),
    [homeFacts.totalRooms]: yup.number().typeError('should be a number'),
    [homeFacts.unit]: yup.string(),
    [homeFacts.yearBuild]: yup
      .number()
      .max(CURRENT_YEAR, 'should be less than current year')
      .typeError('should be between 1800 to current year'),
  }),
  [ListingForm.openHouse]: yup.object().shape({
    [OpenHouse.startTime]: yup.string(),
    [OpenHouse.endTime]: yup.string(),
    [OpenHouse.date]: yup.string(),
  }),

  [ListingForm.matterPortLink]: yup.string().url('Please enter a valid matterport url'),
  [ListingForm.videoDescription]: yup.string().trim(),
  [ListingForm.users]: yup.array().of(yup.string().trim()),
  [ListingForm.media]: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required(REQUIRED_FIELD_ERROR),
        url: yup
          .string()
          .trim()
          .required(REQUIRED_FIELD_ERROR),
        isPrimary: yup.boolean().required(REQUIRED_FIELD_ERROR),
      }),
    )
    .typeError('At least one photo is required')
    .required('At least one photo is required'),
  [ListingForm.pdfs]: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .trim()
        .required(REQUIRED_FIELD_ERROR),
      url: yup
        .string()
        .trim()
        .required(REQUIRED_FIELD_ERROR),
    }),
  ),
  [ListingForm.floorPlans]: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .trim()
        .required(REQUIRED_FIELD_ERROR),
      url: yup
        .string()
        .trim()
        .required(REQUIRED_FIELD_ERROR),
    }),
  ),
});

export const UpdateListingSchema = yup.object().shape({
  [ListingForm.listingDescription]: yup
    .string()
    .trim()
    .required(REQUIRED_FIELD_ERROR),
  [ListingForm.SIZE]: yup
    .number()
    .required(REQUIRED_FIELD_ERROR)
    .typeError('should be a number'),
  [ListingForm.PRICE]: yup.number().required(REQUIRED_FIELD_ERROR),
  [ListingForm.LOCATION]: yup
    .string()
    .trim()
    .required(REQUIRED_FIELD_ERROR),
  [ListingForm.SUB_MARKET_NAME]: yup
    .string()
    .trim()
    .required(REQUIRED_FIELD_ERROR),
  [ListingForm.propertyType]: yup
    .string()
    .trim()
    .required(REQUIRED_FIELD_ERROR),
  [ListingForm.hideAddress]: yup.bool().required(REQUIRED_FIELD_ERROR),
  [ListingForm.homeFacts]: yup.object().shape({
    [homeFacts.homeType]: yup.string().required(REQUIRED_FIELD_ERROR),
    [homeFacts.dimensions]: yup.number().typeError('should be a number'),
    [homeFacts.fullBaths]: yup.number().typeError('should be a number'),
    [homeFacts.hoaDues]: yup.number().typeError('should be a number'),
    [homeFacts.oneByFourBaths]: yup.number().typeError('should be a number'),
    [homeFacts.oneByTwoBaths]: yup.number().typeError('should be a number'),
    [homeFacts.remodelYear]: yup
      .number()
      .moreThan(yup.ref(homeFacts.yearBuild), 'should be greater than built year')
      .max(CURRENT_YEAR, 'should be less than current year')
      .transform((cv, ov) => {
        return ov === '' ? undefined : cv;
      })
      .typeError('should be between 1800 to current year'),
    [homeFacts.squareFeet]: yup.number().typeError('should be a number'),
    [homeFacts.threeByFourBaths]: yup.number().typeError('should be a number'),
    [homeFacts.totalRooms]: yup.number().typeError('should be a number'),
    [homeFacts.unit]: yup.string(),
    [homeFacts.yearBuild]: yup
      .number()
      .max(CURRENT_YEAR, 'should be less than current year')
      .min(1800, 'should be greater than 1800')
      .typeError('should be between 1800 to current year')
      .transform((cv, ov) => {
        return ov === '' ? undefined : cv;
      }),
  }),
  [ListingForm.openHouse]: yup.object().shape({
    [OpenHouse.startTime]: yup.string(),
    [OpenHouse.endTime]: yup.string(),
    [OpenHouse.date]: yup.string(),
  }),
});
