import React from 'react';
import { UploadedMediaInterface } from '../../../../../state/media/media.types';
import { AttachmentLightBox } from '../../../../lightbox';

interface Props {
  show: boolean;
  toggle: () => void;
  images: UploadedMediaInterface[];
}

const ImagesLightbox: React.FC<Props> = ({ show, toggle, images }) => {
  if (!show) return null;
  return <AttachmentLightBox data={images} index={0} close={toggle} />;
};

export default ImagesLightbox;
