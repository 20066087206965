import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { DEFAULT_PRIMARY_COLOR, DEFAULT_SECONDARY_COLOR } from '../../../common/constants';

const SVG500: React.FC = () => {
  const theme = useContext(ThemeContext);

  const [primary, setPrimary] = useState(DEFAULT_PRIMARY_COLOR);
  const [secondary, setSecondary] = useState(DEFAULT_SECONDARY_COLOR);
  useEffect(() => {
    if (theme?.primary) setPrimary(theme.primary);
    if (theme?.secondary) setSecondary(theme.secondary);
  }, [theme]);

  return (
    <svg
      width="372"
      height="278"
      viewBox="0 0 372 278"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M158 218L214.197 250.305L203.441 256.757"
        stroke={secondary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M24.1067 142.365L51.9872 125.639L60.2664 130.505"
        stroke={secondary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M25.8454 229.33L3 242.374L28.2007 256.366"
        stroke={secondary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M371.211 144.326L318.512 174.568"
        stroke={secondary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M371.212 144.326L341.914 127.484"
        stroke={secondary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M80.6656 227.343L49.1067 208.995V136.091M80.6656 227.343L112.103 209.362C116.873 206.549 122.744 209.974 122.744 215.478C122.744 222.94 118.83 229.79 112.347 233.459L80.6656 251.685L80.5433 275.538L119.197 253.642C134.365 245.08 143.661 229.056 143.661 211.686V207.894C143.661 189.546 123.845 178.048 107.944 187.222L101.338 191.014L101.461 166.549L133.264 148.324V123.982L101.705 105.756L49.1067 136.091M80.6656 227.343V154.44L49.1067 136.091"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M80.7879 251.563L49.1067 233.337V258.413L80.6656 275.416"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M49.1067 233.337L70.146 221.227"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M80.6656 154.317L133.386 123.982"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M133.386 188.078L112.347 175.968C109.044 174.133 105.375 173.155 101.705 173.155"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M209.261 144.624V149.728"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M203.287 143.537V145.167C203.287 145.818 203.613 146.47 204.265 146.796L204.482 146.904C205.351 147.447 206.546 146.796 206.546 145.71V144.08C206.546 143.429 206.22 142.777 205.568 142.451L205.351 142.343C204.482 141.8 203.287 142.451 203.287 143.537Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M198.291 140.713V142.343C198.291 142.994 198.617 143.646 199.269 143.972L199.486 144.08C200.355 144.624 201.55 143.972 201.55 142.886V141.256C201.55 140.605 201.224 139.953 200.572 139.627L200.355 139.519C199.377 138.976 198.291 139.627 198.291 140.713Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M193.295 137.781V139.41C193.295 140.062 193.621 140.713 194.272 141.039L194.49 141.148C195.359 141.691 196.553 141.039 196.553 139.953V138.324C196.553 137.672 196.228 137.02 195.576 136.695L195.359 136.586C194.381 136.043 193.295 136.695 193.295 137.781Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M217.842 149.511V154.725"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M211.868 148.534V150.163C211.868 150.815 212.194 151.466 212.846 151.792L213.063 151.901C213.932 152.444 215.126 151.792 215.126 150.706V149.077C215.126 148.425 214.801 147.773 214.149 147.448L213.932 147.339C212.954 146.796 211.868 147.448 211.868 148.534Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M226.314 154.507V159.612"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M220.34 153.421V155.051C220.34 155.702 220.666 156.354 221.318 156.68L221.535 156.788C222.404 157.331 223.598 156.68 223.598 155.594V153.964C223.598 153.313 223.273 152.661 222.621 152.335L222.404 152.227C221.535 151.683 220.34 152.335 220.34 153.421Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M173.31 123.878V128.983"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M167.336 122.792V124.421C167.336 125.073 167.662 125.725 168.313 126.05L168.531 126.159C169.4 126.702 170.594 126.05 170.594 124.964V123.335C170.594 122.683 170.269 122.032 169.617 121.706L169.4 121.597C168.531 121.054 167.336 121.706 167.336 122.792Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M181.89 128.874V133.979"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M175.917 127.788V129.417C175.917 130.069 176.242 130.721 176.894 131.047L177.111 131.155C177.98 131.698 179.175 131.047 179.175 129.96V128.331C179.175 127.68 178.849 127.028 178.197 126.702L177.98 126.593C177.111 126.05 175.917 126.702 175.917 127.788Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M190.471 133.762V138.867"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M184.497 132.676V134.305C184.497 134.957 184.823 135.608 185.475 135.934L185.692 136.043C186.561 136.586 187.756 135.934 187.756 134.848V133.219C187.756 132.567 187.43 131.916 186.778 131.59L186.561 131.481C185.583 130.938 184.497 131.59 184.497 132.676Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M212.846 137.672V142.777"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M206.763 136.586V138.215C206.763 138.867 207.089 139.519 207.741 139.844L207.958 139.953C208.827 140.496 210.022 139.844 210.022 138.758V137.129C210.022 136.477 209.696 135.826 209.044 135.5L208.827 135.391C207.958 134.848 206.763 135.5 206.763 136.586Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M201.767 133.653V135.283C201.767 135.934 202.093 136.586 202.744 136.912L202.962 137.02C203.831 137.564 205.025 136.912 205.025 135.826V134.196C205.025 133.545 204.699 132.893 204.048 132.567L203.831 132.459C202.962 131.916 201.767 132.567 201.767 133.653Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.771 130.829V132.459C196.771 133.11 197.096 133.762 197.748 134.088L197.965 134.196C198.834 134.74 200.029 134.088 200.029 133.002V131.372C200.029 130.721 199.703 130.069 199.051 129.743L198.834 129.635C197.965 129.092 196.771 129.743 196.771 130.829Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M221.318 142.56V147.665"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M215.344 141.474V143.103C215.344 143.755 215.67 144.406 216.321 144.732L216.538 144.841C217.407 145.384 218.602 144.732 218.602 143.646V142.017C218.602 141.365 218.276 140.713 217.625 140.388L217.407 140.279C216.538 139.736 215.344 140.388 215.344 141.474Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M229.898 147.556V152.661"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M223.924 146.47V148.099C223.924 148.751 224.25 149.403 224.902 149.728L225.119 149.837C225.988 150.38 227.183 149.728 227.183 148.642V147.013C227.183 146.361 226.857 145.71 226.205 145.384L225.988 145.275C225.01 144.732 223.924 145.384 223.924 146.47Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M176.894 116.927V122.032"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M170.92 115.841V117.47C170.92 118.121 171.246 118.773 171.898 119.099L172.115 119.208C172.984 119.751 174.179 119.099 174.179 118.013V116.384C174.179 115.732 173.853 115.08 173.201 114.754L172.984 114.646C172.006 114.103 170.92 114.754 170.92 115.841Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M165.924 112.908V114.537C165.924 115.189 166.25 115.841 166.901 116.166L167.119 116.275C167.988 116.818 169.182 116.166 169.182 115.08V113.451C169.182 112.799 168.857 112.148 168.205 111.822L167.988 111.713C167.01 111.17 165.924 111.93 165.924 112.908Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M185.475 121.814V126.919"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179.392 120.728V122.357C179.392 123.009 179.718 123.661 180.37 123.987L180.587 124.095C181.456 124.638 182.651 123.987 182.651 122.901V121.271C182.651 120.62 182.325 119.968 181.673 119.642L181.456 119.533C180.587 118.99 179.392 119.751 179.392 120.728Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M193.947 126.811V131.916"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.973 125.725V127.354C187.973 128.005 188.299 128.657 188.95 128.983L189.168 129.092C190.036 129.635 191.231 128.983 191.231 127.897V126.268C191.231 125.616 190.905 124.964 190.254 124.638L190.036 124.53C189.168 123.987 187.973 124.638 187.973 125.725Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M209.261 115.732V120.837"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M203.287 114.646V116.275C203.287 116.927 203.613 117.578 204.265 117.904L204.482 118.013C205.351 118.556 206.546 117.904 206.546 116.818V115.189C206.546 114.537 206.22 113.886 205.568 113.56L205.351 113.451C204.482 112.908 203.287 113.56 203.287 114.646Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M198.291 111.713V113.342C198.291 113.994 198.617 114.646 199.269 114.972L199.486 115.08C200.355 115.623 201.55 114.972 201.55 113.885V112.256C201.55 111.605 201.224 110.953 200.572 110.627L200.355 110.518C199.377 110.084 198.291 110.736 198.291 111.713Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M193.295 108.889V110.518C193.295 111.17 193.621 111.822 194.272 112.148L194.49 112.256C195.359 112.799 196.553 112.148 196.553 111.062V109.432C196.553 108.781 196.228 108.129 195.576 107.803L195.359 107.694C194.381 107.151 193.295 107.803 193.295 108.889Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M217.842 120.62V125.833"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M211.868 119.533V121.163C211.868 121.814 212.194 122.466 212.846 122.792L213.063 122.901C213.932 123.444 215.126 122.792 215.126 121.706V120.077C215.126 119.425 214.801 118.773 214.149 118.447L213.932 118.339C212.954 117.904 211.868 118.556 211.868 119.533Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M226.314 125.616V130.721"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M220.34 124.53V126.159C220.34 126.811 220.666 127.462 221.318 127.788L221.535 127.897C222.404 128.44 223.598 127.788 223.598 126.702V125.073C223.598 124.421 223.273 123.769 222.621 123.444L222.404 123.335C221.535 122.792 220.34 123.444 220.34 124.53Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M173.31 94.9865V100.091"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M167.336 93.9004V95.5296C167.336 96.1813 167.662 96.833 168.313 97.1588L168.531 97.2674C169.4 97.8105 170.594 97.1588 170.594 96.0727V94.4434C170.594 93.7917 170.269 93.1401 169.617 92.8142L169.4 92.7056C168.531 92.1625 167.336 92.8142 167.336 93.9004Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M181.89 99.8742V105.088"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M175.917 98.7881V100.417C175.917 101.069 176.242 101.721 176.894 102.047L177.111 102.155C177.98 102.698 179.175 102.047 179.175 100.96V99.3311C179.175 98.6794 178.849 98.0278 178.197 97.7019L177.98 97.5933C177.111 97.1588 175.917 97.8105 175.917 98.7881Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M190.471 104.87V109.975"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M184.497 103.784V105.414C184.497 106.065 184.823 106.717 185.475 107.043L185.692 107.151C186.561 107.694 187.756 107.043 187.756 105.957V104.327C187.756 103.676 187.43 103.024 186.778 102.698L186.561 102.59C185.583 102.047 184.497 102.698 184.497 103.784Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M212.846 127.68V132.785"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M206.763 126.593V128.223C206.763 128.874 207.089 129.526 207.741 129.852L207.958 129.961C208.827 130.504 210.022 129.852 210.022 128.766V127.137C210.022 126.485 209.696 125.833 209.044 125.507L208.827 125.399C207.958 124.856 206.763 125.507 206.763 126.593Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M201.767 123.661V125.29C201.767 125.942 202.093 126.593 202.744 126.919L202.962 127.028C203.831 127.571 205.025 126.919 205.025 125.833V124.204C205.025 123.552 204.699 122.901 204.048 122.575L203.831 122.466C202.962 122.032 201.767 122.683 201.767 123.661Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.771 120.837V122.466C196.771 123.118 197.096 123.769 197.748 124.095L197.965 124.204C198.834 124.747 200.029 124.095 200.029 123.009V121.38C200.029 120.728 199.703 120.077 199.051 119.751L198.834 119.642C197.965 119.099 196.771 119.751 196.771 120.837Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M221.318 132.567V137.781"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M215.344 131.481V133.11C215.344 133.762 215.67 134.414 216.321 134.74L216.538 134.848C217.407 135.391 218.602 134.74 218.602 133.653V132.024C218.602 131.373 218.276 130.721 217.625 130.395L217.407 130.286C216.538 129.743 215.344 130.504 215.344 131.481Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M229.898 137.564V142.668"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M223.924 136.477V138.107C223.924 138.758 224.25 139.41 224.902 139.736L225.119 139.844C225.988 140.388 227.183 139.736 227.183 138.65V137.02C227.183 136.369 226.857 135.717 226.205 135.391L225.988 135.283C225.01 134.74 223.924 135.391 223.924 136.477Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M176.894 106.934V112.039"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M170.92 105.848V107.477C170.92 108.129 171.246 108.781 171.898 109.106L172.115 109.215C172.984 109.758 174.179 109.106 174.179 108.02V106.391C174.179 105.739 173.853 105.088 173.201 104.762L172.984 104.653C172.006 104.11 170.92 104.762 170.92 105.848Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M165.924 102.915V104.545C165.924 105.196 166.25 105.848 166.901 106.174L167.119 106.282C167.988 106.826 169.182 106.174 169.182 105.088V103.458C169.182 102.807 168.857 102.155 168.205 101.829L167.988 101.721C167.01 101.286 165.924 101.938 165.924 102.915Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M185.475 111.822V117.035"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179.392 110.736V112.365C179.392 113.017 179.718 113.668 180.37 113.994L180.587 114.103C181.456 114.646 182.651 113.994 182.651 112.908V111.279C182.651 110.627 182.325 109.975 181.673 109.65L181.456 109.541C180.587 109.106 179.392 109.758 179.392 110.736Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M193.947 116.818V121.923"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.973 115.732V117.361C187.973 118.013 188.299 118.665 188.95 118.99L189.168 119.099C190.036 119.642 191.231 118.99 191.231 117.904V116.275C191.231 115.623 190.905 114.972 190.254 114.646L190.036 114.537C189.168 113.994 187.973 114.646 187.973 115.732Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M212.846 108.672V113.886"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M206.763 107.694V109.324C206.763 109.975 207.089 110.627 207.741 110.953L207.958 111.062C208.827 111.605 210.022 110.953 210.022 109.867V108.238C210.022 107.586 209.696 106.934 209.044 106.608L208.827 106.5C207.958 105.957 206.763 106.608 206.763 107.694Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M201.767 104.762V106.391C201.767 107.043 202.093 107.694 202.744 108.02L202.962 108.129C203.831 108.672 205.025 108.02 205.025 106.934V105.305C205.025 104.653 204.699 104.002 204.048 103.676L203.831 103.567C202.962 103.024 201.767 103.676 201.767 104.762Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.771 101.829V103.458C196.771 104.11 197.096 104.762 197.748 105.088L197.965 105.196C198.834 105.739 200.029 105.088 200.029 104.002V102.372C200.029 101.721 199.703 101.069 199.051 100.743L198.834 100.635C197.965 100.2 196.771 100.852 196.771 101.829Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M221.318 113.668V118.773"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M215.344 112.582V114.211C215.344 114.863 215.67 115.515 216.321 115.841L216.538 115.949C217.407 116.492 218.602 115.841 218.602 114.754V113.125C218.602 112.474 218.276 111.822 217.625 111.496L217.407 111.387C216.538 110.844 215.344 111.496 215.344 112.582Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M223.924 117.47V119.099C223.924 119.751 224.25 120.402 224.902 120.728L225.119 120.837C225.988 121.38 227.183 120.728 227.183 119.642V118.013C227.183 117.361 226.857 116.71 226.205 116.384L225.988 116.275C225.01 115.841 223.924 116.492 223.924 117.47Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M176.894 88.0352V93.1401"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M170.92 86.949V88.5783C170.92 89.23 171.246 89.8816 171.898 90.2075L172.115 90.3161C172.984 90.8592 174.179 90.2075 174.179 89.1213V87.4921C174.179 86.8404 173.853 86.1887 173.201 85.8629L172.984 85.7543C172.006 85.2112 170.92 85.8629 170.92 86.949Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M165.924 84.0164V85.6457C165.924 86.2973 166.25 86.949 166.901 87.2749L167.119 87.3835C167.988 87.9266 169.182 87.2749 169.182 86.1887V84.5595C169.182 83.9078 168.857 83.2561 168.205 82.9303L167.988 82.8217C167.01 82.2786 165.924 82.9303 165.924 84.0164Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M185.475 92.9229V98.0278"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179.392 91.8367V93.4659C179.392 94.1176 179.718 94.7693 180.37 95.0952L180.587 95.2038C181.456 95.7468 182.651 95.0952 182.651 94.009V92.3798C182.651 91.7281 182.325 91.0764 181.673 90.7506L181.456 90.6419C180.587 90.0989 179.392 90.7506 179.392 91.8367Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M193.947 97.8105V103.024"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.973 96.7244V98.3536C187.973 99.0053 188.299 99.657 188.95 99.9828L189.168 100.091C190.036 100.635 191.231 99.9828 191.231 98.8967V97.2674C191.231 96.6157 190.905 95.964 190.254 95.6382L190.036 95.5296C189.168 95.0951 187.973 95.7468 187.973 96.7244Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M168.003 74.8083L237.36 114.807V162.88"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M242.13 176.58L185.985 208.995"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M229.776 175.601L196.382 194.806"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M156.138 135.357L133.264 148.446"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M157.851 127.896L133.264 141.963"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M157.851 127.896L242.13 176.58V111.749L157.851 63.0655V127.896Z"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M185.985 208.995L106.965 163.369"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M229.776 175.601L173.508 143.186"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M164.945 203.001L185.985 215.111C194.201 210.382 198.807 207.73 207.024 203.001"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M229.898 118.556V123.769"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M141.704 176.947L112.592 160.189"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M298.296 83.5585V88.6625"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M292.322 82.4715V84.1015C292.322 84.7525 292.648 85.4045 293.3 85.7305L293.517 85.8385C294.386 86.3815 295.581 85.7305 295.581 84.6445V83.0145C295.581 82.3635 295.255 81.7115 294.603 81.3855L294.386 81.2775C293.517 80.7345 292.322 81.3855 292.322 82.4715Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M287.326 79.6475V81.2775C287.326 81.9285 287.652 82.5805 288.304 82.9065L288.521 83.0145C289.39 83.5585 290.585 82.9065 290.585 81.8205V80.1905C290.585 79.5395 290.259 78.8875 289.607 78.5615L289.39 78.4535C288.412 77.9105 287.326 78.5615 287.326 79.6475Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M282.33 76.7155V78.3445C282.33 78.9965 282.656 79.6475 283.307 79.9735L283.525 80.0825C284.394 80.6255 285.588 79.9735 285.588 78.8875V77.2585C285.588 76.6065 285.263 75.9545 284.611 75.6295L284.394 75.5205C283.416 74.9775 282.33 75.6295 282.33 76.7155Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M306.877 88.4455V93.6595"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M300.903 87.4685V89.0975C300.903 89.7495 301.229 90.4005 301.881 90.7265L302.098 90.8355C302.967 91.3785 304.161 90.7265 304.161 89.6405V88.0115C304.161 87.3595 303.836 86.7075 303.184 86.3825L302.967 86.2735C301.989 85.7305 300.903 86.3825 300.903 87.4685Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M315.349 93.4415V98.5465"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M309.375 92.3555V93.9855C309.375 94.6365 309.701 95.2885 310.353 95.6145L310.57 95.7225C311.439 96.2655 312.633 95.6145 312.633 94.5285V92.8985C312.633 92.2475 312.308 91.5955 311.656 91.2695L311.439 91.1615C310.57 90.6175 309.375 91.2695 309.375 92.3555Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M262.345 62.8125V67.9175"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M256.371 61.7265V63.3555C256.371 64.0075 256.697 64.6595 257.348 64.9845L257.566 65.0935C258.435 65.6365 259.629 64.9845 259.629 63.8985V62.2695C259.629 61.6175 259.304 60.9665 258.652 60.6405L258.435 60.5315C257.566 59.9885 256.371 60.6405 256.371 61.7265Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M270.925 67.8085V72.9135"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M264.952 66.7225V68.3515C264.952 69.0035 265.277 69.6555 265.929 69.9815L266.146 70.0895C267.015 70.6325 268.21 69.9815 268.21 68.8945V67.2655C268.21 66.6145 267.884 65.9625 267.232 65.6365L267.015 65.5275C266.146 64.9845 264.952 65.6365 264.952 66.7225Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M279.506 72.6965V77.8015"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M273.532 71.6105V73.2395C273.532 73.8915 273.858 74.5425 274.51 74.8685L274.727 74.9775C275.596 75.5205 276.791 74.8685 276.791 73.7825V72.1535C276.791 71.5015 276.465 70.8505 275.813 70.5245L275.596 70.4155C274.618 69.8725 273.532 70.5245 273.532 71.6105Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M301.881 76.6065V81.7115"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M295.798 75.5205V77.1495C295.798 77.8015 296.124 78.4535 296.776 78.7785L296.993 78.8875C297.862 79.4305 299.057 78.7785 299.057 77.6925V76.0635C299.057 75.4115 298.731 74.7605 298.079 74.4345L297.862 74.3255C296.993 73.7825 295.798 74.4345 295.798 75.5205Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M290.802 72.5875V74.2175C290.802 74.8685 291.128 75.5205 291.779 75.8465L291.997 75.9545C292.866 76.4985 294.06 75.8465 294.06 74.7605V73.1305C294.06 72.4795 293.734 71.8275 293.083 71.5015L292.866 71.3935C291.997 70.8505 290.802 71.5015 290.802 72.5875Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M285.806 69.7635V71.3935C285.806 72.0445 286.131 72.6965 286.783 73.0225L287 73.1305C287.869 73.6745 289.064 73.0225 289.064 71.9365V70.3065C289.064 69.6555 288.738 69.0035 288.086 68.6775L287.869 68.5695C287 68.0265 285.806 68.6775 285.806 69.7635Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M310.353 81.4945V86.5995"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M304.379 80.4085V82.0375C304.379 82.6895 304.705 83.3405 305.356 83.6665L305.573 83.7755C306.442 84.3185 307.637 83.6665 307.637 82.5805V80.9515C307.637 80.2995 307.311 79.6475 306.66 79.3225L306.442 79.2135C305.573 78.6705 304.379 79.3225 304.379 80.4085Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M318.933 86.4905V91.5955"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M312.959 85.4045V87.0335C312.959 87.6855 313.285 88.3375 313.937 88.6625L314.154 88.7715C315.023 89.3145 316.218 88.6625 316.218 87.5765V85.9475C316.218 85.2955 315.892 84.6445 315.24 84.3185L315.023 84.2095C314.045 83.6665 312.959 84.3185 312.959 85.4045Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M265.929 55.8615V60.9665"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M259.955 54.7755V56.4045C259.955 57.0555 260.281 57.7075 260.933 58.0335L261.15 58.1425C262.019 58.6855 263.214 58.0335 263.214 56.9475V55.3185C263.214 54.6665 262.888 54.0145 262.236 53.6885L262.019 53.5805C261.041 53.0375 259.955 53.6885 259.955 54.7755Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M254.959 51.8425V53.4715C254.959 54.1235 255.285 54.7755 255.936 55.1005L256.154 55.2095C257.023 55.7525 258.217 55.1005 258.217 54.0145V52.3855C258.217 51.7335 257.892 51.0825 257.24 50.7565L257.023 50.6475C256.045 50.1045 254.959 50.8645 254.959 51.8425Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M274.51 60.7485V65.8535"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M268.427 59.6625V61.2915C268.427 61.9435 268.753 62.5955 269.405 62.9215L269.622 63.0295C270.491 63.5725 271.686 62.9215 271.686 61.8355V60.2055C271.686 59.5545 271.36 58.9025 270.708 58.5765L270.491 58.4675C269.622 57.9245 268.427 58.6855 268.427 59.6625Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M282.982 65.7455V70.8505"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M277.008 64.6595V66.2885C277.008 66.9395 277.334 67.5915 277.985 67.9175L278.203 68.0265C279.071 68.5695 280.266 67.9175 280.266 66.8315V65.2025C280.266 64.5505 279.94 63.8985 279.289 63.5725L279.071 63.4645C278.203 62.9215 277.008 63.5725 277.008 64.6595Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M298.296 54.6665V59.7715"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M292.322 53.5805V55.2095C292.322 55.8615 292.648 56.5125 293.3 56.8385L293.517 56.9475C294.386 57.4905 295.581 56.8385 295.581 55.7525V54.1235C295.581 53.4715 295.255 52.8205 294.603 52.4945L294.386 52.3855C293.517 51.8425 292.322 52.4945 292.322 53.5805Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M287.326 50.6475V52.2765C287.326 52.9285 287.652 53.5805 288.304 53.9065L288.521 54.0145C289.39 54.5575 290.585 53.9065 290.585 52.8195V51.1905C290.585 50.5395 290.259 49.8875 289.607 49.5615L289.39 49.4525C288.412 49.0185 287.326 49.6705 287.326 50.6475Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M282.33 47.8235V49.4525C282.33 50.1045 282.656 50.7565 283.307 51.0825L283.525 51.1905C284.394 51.7335 285.588 51.0825 285.588 49.9965V48.3665C285.588 47.7155 285.263 47.0635 284.611 46.7375L284.394 46.6285C283.416 46.0855 282.33 46.7375 282.33 47.8235Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M306.877 59.5545V64.7675"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M300.903 58.4675V60.0975C300.903 60.7485 301.229 61.4005 301.881 61.7265L302.098 61.8355C302.967 62.3785 304.161 61.7265 304.161 60.6405V59.0115C304.161 58.3595 303.836 57.7075 303.184 57.3815L302.967 57.2735C301.989 56.8385 300.903 57.4905 300.903 58.4675Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M315.349 64.5505V69.6555"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M309.375 63.4645V65.0935C309.375 65.7455 309.701 66.3965 310.353 66.7225L310.57 66.8315C311.439 67.3745 312.633 66.7225 312.633 65.6365V64.0075C312.633 63.3555 312.308 62.7035 311.656 62.3785L311.439 62.2695C310.57 61.7265 309.375 62.3785 309.375 63.4645Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M262.345 33.921V39.0259"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M256.371 32.8349V34.4641C256.371 35.1158 256.697 35.7675 257.348 36.0933L257.566 36.2019C258.435 36.745 259.629 36.0933 259.629 35.0072V33.3779C259.629 32.7262 259.304 32.0746 258.652 31.7487L258.435 31.6401C257.566 31.097 256.371 31.7487 256.371 32.8349Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M270.925 38.8087V44.0225"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M264.952 37.7226V39.3518C264.952 40.0035 265.277 40.6555 265.929 40.9815L266.146 41.0895C267.015 41.6325 268.21 40.9815 268.21 39.8949V38.2656C268.21 37.6139 267.884 36.9623 267.232 36.6364L267.015 36.5278C266.146 36.0933 264.952 36.745 264.952 37.7226Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M279.506 43.8045V48.9095"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M273.532 42.7185V44.3485C273.532 44.9995 273.858 45.6515 274.51 45.9775L274.727 46.0855C275.596 46.6285 276.791 45.9775 276.791 44.8915V43.2615C276.791 42.6105 276.465 41.9585 275.813 41.6325L275.596 41.5245C274.618 40.9815 273.532 41.6325 273.532 42.7185Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M301.881 66.6145V71.7195"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M295.798 65.5275V67.1575C295.798 67.8085 296.124 68.4605 296.776 68.7865L296.993 68.8955C297.862 69.4385 299.057 68.7865 299.057 67.7005V66.0715C299.057 65.4195 298.731 64.7675 298.079 64.4415L297.862 64.3335C296.993 63.7905 295.798 64.4415 295.798 65.5275Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M290.802 62.5955V64.2245C290.802 64.8765 291.128 65.5275 291.779 65.8535L291.997 65.9625C292.866 66.5055 294.06 65.8535 294.06 64.7675V63.1385C294.06 62.4865 293.734 61.8355 293.083 61.5095L292.866 61.4005C291.997 60.9665 290.802 61.6175 290.802 62.5955Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M285.806 59.7715V61.4005C285.806 62.0525 286.131 62.7035 286.783 63.0295L287 63.1385C287.869 63.6815 289.064 63.0295 289.064 61.9435V60.3145C289.064 59.6625 288.738 59.0115 288.086 58.6855L287.869 58.5765C287 58.0335 285.806 58.6855 285.806 59.7715Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M310.353 71.5015V76.7155"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M304.379 70.4155V72.0445C304.379 72.6965 304.705 73.3485 305.356 73.6745L305.573 73.7825C306.442 74.3255 307.637 73.6745 307.637 72.5875V70.9585C307.637 70.3075 307.311 69.6555 306.66 69.3295L306.442 69.2205C305.573 68.6775 304.379 69.4385 304.379 70.4155Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M318.933 76.4985V81.6025"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M312.959 75.4115V77.0415C312.959 77.6925 313.285 78.3445 313.937 78.6705L314.154 78.7785C315.023 79.3225 316.218 78.6705 316.218 77.5845V75.9545C316.218 75.3035 315.892 74.6515 315.24 74.3255L315.023 74.2175C314.045 73.6745 312.959 74.3255 312.959 75.4115Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M265.929 45.8685V50.9735"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M259.955 44.7825V46.4115C259.955 47.0635 260.281 47.7155 260.933 48.0405L261.15 48.1495C262.019 48.6925 263.214 48.0405 263.214 46.9545V45.3255C263.214 44.6735 262.888 44.0225 262.236 43.6965L262.019 43.5875C261.041 43.0445 259.955 43.6965 259.955 44.7825Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M254.959 41.8495V43.4795C254.959 44.1305 255.285 44.7825 255.936 45.1085L256.154 45.2165C257.023 45.7605 258.217 45.1085 258.217 44.0225V42.3925C258.217 41.7415 257.892 41.0895 257.24 40.7635L257.023 40.6555C256.045 40.2205 254.959 40.8725 254.959 41.8495Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M274.51 50.7565V55.9695"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M268.427 49.6705V51.2995C268.427 51.9515 268.753 52.6025 269.405 52.9285L269.622 53.0375C270.491 53.5805 271.686 52.9285 271.686 51.8425V50.2135C271.686 49.5615 271.36 48.9095 270.708 48.5845L270.491 48.4755C269.622 48.0405 268.427 48.6925 268.427 49.6705Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M282.982 55.7525V60.8575"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M277.008 54.6665V56.2955C277.008 56.9475 277.334 57.5995 277.985 57.9245L278.203 58.0335C279.071 58.5765 280.266 57.9245 280.266 56.8385V55.2095C280.266 54.5575 279.94 53.9065 279.289 53.5805L279.071 53.4715C278.203 52.9285 277.008 53.5805 277.008 54.6665Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M301.881 47.6065V52.8205"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M295.798 46.6285V48.2585C295.798 48.9095 296.124 49.5615 296.776 49.8875L296.993 49.9965C297.862 50.5395 299.057 49.8875 299.057 48.8015V47.1725C299.057 46.5205 298.731 45.8685 298.079 45.5425L297.862 45.4345C296.993 44.8915 295.798 45.5425 295.798 46.6285Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M290.802 43.6965V45.3255C290.802 45.9775 291.128 46.6285 291.779 46.9545L291.997 47.0635C292.866 47.6065 294.06 46.9545 294.06 45.8685V44.2395C294.06 43.5875 293.734 42.9365 293.083 42.6105L292.866 42.5015C291.997 41.9585 290.802 42.6105 290.802 43.6965Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M285.806 40.7635V42.3925C285.806 43.0445 286.131 43.6965 286.783 44.0225L287 44.1305C287.869 44.6735 289.064 44.0225 289.064 42.9365V41.3065C289.064 40.6555 288.738 40.0035 288.086 39.6776L287.869 39.569C287 39.1345 285.806 39.7862 285.806 40.7635Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M310.353 52.6025V57.7075"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M304.379 51.5165V53.1455C304.379 53.7975 304.705 54.4495 305.356 54.7755L305.573 54.8835C306.442 55.4265 307.637 54.7755 307.637 53.6885V52.0595C307.637 51.4085 307.311 50.7565 306.66 50.4305L306.442 50.3215C305.573 49.7785 304.379 50.4305 304.379 51.5165Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M312.959 56.4045V58.0335C312.959 58.6855 313.285 59.3365 313.937 59.6625L314.154 59.7715C315.023 60.3145 316.218 59.6625 316.218 58.5765V56.9475C316.218 56.2955 315.892 55.6445 315.24 55.3185L315.023 55.2095C314.045 54.7755 312.959 55.4265 312.959 56.4045Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M265.929 26.9697V32.0746"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M259.955 25.8835V27.5128C259.955 28.1645 260.281 28.8161 260.933 29.142L261.15 29.2506C262.019 29.7937 263.214 29.142 263.214 28.0558V26.4266C263.214 25.7749 262.888 25.1232 262.236 24.7974L262.019 24.6888C261.041 24.1457 259.955 24.7974 259.955 25.8835Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M254.959 22.9509V24.5802C254.959 25.2318 255.285 25.8835 255.936 26.2094L256.154 26.318C257.023 26.8611 258.217 26.2094 258.217 25.1232V23.494C258.217 22.8423 257.892 22.1906 257.24 21.8648L257.023 21.7562C256.045 21.2131 254.959 21.8648 254.959 22.9509Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M274.51 31.8574V36.9623"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M268.427 30.7712V32.4004C268.427 33.0521 268.753 33.7038 269.405 34.0297L269.622 34.1383C270.491 34.6813 271.686 34.0297 271.686 32.9435V31.3143C271.686 30.6626 271.36 30.0109 270.708 29.6851L270.491 29.5764C269.622 29.0334 268.427 29.6851 268.427 30.7712Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M282.982 36.745V41.9585"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M277.008 35.6589V37.2881C277.008 37.9398 277.334 38.5915 277.985 38.9173L278.203 39.0259C279.071 39.569 280.266 38.9173 280.266 37.8312V36.2019C280.266 35.5502 279.94 34.8985 279.289 34.5727L279.071 34.4641C278.203 34.0296 277.008 34.6813 277.008 35.6589Z"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M257.038 13.7428L326.395 53.7415V101.815"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M331.165 115.515L275.02 147.93"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M318.811 114.536L285.417 133.741"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path d="M245 74L211 94" stroke={primary} strokeWidth="2" strokeMiterlimit="10" />
      <path d="M247 67L205 91" stroke={primary} strokeWidth="2" strokeMiterlimit="10" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M246.886 66.8305L331.165 115.515V50.6835L246.886 2V66.8305Z"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path d="M275 148L242 129" stroke={primary} strokeWidth="2" strokeMiterlimit="10" />
      <path
        d="M318.811 114.536L262.543 82.1205"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M242.5 135L275.02 154.046C283.236 149.316 287.843 146.665 296.059 141.936"
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M318.933 57.4905V62.7035"
        stroke={secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SVG500;
