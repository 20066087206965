import React, { useEffect, useState } from 'react';
import { DEFAULT_PRIMARY_COLOR, DEFAULT_SECONDARY_COLOR } from '../../common/constants';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../state';

export interface Theme {
  primary: string;
  secondary: string;
}

interface Props {
  [key: string]: any;
}

const withTheme = <T extends Props>(WrappedComponent: React.FC<T>) => {
  return function(props: T) {
    const [theme, setTheme] = useState<Theme>({
      primary: DEFAULT_PRIMARY_COLOR,
      secondary: DEFAULT_SECONDARY_COLOR,
    });

    const me = useSelector((state: AppStateInterface) => state.me.me.data);
    const company = useSelector(
      (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
    );

    useEffect(() => {
      if (company && setTheme) {
        setTheme(prevState => ({
          primary: company?.settings?.primaryColor || prevState.primary,
          secondary: company?.settings?.secondaryColor || prevState.secondary,
        }));
      }
    }, [company]);

    useEffect(() => {
      if (me && setTheme) {
        setTheme(prevState => ({
          primary: me?.company?.settings?.primaryColor || prevState.primary,
          secondary: me?.company?.settings?.secondaryColor || prevState.secondary,
        }));
      }
    }, [me]);

    return (
      <ThemeProvider theme={theme}>
        <WrappedComponent {...props} setTheme={setTheme} />
      </ThemeProvider>
    );
  };
};

export default withTheme;
