import { ReviewActionTypes as ReviewActions, ReviewStateInterface } from './review.types';
import { REVIEW_STATE as rev, RECORDING_STATE as rec } from '../../common/constants';
import { convertSecToString } from '../../utils/misc.utils';

const defaultState: ReviewStateInterface = {
  videoTimeStamp: convertSecToString(0, true),
  currentSegment: 1,
  isRecording: false,
  reviewState: rev.IDLE,
  recordingState: rec.IDLE,
  comments: [],
  audios: [],
};

export default function ReviewReducer(state = defaultState, action: any) {
  switch (action.type) {
    case ReviewActions.REVIEW_UPDATE:
      return {
        ...state,
        reviewState: action.payload,
      };

    case ReviewActions.CHANGE_SEGMENT:
      return {
        ...state,
        currentSegment: action.payload,
      };

    case ReviewActions.CHANGE_VIDEO_STATUS:
      return {
        ...state,
        isRecording: action.isRecording,
        reviewState: action.reviewStatus,
      };

    case ReviewActions.CHANGE_AUDIO_STATUS:
      return {
        ...state,
        recordingState: action.recordingState,
      };

    case ReviewActions.SET_VIDEO_TIME:
      return {
        ...state,
        videoTimeStamp: action.payload,
      };

    case ReviewActions.SET_AUDIOS:
      return {
        ...state,
        audios: action.payload,
      };

    default:
      return state;
  }
}
