import envUtils from './env.utils';
import {
  DEFAULT_IMG_QUALITY,
  DEFAULT_MIN_IMG_HEIGHT,
  DEFAULT_MIN_IMG_WIDTH,
} from '../common/constants';
import { isEmpty } from 'lodash';

interface LowSize {
  width?: number;
  height?: number;
}
const verifyValue = (val?: LowSize): boolean => {
  if (!isEmpty(val)) return true;
  else return false;
};

const getSizeValues = (val: LowSize): string => {
  let output = '';
  if (!isEmpty(val)) {
    const newVal = val as LowSize;
    if (newVal.height) output += `&h=${Math.max(newVal.height, DEFAULT_MIN_IMG_HEIGHT)}`;
    if (newVal.width) output += `&w=${Math.max(newVal.width, DEFAULT_MIN_IMG_WIDTH)}`;
  }
  return output;
};

const getQuality = (val?: LowSize): number => {
  return val && !isEmpty(val) ? 100 : DEFAULT_IMG_QUALITY;
};

export function compileAvatarUrl(avatar: string, lowSize?: LowSize, noFilter?: boolean) {
  if (noFilter) return envUtils.mediaBaseUrl + avatar;
  else {
    const sizeInfo = lowSize && verifyValue(lowSize) ? getSizeValues(lowSize) : '';
    return `${envUtils.mediaBaseUrl +
      avatar}?fm=webp&lossless=true&auto=compress&cs=strip&q=${getQuality(lowSize) + sizeInfo}`;
  }
}
