import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../../state';
import { MapShimmer } from '../shimmer/location';
const GoogleMaps = lazy(() => import('../../../../location'));

interface Location {
  lat?: number;
  lng?: number;
  className?: string;
}
const BuildingLocation: React.FC<Location> = props => {
  const { lat, lng, className } = props;
  const info = useSelector(
    (state: AppStateInterface) =>
      state.subdomain.listing.singleListing.data || {
        latitude: lat?.toString(),
        longitude: lng?.toString(),
      },
  );

  return (
    <React.Fragment>
      {/* <h2 className="dashboard__heading dashboard__heading-alt text-center font-semibold mb-5">
        Map
      </h2> */}
      {info?.latitude && info?.longitude && (
        <Suspense fallback={<MapShimmer />}>
          <div className={`map--box ${className}`}>
            <GoogleMaps
              location={{
                lat: parseFloat(info?.latitude),
                lng: parseFloat(info?.longitude),
              }}
              marker={[
                {
                  lat: parseFloat(info?.latitude),
                  lng: parseFloat(info?.longitude),
                },
              ]}
              selected={0}
            />
          </div>
        </Suspense>
      )}
    </React.Fragment>
  );
};

export default BuildingLocation;
