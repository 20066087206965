import { AxiosPromise, CancelToken } from 'axios';
import api from './../../../utils/api.utils';
import {
  GetListingObject,
  GetAllListingsResponseInterface,
  sortByTypes,
  orderByTypes,
  TourFormInterface,
  TourContactInterface,
  GetTourListingRequest,
  GetTourListings,
  GetTourUrlResponseInterface,
  GetWebsiteInterface,
} from './listing.types';
import { cleanObject } from './../../../utils/apiValidatior';
import { ListingInterface } from '../../listing/listing.types';

export const postTourRequestAPI = (
  payload: TourFormInterface,
  ct?: CancelToken,
): AxiosPromise<GetTourUrlResponseInterface> => {
  console.log(payload);

  return api.post('/room/create/tenant', cleanObject(payload), {
    cancelToken: ct,
  });
};
export const postContactRequestAPI = (
  payload: TourContactInterface,
  ct?: CancelToken,
): AxiosPromise<GetTourUrlResponseInterface> => {
  return api.post('/broker/contact', cleanObject(payload), {
    cancelToken: ct,
  });
};

export const getSingleListingAPI = (street: string, ct?: CancelToken): AxiosPromise<any> => {
  return api.get(`/listing/${street}`, { cancelToken: ct });
};
export const getSingleListingWebsiteAPI = (street: string, ct?: CancelToken): AxiosPromise<any> => {
  return api.get(`/building-v2/website/${street}`, { cancelToken: ct });
};

export const getAllSubDomainListingsAPI = (
  companyId: string,
  limit: number,
  skip: number,
  search?: string,
  sortBy?: sortByTypes,
  orderBy?: orderByTypes,
  subMarket?: string,
  ct?: CancelToken,
): AxiosPromise<GetAllListingsResponseInterface> => {
  return api.get('listing/all/group', {
    cancelToken: ct,
    params: cleanObject({
      companyId,
      limit,
      skip,
      search,
      sortBy,
      orderBy,
      subMarket,
    }),
  });
};

export const getAllTourListingsAPI = (
  payload: GetTourListingRequest,
  ct?: CancelToken,
): AxiosPromise<GetTourListings> => {
  return api.get('listing/tour/all', {
    cancelToken: ct,
    params: cleanObject(payload),
  });
};

export const getWebsiteByDomainAPI = async (payload: string, ct?: CancelToken) => {
  const res = await api.get(`website/${payload}`, {
    cancelToken: ct,
  });
  return res;
};

export const deleteListingAPI = (payload: string, ct?: CancelToken): AxiosPromise<boolean> => {
  return api.delete(`listing/${payload}`, {
    cancelToken: ct,
    params: cleanObject(payload),
  });
};
