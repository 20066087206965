import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';

import '../../../../assets/css/tailwind.css';
import BuildingWebsite from '../../../../components/building/view/buildingsNew/website/buildingWebsite';
import { updateSingleBuilding } from '../../../../state/building/building.actions';

const NotFound = lazy(() => import('../../../../pages/error/404'));

interface Props {
  data?: any;
}
const CustomDomainListing: React.FC<Props> = ({ data }: Props) => {
  updateSingleBuilding(data?.building);

  if (!data?.building?.published) return <NotFound />;

  return <BuildingWebsite />;
};

export default CustomDomainListing;
