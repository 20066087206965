import React, { lazy, useEffect } from 'react';
import NotFound from '../error/404';
import { Route, Switch } from 'react-router-dom';
import CustomDomainListing from '../../components/subdomain/listing/listDetail/CustomDomainListing';
import SdListingDetail from '../subdomain/listing/singleListing';
interface Props {
  data?: any;
}
const CustomDomainApp: React.FC<Props> = ({ data }) => {
  return (
    <div id="customdomain-app">
      <Switch>
        <Route
          exact
          path="/"
          component={() => {
            return <CustomDomainListing data={data} />;
          }}
        />
        <Route
          exact
          path="/unit/:listingId"
          component={() => {
            return <SdListingDetail companyData={data?.building?.company} />;
          }}
        />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default CustomDomainApp;
