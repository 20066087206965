import React from 'react';
import { useSelector } from 'react-redux';
import {
  BuildingFeatureFormField,
  BuildingFeatureList,
} from '../../../../../../state/building/forms/building.form';
import { selectSubdomainBuilding } from '../../../../../../state/subdomain/building/building.selector';
import BuildingFeatureCollapse from './BuildingFeatureCollapse';

const labelsMap: { [key in BuildingFeatureFormField]: BuildingFeatureList } = {
  activeCommunity: BuildingFeatureList.ACTIVE_COMMUNITY,
  basketballCourt: BuildingFeatureList.BASKETBALL,
  disabledAccess: BuildingFeatureList.DISABLED_ACCESS,
  doorman: BuildingFeatureList.DOORMAN,
  elevator: BuildingFeatureList.ELEVATOR,
  fitnessCenter: BuildingFeatureList.FITNESS_CENTER,
  gatedEntry: BuildingFeatureList.GATED_ENTRY,
  nearTransportation: BuildingFeatureList.NEAR_TRANSPORTATION,
  sportsCourt: BuildingFeatureList.SPORTS_COURT,
  storage: BuildingFeatureList.STORAGE,
  tennisCourt: BuildingFeatureList.TENNIS_COURT,
};

const BuildingFeaturesSection = () => {
  const building = useSelector(selectSubdomainBuilding());

  const availableFeatures = Object.entries(labelsMap).map(([key, label]) => {
    const feature = building?.[key as BuildingFeatureFormField];
    if (feature && (feature.description || feature.media?.length)) return { ...feature, label };
    return null;
  });

  return (
    <div className="features-main-container">
      {availableFeatures.map((feature, index) => {
        if (!feature) return null;
        return (
          <BuildingFeatureCollapse
            description={feature?.description}
            key={index}
            label={feature?.label}
            media={feature?.media}
          />
        );
      })}
    </div>
  );
};

export default BuildingFeaturesSection;
