import { combineReducers } from 'redux';
import company from './company/company.reducer';
import { CompanySubDomainStateInterface } from './company/company.types';
import listing from './listing/listing.reducer';
import building from './building/building.reducer';
import { ListingStateInterface } from './listing/listing.types';
import tour from './tour/tour.reducer';
import { TourStateInterface } from './tour/tour.types';
import { BuildingStateInterface } from './building/building.types';

export interface SubDomainAppStateInterface {
  company: CompanySubDomainStateInterface;
  listing: ListingStateInterface;
  building: BuildingStateInterface;
  tour: TourStateInterface;
}

const subDomainAppReducer = combineReducers<SubDomainAppStateInterface>({
  company,
  listing,
  building,
  tour,
});

export default subDomainAppReducer;
