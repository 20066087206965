import { AxiosPromise, CancelToken } from 'axios';
import api from '../../utils/api.utils';
import {
  BuildingDataPayloadInterface,
  GetAllBuildingsResponseInterface,
  GetBuildingsListResponseInterface,
  UpdateSingleBuildingDataInterface,
  GetAllBuildingsQueryInterface,
  SingleBuildingDataInterface,
  GetBuildingWalkScoresResponseInterface,
  RemoveListingBrokersFromPlanInterface,
  AddCustomDomainRequestInterface,
  SingleBuildingInterface_V2,
  GetAllBuildingInterfaceV_2,
  CreateUnitsInterface,
} from './building.types';
import { cleanObject } from '../../utils/apiValidatior';
import { User } from '../listing/listing.types';

export const getBuildingDetailsAPI = (buildingId: string, ct?: CancelToken): AxiosPromise => {
  return api.get(`/building-v2/${buildingId}`, {
    cancelToken: ct,
  });
};

export const getBuildingsListAPI = (
  companyId: string,
  ct?: CancelToken,
): AxiosPromise<Array<GetBuildingsListResponseInterface>> => {
  return api.get(`/building/company/${companyId}`, {
    cancelToken: ct,
  });
};
export const getRemoveListingsAndBrokersFromPlanAPI = (
  data: RemoveListingBrokersFromPlanInterface,
  ct?: CancelToken,
): AxiosPromise<Array<GetBuildingsListResponseInterface>> => {
  return api.post(`/building/remove/brokers-listings/`, data, {
    cancelToken: ct,
  });
};

// depricated, keeping for referece. Client can change requirements again
export const addBuildingAPI = (
  data: BuildingDataPayloadInterface,
  ct?: CancelToken,
): AxiosPromise => {
  return api.post('/building', data, {
    cancelToken: ct,
  });
};

export const addBuildingAPI_V2 = (
  data: SingleBuildingInterface_V2,
  ct?: CancelToken,
): AxiosPromise => {
  return api.post('/building-v2', data, {
    cancelToken: ct,
  });
};

export const createUnitAPI = (data: CreateUnitsInterface, ct?: CancelToken): AxiosPromise => {
  return api.post('/listing', data, {
    cancelToken: ct,
  });
};

// depricated, keeping just for reference
export const getAllBuildingsAPI = (
  query: GetAllBuildingsQueryInterface,
  ct?: CancelToken,
): AxiosPromise<GetAllBuildingsResponseInterface> => {
  return api.get('/building/all/company', {
    cancelToken: ct,
    params: cleanObject(query),
  });
};

export const getAllBuildingsAPI_V2 = (
  ct?: CancelToken,
): AxiosPromise<GetAllBuildingInterfaceV_2> => {
  return api.get('/building-v2/all/buildings', {
    cancelToken: ct,
  });
};

export const updateBuildingAPI = (
  buildingId: string,
  data: SingleBuildingDataInterface,
  ct?: CancelToken,
): AxiosPromise<SingleBuildingDataInterface> => {
  return api.put(`/building-v2/${buildingId}`, data, {
    cancelToken: ct,
  });
};

export const deleteBuildingAPI = (buildingId: string, ct?: CancelToken) => {
  return api.delete(`/building-v2/${buildingId}`, {
    cancelToken: ct,
  });
};

export const getBuildingBrokersAPI = (
  buildingId: string,
  listingStatus: string,
  ct?: CancelToken,
): AxiosPromise<Array<User>> => {
  return api.get('/building/users/all', {
    cancelToken: ct,
    params: cleanObject({ buildingId }),
  });
};

export const getBuildingWalkScoresAPI = (
  buildingId: string,
  ct?: CancelToken,
): AxiosPromise<GetBuildingWalkScoresResponseInterface> => {
  return api.get(`/building/walk-scores/single/${buildingId}`, {
    cancelToken: ct,
    params: { buildingId },
  });
};
export const addCustomDomainAPI = (
  payload: AddCustomDomainRequestInterface,
  ct?: CancelToken,
): AxiosPromise<any> => {
  return api.post(`/building-v2/create/subdomain`, payload, {
    cancelToken: ct,
  });
};
export const deleteCustomDomainAPI = (payload: string, ct?: CancelToken): AxiosPromise<any> => {
  return api.delete(`/building-v2/subdomain/${payload}`, {
    cancelToken: ct,
  });
};
