import { UsersActions, UsersStateInterface } from './users.types';
import { GetState } from '../../types/GetStateInterface';

const defaultState: UsersStateInterface = {
  brokers: GetState,
};

export default function UserReducer(state = defaultState, action: any) {
  switch (action.type) {
    case UsersActions.GET_BROKERS_INIT:
      return {
        ...state,
        brokers: {
          ...state.brokers,
          loading: true,
          error: null,
        },
      };

    case UsersActions.GET_BROKERS_FAILURE:
      return {
        ...state,
        brokers: {
          ...state.brokers,
          loading: false,
          error: action.payload,
          hasData: true,
        },
      };

    case UsersActions.GET_BROKERS_SUCCESSFUL:
      return {
        ...state,
        brokers: {
          ...state.brokers,
          loading: false,
          error: null,
          data: action.payload,
          hasData: true,
        },
      };

    default:
      return state;
  }
}
