import React from 'react';
import NumberFormat from 'react-number-format';
import {
  SEPARATORS,
  REVIEW_SEGMENTS as total,
  LISTING_STATUS as LS,
  VIDEO_STATUS as VS,
} from '../../common/constants';
import {
  HandlerConditions,
  ListingInterface,
  SingleListingDataInterface,
} from '../../state/listing/listing.types';
import { BuildingValueInString } from '../../state/building/building.types';

export const propertyTypeOptions = () => {
  return (
    <React.Fragment>
      <option value="" key={-1} className="placeholder">
        Property Type *
      </option>
      <option>House </option>
      <option>Condo</option>
      <option>Townhouse</option>
      <option>Entire apartment community</option>
      <option>Room renting</option>
    </React.Fragment>
  );
};
export const hideAddressOptions = () => {
  return (
    <React.Fragment>
      <option value="false" key={-1}>
        No (Recommended)
      </option>
      <option value="true">Yes</option>
    </React.Fragment>
  );
};
export const upgradePlanOptions = () => {
  return (
    <React.Fragment>
      <option value="false" key={-1}>
        Standard plan
      </option>
      <option value="SAVE_FOR_LATER">Save for later</option>
    </React.Fragment>
  );
};

export const timeOptions = () => {
  return (
    <React.Fragment>
      <option value="" key={-1} className="placeholder">
        Time
      </option>
      <option>12:00am </option>
      <option>12:30am </option>
      <option>1:00am</option>
      <option>1:30am</option>
      <option>2:00am</option>
      <option>2:30am</option>
      <option>3:00am</option>
      <option>3:30am</option>
      <option>4:00am</option>
      <option>4:30am</option>
      <option>5:00am</option>
      <option>5:30am</option>
      <option>6:00am</option>
      <option>6:30am</option>
      <option>7:00am</option>
      <option>7:30am</option>
      <option>8:00am</option>
      <option>8:30am</option>
      <option>9:00am</option>
      <option>9:30am</option>
      <option>10:00am</option>
      <option>10:30am</option>
      <option>11:00am</option>
      <option>11:30am</option>
      <option>12:00pm</option>
      <option>12:30pm</option>
      <option>1:00pm</option>
      <option>1:30pm</option>
      <option>2:00pm</option>
      <option>2:30pm</option>
      <option>3:00pm</option>
      <option>3:30pm</option>
      <option>4:00pm</option>
      <option>4:30pm</option>
      <option>5:00pm</option>
      <option>5:30pm</option>
      <option>6:00pm</option>
      <option>6:30pm</option>
      <option>7:00pm</option>
      <option>7:30pm</option>
      <option>8:00pm</option>
      <option>8:30pm</option>
      <option>9:00pm</option>
      <option>9:30pm</option>
      <option>10:00pm</option>
      <option>10:30pm</option>
      <option>11:00pm</option>
      <option>11:30pm</option>
    </React.Fragment>
  );
};
export const bedroomsOptions = () => {
  return (
    <React.Fragment>
      <option value="" key={-1} className="placeholder">
        Bedrooms
      </option>
      <option>12 </option>
      <option>12 </option>
      <option>1</option>
      <option>2</option>
      <option>3</option>
      <option>4</option>
      <option>5</option>
      <option>6</option>
      <option>7</option>
      <option>8</option>
      <option>9</option>
      <option>10</option>
      <option>10</option>
      <option>11</option>
      <option>12</option>
    </React.Fragment>
  );
};

export const editCapacityOptions = () => {
  return (
    <React.Fragment>
      <option value="" key={-1} className="placeholder">
        Capacity
      </option>
      <option value="1-8">1-8</option>
      <option value="1-15">1-15</option>
      <option value="1-30">1-30</option>
      <option value="1-50">1-50</option>
      <option value="1-100">1-100</option>
      <option value="1-100+">1-100+</option>
    </React.Fragment>
  );
};

export const conditionOptions = () => {
  return (
    <React.Fragment>
      <option value="" key={-1} className="placeholder">
        Condition
      </option>
      <option value="Full-Blown Build Out">Full-Blown Build Out</option>
      <option value="Partial Build Out">Partial Build Out</option>
      <option value="Wipe-Off">Wipe-Off</option>
      <option value="Spec Suite">Spec Suite</option>
      <option value="Second Generation">Second Generation</option>
    </React.Fragment>
  );
};

export const errorMsg = (error: any, errorMessage?: string, className?: string) => {
  if (errorMessage) return <p className={`error__msg ${className || ``}`}>{errorMessage}</p>;
  return error ? <p className={`error__msg ${className || ``}`}>{error?.message}</p> : null;
};

export const commaSeparated = (e: number) => {
  return <NumberFormat value={e} displayType="text" thousandSeparator />;
};

export const mergeBuildingHours = (
  hours: BuildingValueInString[],
  join?: boolean,
  separator?: string,
): string | string[] => {
  const output = [] as string[];
  for (const hour of hours) {
    if (hour.visibility) {
      const parts = hour?.value?.split(SEPARATORS.SPACE);
      const dayParts = parts[0].split(SEPARATORS.DASH);
      const timeParts = parts[1].split(SEPARATORS.DASH);
      if (dayParts.length > 1 && dayParts[0] === dayParts[1]) {
        if (timeParts.length > 1 && (timeParts[0] === 'Closed' || timeParts[1] === 'Closed')) {
          output.push(dayParts[1] + SEPARATORS.SPACE + 'Closed');
        } else {
          output.push(dayParts[1] + SEPARATORS.SPACE + parts[1]);
        }
      } else {
        if (timeParts.length > 1 && (timeParts[0] === 'Closed' || timeParts[1] === 'Closed')) {
          output.push(parts[0] + SEPARATORS.SPACE + 'Closed');
        } else {
          output.push(hour.value);
        }
      }
    }
  }
  return join ? output.join(separator || SEPARATORS.SEMI_COLON + SEPARATORS.SPACE) : output;
};

export const mergeWithAnd = (e: string[]): string => {
  if (e.length <= 1) return e.join('');
  const lastOne = e.pop();
  return e.join(SEPARATORS.COMMA + SEPARATORS.SPACE) + ' and ' + lastOne;
};

export const splitDurations = (e: number): number[] => {
  const duration = Math.round(e / total);
  const remain = e - duration * total;
  const output = new Array(duration).fill(duration);
  output[output.length - 1] = duration + remain;
  return output;
};

export const wistiaVideoConfigs = {
  options: {
    controlsVisibleOnLoad: false,
    copyLinkAndThumbnailEnabled: false,
    fakeFullscreen: false,
    fullscreenButton: false,
    fullscreenOnRotateToLandscape: false,
    muted: true,
    settingsControl: false,
    StyledPlayButton: false,
    playSuspendedOffScreen: false,
    silentAutoPlay: true,
    smallStyledPlayButton: false,
    volume: 0,
    volumeControl: false,
    endVideoBehavior: 'auto',
    autoPlay: false,
    playbar: true,
  },
};

export const renderHandlers = (listing: ListingInterface): HandlerConditions => {
  const output = {
    publish: false,
    unpublish: false,
    review: false,
  };
  if (listing.status === LS.PUBLISHED) output.unpublish = true;
  else output.publish = true;
  // const uploadedByStaff = listing?.videoUrls?.find(vid => vid.uploadedByStaff);
  // if (listing.status !== LS.DRAFT && uploadedByStaff) output.review = true;
  return output;
};

export enum listingHandlers {
  PUBLISH = 'publish',
  UNPUBLISH = 'unpublish',
  ACCEPT = 'accept',
}
