import React from 'react';
import SmallLoader from './small.loader';

interface Props {
  className?: string;
}
const Loader: React.FC<Props> = props => {
  return (
    <div className={`lds-canvas ${props.className || ''}`}>
      <SmallLoader />
    </div>
  );
};

export default Loader;
