import defaultAvatar from '../assets/images/user-default.svg';
import whiteLogo from '../assets/images/HomeTour_White_Logo.svg';
import darkLogo from '../assets/images/HomeTour_Dark_Logo.svg';
import whiteIcon from '../assets/images/HomeTour_White_Icon.svg';
import darkIcon from '../assets/images/HomeTour_Dark_Icon.svg';
import starCertificationImg from '../assets/images/star-certification.png';
import leedCertifiedImg from '../assets/images/Leed-Certified.png';
import leedPlatinumImg from '../assets/images/Leed-Platinum.png';
import leedGoldImg from '../assets/images/Leed-Gold.png';
import leedSilverImg from '../assets/images/Leed-Silver.png';
import pdfImg from '../assets/images/pdf.png';
import blackImg from '../assets/images/black.jpg';
import grayMapMarker from '../assets/images/map-marker-grey.svg';
import blueMapMarker from '../assets/images/map-marker-blue.svg';
import redMapMarker from '../assets/images/map-marker-red.svg';
import signInImg from '../assets/images/signin-img.svg';
import basicTemplateImg from '../assets/images/template-basic.png';
import basicTemplateImgNew from '../assets/images/template-basic-new.png';
import classicTemplateImg from '../assets/images/website-template-2.png';
import modernTemplateImg from '../assets/images/template-modern.png';
import chatUserImg from '../assets/images/chat-user.svg';
import changeBrowserImg from '../assets/images/change-browser.svg';
import browserDirectionImg from '../assets/images/browser-direction.svg';
import logoLandingImg from '../assets/images/logo-landing.svg';
import visaImg from '../assets/images/visa-sm.svg';
import masterCardImg from '../assets/images/master-card-sm.svg';
import amexImg from '../assets/images/amex-sm.svg';
import paymentCompletedImg from '../assets/images/payment--completed--bg.png';
import chatIcon from '../assets/images/chat-icon.svg';
import Tick from '../assets/images/tick.png';
import Visa from '../assets/images/Visa.png';
import Add from '../assets/images/add.svg';
import AddWhite from '../assets/images/add_white.svg';
import RoundedTick from '../assets/images/rounded-tick.svg';
import Trial from '../assets/images/trial.svg';
import ListItemIcon from '../assets/images/list-item-icon.svg';
import RentIcon from '../assets/images/rent.svg';
import SaleIcon from '../assets/images/sale.svg';
import Timer from '../assets/images/TimerIcon.svg';
import TemplateLocation from '../assets/images/TemplateLocation.svg';
import TemplateLocationWhite from '../assets/images/TemplateLocationWhite.svg';
import PropertyType from '../assets/images/PropertyType.svg';
import SizeIcon from '../assets/images/SizeIcon.svg';
import RoomIcon from '../assets/images/RoomIcon.svg';
import TemplateTourIcon from '../assets/images/TemplateTourIcon.svg';
import TemplateHomeFactsIcon from '../assets/images/TemplateHomeFactsIcon.svg';
import TemplateInteriorIcon from '../assets/images/TemplateInteriorIcon.svg';
import TemplateExteriorIcon from '../assets/images/TemplateExteriorIcon.svg';
import TemplateAppliancesIcon from '../assets/images/TemplateAppliancesIcon.svg';
import dollar from '../assets/images/dollar.svg';
import SaleIconNew from '../assets/images/SaleIcon.svg';
import RentIconNew from '../assets/images/RentIcon.svg';
import SizeIconNew from '../assets/images/sizeIconNew.svg';
import remove from '../assets/images/remove.svg';
import PhoneIcon from '../assets/images/phone.svg';
import BasketballCourtIcon from '../assets/images/BasketballCourtIcon.svg';
import ControlledAccessIcon from '../assets/images/ControlledAccessIcon.svg';
import DisabledAccessIcon from '../assets/images/DisabledAccessIcon.svg';
import DoormanIcon from '../assets/images/DoormanIcon.svg';
import ElevatorIcon from '../assets/images/ElevatorIcon.svg';
import FitnessCenterIcon from '../assets/images/FitnessCenterIcon.svg';
import GatedEntryIcon from '../assets/images/GatedEntryIcon.svg';
import NearTransportationIcon from '../assets/images/NearTransportationIcon.svg';
import Over55Icon from '../assets/images/Over55Icon.svg';
import SportsCourtIcon from '../assets/images/SportsCourtIcon.svg';
import StorageIcon from '../assets/images/StorageIcon.svg';
import TennisCourtIcon from '../assets/images/TennisCourtIcon.svg';
import UnitIcon from '../assets/images/UnitIcon.svg';
import CollapseChevronDown from '../assets/images/CollapseChevronDown.svg';
import BuildingExteriorIcon from '../assets/images/BuildingExteriorIcon.svg';
import BathroomIcon from '../assets/images/BathroomIcon.svg';
import UnitsSortIcon from '../assets/images/UnitsSortIcon.svg';
import ClassicBuildingThumbnail from '../assets/images/ClassicBuildingThumbnail.png';
import BasicBuildingThumbnail from '../assets/images/BasicBuildingThumbnail.png';
import ModernBuildingThumbnail from '../assets/images/ModernBuildingThumbnail.png';

const images = {
  Tick,
  Add,
  AddWhite,
  remove,
  SizeIconNew,
  Visa,
  defaultAvatar,
  whiteLogo,
  darkLogo,
  whiteIcon,
  darkIcon,
  starCertificationImg,
  leedCertifiedImg,
  leedPlatinumImg,
  leedGoldImg,
  leedSilverImg,
  pdfImg,
  blackImg,
  grayMapMarker,
  blueMapMarker,
  redMapMarker,
  signInImg,
  basicTemplateImg,
  classicTemplateImg,
  modernTemplateImg,
  chatUserImg,
  changeBrowserImg,
  browserDirectionImg,
  logoLandingImg,
  visaImg,
  masterCardImg,
  amexImg,
  paymentCompletedImg,
  chatIcon,
  RoundedTick,
  Trial,
  ListItemIcon,
  RentIcon,
  SaleIcon,
  basicTemplateImgNew,
  Timer,
  TemplateLocation,
  TemplateLocationWhite,
  PropertyType,
  SizeIcon,
  RoomIcon,
  TemplateTourIcon,
  TemplateHomeFactsIcon,
  TemplateInteriorIcon,
  TemplateExteriorIcon,
  TemplateAppliancesIcon,
  dollar,
  SaleIconNew,
  RentIconNew,
  PhoneIcon,
  BasketballCourtIcon,
  ControlledAccessIcon,
  DisabledAccessIcon,
  DoormanIcon,
  ElevatorIcon,
  FitnessCenterIcon,
  GatedEntryIcon,
  NearTransportationIcon,
  Over55Icon,
  SportsCourtIcon,
  StorageIcon,
  TennisCourtIcon,
  UnitIcon,
  CollapseChevronDown,
  BuildingExteriorIcon,
  BathroomIcon,
  UnitsSortIcon,
  ClassicBuildingThumbnail,
  ModernBuildingThumbnail,
  BasicBuildingThumbnail,
};

export const getLeedImage = (leedValue?: string) => {
  switch (leedValue) {
    case 'certified':
    case 'Certified':
      return leedCertifiedImg;
    case 'platinum':
    case 'Platinum':
      return leedPlatinumImg;
    case 'gold':
    case 'Gold':
      return leedGoldImg;
    case 'silver':
    case 'Silver':
      return leedSilverImg;
    default:
      return '';
  }
};

export default images;
