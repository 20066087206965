import { useCallback, useState, useEffect } from 'react';
import {
  ListingActions as actionName,
  TourFormInterface,
  orderByTypes,
  sortByTypes,
  GetTourListingRequest,
  GetTourListings,
  GetTourUrlResponseInterface,
  TourContactInterface,
  GetWebsiteInterface,
} from './listing.types';
import {
  postTourRequestAPI,
  postContactRequestAPI,
  getAllTourListingsAPI,
  getWebsiteByDomainAPI,
} from './listing.api';
import fetchActions from './../../../utils/state.utils';
import { GetState, GetStateInterface } from '../../../types/GetStateInterface';
import { useSelector } from 'react-redux';
import { AppStateInterface } from './../..';
import {
  clearAllListings,
  clearSingleListing,
  getAllListings,
  getSingleListing,
} from './listing.action';
import { DEFAULT_LISTING_LIMIT } from './../../../common/constants';
import axios from 'axios';
import { trim } from 'lodash';
import { CompanyActions } from '../company/company.types';

export const useGetAllSdListings = () => {
  const res = useSelector((state: AppStateInterface) => state.subdomain.listing.allListings);

  const getAllFilteredListings = (
    companyId: string,
    search?: string,
    sortBy?: sortByTypes,
    orderBy?: orderByTypes,
    subMarket?: string,
  ) => {
    getAllListings(companyId, DEFAULT_LISTING_LIMIT, 0, search, sortBy, orderBy, subMarket);
  };

  const fetchMore = (
    companyId: string,
    search?: string,
    sortBy?: sortByTypes,
    orderBy?: orderByTypes,
    subMarket?: string,
  ) => {
    if (res.data?.hasMore && !res.loading) {
      getAllListings(
        companyId,
        DEFAULT_LISTING_LIMIT,
        res.data?.skip || 0,
        search,
        sortBy,
        orderBy,
        subMarket,
        res.data?.data || [],
      );
    }
  };

  return { res, getAllFilteredListings, fetchMore };
};

export const useClearAllListings = () => {
  return { clearAllListings };
};

export const useGetSingleListingAction = () => {
  return { getSingleListing };
};

export const useClearSingleListing = () => {
  return { clearSingleListing };
};

export const useTourContact = () => {
  const [res, setRes] = useState<GetStateInterface<GetTourUrlResponseInterface>>(GetState);
  const postContactRequest = useCallback(async (payload: TourContactInterface) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.SUBMIT_CONTACT_FORM);
    try {
      const res = await postContactRequestAPI(
        payload,
        fetchActions.setCT(actionName.SUBMIT_CONTACT_FORM),
      );
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error) {
      setRes({ data: null, loading: false, error: error, hasData: true });
    }
  }, []);
  return { res, postContactRequest };
};

export const useTourRequest = () => {
  const [res, setRes] = useState<GetStateInterface<GetTourUrlResponseInterface>>(GetState);
  const postTourRequest = useCallback(async (payload: TourFormInterface) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.SUBMIT_TOUR_FORM);
    try {
      const res = await postTourRequestAPI(
        payload,
        fetchActions.setCT(actionName.SUBMIT_TOUR_FORM),
      );
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error) {
      setRes({ data: null, loading: false, error: error, hasData: true });
    }
  }, []);
  return { res, postTourRequest };
};

export const useTourListing = (companyId: string) => {
  const [res, setRes] = useState<GetStateInterface<GetTourListings>>(GetState);
  const getTourListings = useCallback(async (payload: GetTourListingRequest) => {
    if (companyId.length) {
      payload.limit = 10;
      payload.companyId = companyId;
      if (!payload.skip) payload.skip = 0;
      setRes(prevState => ({ ...prevState, loading: true }));
      fetchActions.callCT(actionName.GET_TOUR_LISTINGS);
      try {
        const res = await getAllTourListingsAPI(
          payload,
          fetchActions.setCT(actionName.GET_TOUR_LISTINGS),
        );
        setRes({ data: res.data, loading: false, error: null, hasData: true });
      } catch (error) {
        if (!axios.isCancel(error)) {
          setRes({ data: null, loading: false, error: error, hasData: true });
        }
      }
    }
  }, []);
  return { res, getTourListings };
};

export const useGetWebsiteListing = () => {
  const [res, setRes] = useState<GetStateInterface<any>>(GetState);
  const getWebsiteByDomain = useCallback(async (payload: string) => {
    try {
      const res = await getWebsiteByDomainAPI(
        payload,
        fetchActions.setCT(actionName.GET_WEBSITE_BY_DOMAIN),
      );

      setRes({ data: res.data, loading: false, error: null, hasData: true });
      fetchActions.success(CompanyActions.GET_COMPANY_INFO, res.data?.building?.company);
    } catch (error) {
      if (!axios.isCancel(error)) {
        // console.log(error);

        setRes({ data: null, loading: false, error: error, hasData: true });
      }
    }
  }, []);
  return { res, getWebsiteByDomain };
};

export const useSubdomainListingVisibility = () => {
  const data = useSelector(
    (state: AppStateInterface) => state.subdomain.listing.singleListing.data,
  );
  const [showDetail, setDetail] = useState(false);
  const [showDescription, setDescription] = useState(false);
  const [showLocation, setLocation] = useState(false);
  const [showPlans, setPlans] = useState(false);
  const [showTour, setTour] = useState(false);
  const [showSpecs, setSpecs] = useState(false);
  const [showAmenity, setAmenity] = useState(false);
  const [showMedia, setMedia] = useState(false);
  const [showBrokers, setBrokers] = useState(false);

  useEffect(() => {
    if (data) {
      // console.log(data);
      // setDescription(data.descriptionVisibility);
      // const build = data.building;
      // setLocation((build.longitude?.visibility && build.latitude?.visibility) || false);
      // setDetail(
      //   data?.sizeVisibility ||
      //     data?.capacityVisibility ||
      //     data?.monthlyRentVisibility ||
      //     data?.metroProximityVisibility,
      // );
      // setPlans(
      //   !!data.floorPlans?.length || (!!data.floorPlans?.length && data.floorPlansVisibility),
      // );
      // setAmenity(
      //   !!build.buildingAmenities?.find(amenity => amenity.visibility && !!trim(amenity.value)),
      // );
      // setSpecs(
      //   !!build.buildingHours?.find(hour => hour.visibility && !!trim(hour.value)) ||
      //     !!(build.yearBuilt?.value && build.yearBuilt?.visibility) ||
      //     !!(build.yearRenovated?.value && build.yearRenovated?.visibility) ||
      //     !!(build.floorsCount?.value && build.floorsCount?.visibility) ||
      //     !!(build.buildingSize?.value && build.buildingSize?.visibility) ||
      //     !!(build.averageFloor?.value && build.averageFloor?.visibility) ||
      //     !!(build.parkingRatio?.value && build.parkingRatio?.visibility) ||
      //     !!(build.columnSpacingHeight?.value && build.columnSpacingHeight?.visibility) ||
      //     !!(build.columnSpacingWidth?.value && build.columnSpacingWidth?.visibility) ||
      //     !!(build.passengerElevators?.value && build.passengerElevators?.visibility) ||
      //     !!(build.freightElevators?.value && build.freightElevators?.visibility) ||
      //     !!(build.telecomProviders?.value && build.telecomProviders?.visibility) ||
      //     !!(build.leedCertification?.value && build.leedCertification?.visibility) ||
      //     !!(build.energyStarCertification?.value && build.energyStarCertification?.visibility) ||
      //     !!(build.slabToSlabHeight?.value && build.slabToSlabHeight?.visibility),
      // );
      // setTour(!!build.buildingAmenitiesMatterportLinks?.length);
      // setMedia(!!data.media?.length || !!build.media?.length || !!data.videoUrls?.length);
      // setBrokers(!!data.users?.length);
    }
  }, [data]);
  return {
    showDescription,
    showDetail,
    showLocation,
    showPlans,
    showTour,
    showSpecs,
    showAmenity,
    showMedia,
    showBrokers,
  };
};
