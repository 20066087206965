import React from 'react';
import { useSelector } from 'react-redux';
import { useToggle } from '../../../../../../hooks';
import { selectSubdomainBuilding } from '../../../../../../state/subdomain/building/building.selector';
import ImgTile from '../../../../../media/imgTile';
import ImagesLightbox from '../ImagesLightbox';
import './index.css';

const ClassicGallery = () => {
  const [show, toggle] = useToggle();
  const building = useSelector(selectSubdomainBuilding());
  const images = building?.media || [];
  const primary = images.find(({ isPrimary }) => isPrimary);
  const secondaryImages = images.filter(({ isPrimary }) => !isPrimary);
  return (
    <>
      <ImagesLightbox show={show} toggle={toggle} images={images} />
      <div className="gallery-container">
        <ImgTile className="primary-image" src={primary?.url} />
        {secondaryImages.length > 1 && (
          <div className="secondary-images-col">
            {secondaryImages.slice(0, 3).map((image, imageIndex) => (
              <div className="secondary-image-container" key={`${image}_${imageIndex}`}>
                <ImgTile className="secondary-image" src={image?.url} />
                {imageIndex === 2 && images.length > 4 ? (
                  <div onClick={toggle} className="secondary-image-overlay">
                    See {images.length - 4} more
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ClassicGallery;
