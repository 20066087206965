import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import RequestForm from './form';
import RequestComplete from './completion';

interface Props {
  hideForm: () => void;
  listingId?: string;
  buildingId?: string;
}
const TourRequestForm: React.FC<Props> = props => {
  const { hideForm: hide, listingId, buildingId } = props;
  const [complete, handleComplete] = useState(false);

  return (
    <Modal
      show
      onHide={hide}
      className="listing--modal set--modal"
      contained-modal-title-vcenter="true"
      centered
    >
      <Modal.Body>
        {!complete && (
          <RequestForm
            listingId={listingId}
            buildingId={buildingId}
            hide={hide}
            complete={() => handleComplete(true)}
          />
        )}
        {complete && <RequestComplete />}
      </Modal.Body>
    </Modal>
  );
};
export default TourRequestForm;
