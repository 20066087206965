import React from 'react';
import './index.css';
import Images from '../../../../../../utils/img.utils';
import { PhotoInterface } from '../../../../../../state/listing/listing.types';
import cx from 'classnames';
import ImgTile from '../../../../../media/imgTile';
import Button from '../Button';
import { useToggle } from '../../../../../../hooks';
import ImagesLightbox from '../ImagesLightbox';

interface Props {
  label?: string;
  description?: string;
  media?: PhotoInterface[];
}

const BuildingFeatureCollapse: React.FC<Props> = ({ description, label, media }) => {
  const [showLightbox, toggleLightbox] = useToggle();
  const [collapsed, toggle] = useToggle();
  const images = media || [];
  const chevClassName = cx({ 'feature-chevron-inactive': collapsed });
  return (
    <>
      <ImagesLightbox show={showLightbox} toggle={toggleLightbox} images={images} />
      <div className="feature-collapse-container">
        <div className="feature-head-container" onClick={toggle}>
          <img src={Images.CollapseChevronDown} className={chevClassName} />
          <span className="feature-title">{label}</span>
        </div>
        {!collapsed && (
          <>
            <div className="feature-description">{description}</div>
            {images.length ? (
              <>
                <div className="feature-images-container">
                  {images.slice(0, 3).map((image, imageIndex) => (
                    <div key={imageIndex} className="feature-image-row">
                      <ImgTile src={image.url} className="feature-image" />
                    </div>
                  ))}
                </div>
                {images.length > 3 && (
                  <Button onClick={toggleLightbox} size="sm" variant="blue-outline">
                    View all pictures
                  </Button>
                )}
              </>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default BuildingFeatureCollapse;
