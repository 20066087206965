import React, { MutableRefObject, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill, { Delta as DeltaInterface, Sources, RangeStatic } from 'quill';
/* eslint-disable */
// @ts-ignore
import MarkdownShortcuts from 'quill-markdown-shortcuts';
// @ts-ignore
import MagicUrl from 'quill-magic-url';
import { QuillEnhancers, IndentStyle, Block } from './quillEnhancers';
// @ts-ignore
export type UnprivilegedEditor = ReactQuill.UnprivilegedEditor;
/* eslint-enable */

interface Props {
  defaultValue?: string;
  onChange: (
    text: string,
    delta: DeltaInterface,
    source: Sources,
    editor: UnprivilegedEditor,
  ) => void;
  onEnter?: (event?: any) => void;
  onBlur?: (previousRange: RangeStatic, source: Sources, editor: UnprivilegedEditor) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  className?: string;
  formats?: string[];
  toolbar?: any;
  enableMarkdownShortcuts?: boolean;
  enableMagicUrl?: boolean;
  readOnly?: boolean;
  quillRef?: MutableRefObject<any>;
}

Block.tagName = 'DIV';
Quill.register(Block, true);

Quill.register(IndentStyle, true);

Quill.register('modules/clipboard', QuillEnhancers, true);

Quill.register('modules/markdownShortcuts', MarkdownShortcuts, true);

Quill.register('modules/magicUrl', MagicUrl, true);

const QuillEditor: React.FC<Props> = (props: Props) => {
  const {
    defaultValue,
    toolbar: customToolbar,
    placeholder,
    style,
    className,
    formats,
    onBlur,
    readOnly,
    onEnter,
    quillRef,
  } = props;
  const [executeOnEnter, setExecuteOnEnter] = useState(false);
  const [defaultToolbar] = useState([
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ direction: 'rtl' }], // text direction

    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ['link', 'image', 'video'],

    ['clean'], // remove formatting button
  ]);
  const [bindings] = useState({
    enter: {
      key: 'Enter',
      shiftKey: false,
      handler: () => {
        setExecuteOnEnter(true);
      },
    },
  });

  useEffect(() => {
    if (executeOnEnter && onEnter) {
      onEnter();
      setExecuteOnEnter(false);
    }
  }, [executeOnEnter]);

  const onChange = (
    content: string,
    delta: DeltaInterface,
    source: Sources,
    editor: UnprivilegedEditor,
  ) => {
    props.onChange(editor.getLength() > 1 ? content : '', delta, source, editor);
  };

  return (
    <ReactQuill
      ref={quillRef}
      readOnly={readOnly}
      style={style}
      className={className}
      defaultValue={defaultValue}
      onChange={onChange}
      theme="snow"
      onBlur={onBlur}
      formats={formats}
      modules={{
        keyboard: {
          bindings,
        },
        toolbar: customToolbar || defaultToolbar,
        markdownShortcuts: props.enableMarkdownShortcuts ?? true,
        magicUrl: props.enableMagicUrl ?? true,
        clipboard: {
          allowed: {
            tags: ['a', 'b', 'strong', 'u', 's', 'i', 'p', 'br', 'ul', 'ol', 'li', 'span'],
            attributes: ['href', 'rel', 'target'],
          },
        },
      }}
      placeholder={placeholder}
    />
  );
};

export default QuillEditor;
