// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import AudioRecorder from 'audio-recorder-polyfill';

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
if (!window?.MediaRecorder) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  window.MediaRecorder = AudioRecorder;
}
