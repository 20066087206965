import * as yup from 'yup';
import { useCallback } from 'react';

export const createFields = (elements: number) => {
  const form: any = {};

  for (let i = 0; i < elements; i++) {
    form[`email${i}`] = yup.string().email('Email is not in a valid format');
  }
  return form;
};

export const createEmailFields = (elements: number[]) => {
  const form: any = {};

  for (const element of elements) {
    form[`email${element}`] = yup.string().email('Please enter valid email!');
  }
  return form;
};

export const useYupValidationResolver = (validationSchema: any) =>
  useCallback(
    async data => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors?.inner?.reduce(
            (allErrors: any, currentError: any) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type || 'validation',
                message: currentError.message,
              },
            }),
            {},
          ),
        };
      }
    },
    [validationSchema],
  );
