import { MeActions, MeStateInterface } from './me.types';
import { GetState } from '../../types/GetStateInterface';
import { LoginActions } from '../login/login.types';

const defaultState: MeStateInterface = {
  me: GetState,
};

export default function MeReducer(state = defaultState, action: any) {
  switch (action.type) {
    case MeActions.ME_INIT:
      return {
        ...state,
        me: {
          ...state.me,
          loading: true,
          error: null,
        },
      };

    case MeActions.ME_FAILURE:
      return {
        ...state,
        me: {
          ...state.me,
          loading: false,
          error: action.payload,
          hasData: true,
        },
      };

    case MeActions.ME_SUCCESSFUL:
      return {
        ...state,
        me: {
          ...state.me,
          loading: false,
          error: null,
          data: action.payload,
          hasData: true,
        },
      };

    case LoginActions.LOGOUT:
      return defaultState;

    default:
      return state;
  }
}
