import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ContactForm from './form';
import RequestComplete from './completion';

interface Props {
  hideForm: () => void;
  listingId?: string;
  buildingId?: string;
}
const TourContactForm: React.FC<Props> = props => {
  const { hideForm: hide, listingId, buildingId } = props;
  const [isCompleted, handleComplete] = useState(false);

  return (
    <Modal
      show
      onHide={hide}
      className="listing--modal set--modal"
      contained-modal-title-vcenter="true"
      centered
    >
      <Modal.Body>
        {!isCompleted && (
          <ContactForm
            listingId={listingId}
            buildingId={buildingId}
            hide={hide}
            isCompleted={() => handleComplete(true)}
          />
        )}
        {isCompleted && <RequestComplete />}
      </Modal.Body>
    </Modal>
  );
};
export default TourContactForm;
