import React from 'react';

const SingleListingShimmer: React.FC = () => {
  return (
    <React.Fragment>
      <div className="listing subdomain--listing">
        <div className="container container--large">
          <div className="shimmer--header d-flex align-items-center justify-content-between">
            <span className="header__logo-shimmer shimmer__style animate"></span>
            <span className="header__bars-shimmer shimmer__style animate"></span>
          </div>
          <div className="subdomain--listing-content">
            <div className="row row--sapce-10">
              <div className="col-12 col-xl-6">
                <span className="shimmer__back">
                  <span className="back__btn-icon">
                    <i className="icon-chevron-left"></i>
                  </span>
                  <span className="shimmer__back-text shimmer__style animate"></span>
                </span>
                <div className="pt-2">
                  <span className="shimmer__heading-lg shimmer__style animate mt-4 mb-2"></span>
                </div>
                <span className="shimmer__heading-md shimmer__style animate mb-4"></span>
                <div className="al--listing-box al--listing-box-sh mb-4">
                  <div className="al--listing">
                    <span className="al__icon animate"></span>
                    <span className="al__text animate"></span>
                  </div>
                </div>
                <div className="row row--space-10 pt-1 mb-2 mb-xl-0">
                  <div className="col col-12 col-sm-4">
                    <div className="shimmer--keybox">
                      <div className="keyBox--head">
                        <span className="keyBoxHead__icon shimmer__style animate--gray"></span>
                        <span className="keyBoxHead__text shimmer__style animate--gray"></span>
                      </div>
                      <span className="shimmer__heading-md shimmer__style animate--gray"></span>
                      <span className="small__paragraph shimmer__style animate--gray"></span>
                    </div>
                  </div>
                  <div className="col col-12 col-sm-4">
                    <div className="shimmer--keybox">
                      <div className="keyBox--head">
                        <span className="keyBoxHead__icon shimmer__style animate--gray"></span>
                        <span className="keyBoxHead__text shimmer__style animate--gray"></span>
                      </div>
                      <span className="shimmer__heading-md shimmer__style animate--gray"></span>
                      <span className="small__paragraph shimmer__style animate--gray"></span>
                    </div>
                  </div>
                  <div className="col col-12 col-sm-4">
                    <div className="shimmer--keybox">
                      <div className="keyBox--head">
                        <span className="keyBoxHead__icon shimmer__style animate--gray"></span>
                        <span className="keyBoxHead__text shimmer__style animate--gray"></span>
                      </div>
                      <span className="shimmer__heading-md shimmer__style animate--gray"></span>
                      <span className="small__paragraph shimmer__style animate--gray"></span>
                    </div>
                  </div>
                  <div className="col col-12">
                    <div className="shimmer--keybox shimmer--keybox-expand">
                      <div className="keyBox--head">
                        <span className="keyBoxHead__icon shimmer__style animate--gray"></span>
                        <span className="keyBoxHead__text shimmer__style animate--gray"></span>
                      </div>
                      <span className="small__paragraph shimmer__style animate--gray"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-6">
                <div className="shimmer--video mb-4">
                  <div className="shimmer__play-highlight shimmer__style">
                    <span className="shimmer__play-text d-inline-flex shimmer__style animate--gray" />
                    <span className="shimmer__play-icon d-inline-flex ml-2">
                      <i className="icon-play-circle"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="subdomain--btns-off">
              <div className="row row--space-10 flex-lg-row-reverse mt-3">
                <div className="col-12 col-lg">
                  <span className="button__shimmer button__shimmer-expand w-100 mb-3 mb-lg-0">
                    <span className="button__shimmer-text animate--gray"></span>
                    <span className="button__shimmer-icon animate--gray"></span>
                  </span>
                </div>
                <div className="col-12 col-lg">
                  <span className="button__shimmer button__shimmer-expand w-100 mb-3 mb-lg-0">
                    <span className="button__shimmer-text animate--gray"></span>
                  </span>
                </div>
              </div>
            </div>
            <div className="team--main team--main-sbl mt-4 pt-2">
              <div className="row row--space-10">
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="team--block team--block-alt teamBlock--shimmer">
                    <div className="teamBlock--img animate"></div>
                    <div
                      className="teamBlock--detail text-md-left d-flex flex-column
                        align-items-center align-items-md-start"
                    >
                      <span className="teamBlock__user animate"></span>
                      <span className="teamBlock__email animate"></span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="team--block team--block-alt teamBlock--shimmer">
                    <div className="teamBlock--img animate"></div>
                    <div
                      className="teamBlock--detail text-md-left d-flex flex-column
                        align-items-center align-items-md-start"
                    >
                      <span className="teamBlock__user animate"></span>
                      <span className="teamBlock__email animate"></span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="team--block team--block-alt teamBlock--shimmer">
                    <div className="teamBlock--img animate"></div>
                    <div
                      className="teamBlock--detail text-md-left d-flex flex-column
                        align-items-center align-items-md-start"
                    >
                      <span className="teamBlock__user animate"></span>
                      <span className="teamBlock__email animate"></span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="team--block team--block-alt teamBlock--shimmer">
                    <div className="teamBlock--img animate"></div>
                    <div
                      className="teamBlock--detail text-md-left d-flex flex-column
                        align-items-center align-items-md-start"
                    >
                      <span className="teamBlock__user animate"></span>
                      <span className="teamBlock__email animate"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="photos--off">
              <span className="photos__control photos__control-prev">
                <i className="icon-chevron-left"></i>
              </span>
              <div className="photos--gallery block-flex">
                <div className="block--search-row photos--row-lg block--scroll">
                  <div className="block--search-col">
                    <div className="photos--lg-shimmer animate"></div>
                  </div>
                  <div className="block--search-col">
                    <div className="photos--lg-shimmer animate"></div>
                  </div>
                  <div className="block--search-col">
                    <div className="photos--lg-shimmer animate"></div>
                  </div>
                  <div className="block--search-col">
                    <div className="photos--lg-shimmer animate"></div>
                  </div>
                  <div className="block--search-col">
                    <div className="photos--lg-shimmer animate"></div>
                  </div>
                  <div className="block--search-col">
                    <div className="photos--lg-shimmer animate"></div>
                  </div>
                  <div className="block--search-col">
                    <div className="photos--lg-shimmer animate"></div>
                  </div>
                  <div className="block--search-col">
                    <div className="photos--lg-shimmer animate"></div>
                  </div>
                  <div className="block--search-col">
                    <div className="photos--lg-shimmer animate"></div>
                  </div>
                </div>
              </div>
              <span className="photos__control photos__control-next">
                <i className="icon-chevron-right"></i>
              </span>
            </div>
            <div className="sdl--combine shimmer--dc">
              <div className="row row--space-10 sdl--row align-items-center">
                <div className="col-12 col-lg-5 d-flex flex-column">
                  <div className="sdl--l text-center text-lg-left">
                    <span className="shimmer__heading-lg shimmer__style animate mb-5 mb-lg-0"></span>
                  </div>
                </div>
                <div className="col-12 col-lg-7 d-flex flex-column">
                  <div className="sdl--r text-center text-md-left">
                    <div className="dc--para-column d-flex flex-column mb-4">
                      <span className="para__md-gray shimmer__style animate"></span>
                      <span className="para__md-gray shimmer__style animate"></span>
                      <span className="para__md-gray shimmer__style animate"></span>
                      <span className="para__md-gray shimmer__style animate"></span>
                      <span className="para__md-gray shimmer__style animate"></span>
                    </div>
                    <div className="dc--para-column d-flex flex-column">
                      <span className="para__md-gray shimmer__style animate"></span>
                      <span className="para__md-gray shimmer__style animate"></span>
                      <span className="para__md-gray shimmer__style animate"></span>
                      <span className="para__md-gray shimmer__style animate"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sdl--combine shimmer--floorplan">
              <div className="row row--space-10 sdl--row align-items-center flex-lg-row-reverse">
                <div className="col-12 col-lg-5 d-flex flex-column align-items-lg-end">
                  <div className="sdl--l text-center text-lg-left">
                    <span className="shimmer__heading-lg shimmer__style animate mb-0"></span>
                  </div>
                </div>
                <div className="col-12 col-lg-7 d-flex flex-column">
                  <div className="sdl--r text-center text-lg-left">
                    <span className="floorplan--img shimmer__style animate"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="map--block map--block-shimmer text-center">
              <span className="shimmer__heading-lg shimmer__style animate mb-5"></span>
              <div className="map--box shimmer__style animate"></div>
            </div>
            <div className="sdl--combine shimmer--specs">
              <div className="row row--space-10 sdl--row align-items-center">
                <div className="col-12 col-lg-5 d-flex flex-column">
                  <div className="sdl--l text-center text-lg-left mb-20">
                    <span className="shimmer__heading-lg shimmer__style animate mb-4 mb-lg-0"></span>
                  </div>
                </div>
                <div className="col-12 col-lg-7 d-flex flex-column">
                  <div className="row row--space-10 justify-content-center justify-content-lg-start">
                    <div className="col col-12 col-sm-6 col-xl-4">
                      <div className="specs--box-shimmer">
                        <div className="specsBox__head">
                          <span className="specsBox__head-icon shimmer__style animate--gray"></span>
                          <span className="specsBox__head-text shimmer__style animate--gray"></span>
                        </div>
                        <span className="para__sm-gray animate--gray"></span>
                      </div>
                    </div>
                    <div className="col col-12 col-sm-6 col-xl-4">
                      <div className="specs--box-shimmer">
                        <div className="specsBox__head">
                          <span className="specsBox__head-icon shimmer__style animate--gray"></span>
                          <span className="specsBox__head-text shimmer__style animate--gray"></span>
                        </div>
                        <span className="para__sm-gray animate--gray"></span>
                      </div>
                    </div>
                    <div className="col col-12 col-sm-6 col-xl-4">
                      <div className="specs--box-shimmer">
                        <div className="specsBox__head">
                          <span className="specsBox__head-icon shimmer__style animate--gray"></span>
                          <span className="specsBox__head-text shimmer__style animate--gray"></span>
                        </div>
                        <span className="para__sm-gray animate--gray"></span>
                      </div>
                    </div>
                    <div className="col col-12 col-sm-6 col-xl-4">
                      <div className="specs--box-shimmer">
                        <div className="specsBox__head">
                          <span className="specsBox__head-icon shimmer__style animate--gray"></span>
                          <span className="specsBox__head-text shimmer__style animate--gray"></span>
                        </div>
                        <span className="para__sm-gray animate--gray"></span>
                      </div>
                    </div>
                    <div className="col col-12 col-sm-6 col-xl-4">
                      <div className="specs--box-shimmer">
                        <div className="specsBox__head">
                          <span className="specsBox__head-icon shimmer__style animate--gray"></span>
                          <span className="specsBox__head-text shimmer__style animate--gray"></span>
                        </div>
                        <span className="para__sm-gray animate--gray"></span>
                      </div>
                    </div>
                    <div className="col col-12 col-sm-6 col-xl-4">
                      <div className="specs--box-shimmer">
                        <div className="specsBox__head">
                          <span className="specsBox__head-icon shimmer__style animate--gray"></span>
                          <span className="specsBox__head-text shimmer__style animate--gray"></span>
                        </div>
                        <span className="para__sm-gray animate--gray"></span>
                      </div>
                    </div>
                    <div className="col col-12 col-sm-6 col-xl-4">
                      <div className="specs--box-shimmer">
                        <div className="specsBox__head">
                          <span className="specsBox__head-icon shimmer__style animate--gray"></span>
                          <span className="specsBox__head-text shimmer__style animate--gray"></span>
                        </div>
                        <span className="para__sm-gray animate--gray"></span>
                      </div>
                    </div>
                    <div className="col col-12 col-sm-6 col-xl-4">
                      <div className="specs--box-shimmer">
                        <div className="specsBox__head">
                          <span className="specsBox__head-icon shimmer__style animate--gray"></span>
                          <span className="specsBox__head-text shimmer__style animate--gray"></span>
                        </div>
                        <span className="para__sm-gray animate--gray"></span>
                      </div>
                    </div>
                    <div className="col col-12 col-sm-6 col-xl-4">
                      <div className="specs--box-shimmer">
                        <div className="specsBox__head">
                          <span className="specsBox__head-icon shimmer__style animate--gray"></span>
                          <span className="specsBox__head-text shimmer__style animate--gray"></span>
                        </div>
                        <span className="para__sm-gray animate--gray"></span>
                      </div>
                    </div>
                    <div className="col col-12 col-sm-6 col-xl-4">
                      <div className="specs--box-shimmer">
                        <div className="specsBox__head">
                          <span className="specsBox__head-icon shimmer__style animate--gray"></span>
                          <span className="specsBox__head-text shimmer__style animate--gray"></span>
                        </div>
                        <span className="para__sm-gray animate--gray"></span>
                      </div>
                    </div>
                    <div className="col col-12 col-sm-6 col-xl-4">
                      <div className="specs--box-shimmer">
                        <div className="specsBox__head">
                          <span className="specsBox__head-icon shimmer__style animate--gray"></span>
                          <span className="specsBox__head-text shimmer__style animate--gray"></span>
                        </div>
                        <span className="para__sm-gray animate--gray"></span>
                      </div>
                    </div>
                    <div className="col col-12 col-sm-6 col-xl-4">
                      <div className="specs--box-shimmer">
                        <div className="specsBox__head">
                          <span className="specsBox__head-icon shimmer__style animate--gray"></span>
                          <span className="specsBox__head-text shimmer__style animate--gray"></span>
                        </div>
                        <span className="para__sm-gray animate--gray"></span>
                      </div>
                    </div>
                    <div className="col col-12 col-sm-6 col-xl-4">
                      <div className="specs--box-shimmer">
                        <div className="specsBox__head">
                          <span className="specsBox__head-icon shimmer__style animate--gray"></span>
                          <span className="specsBox__head-text shimmer__style animate--gray"></span>
                        </div>
                        <span className="para__sm-gray animate--gray"></span>
                      </div>
                    </div>
                    <div className="col col-12 col-sm-6 col-xl-4">
                      <div className="specs--box-shimmer specsBox--img-shimmer">
                        <span className="specsBox__head-img shimmer__style animate--gray"></span>
                        <div className="para--column d-flex flex-column">
                          <span className="para__sm-gray animate--gray"></span>
                          <span className="para__sm-gray animate--gray"></span>
                        </div>
                      </div>
                    </div>
                    <div className="col col-12 col-sm-6 col-xl-4">
                      <div className="specs--box-shimmer specsBox--img-shimmer">
                        <span className="specsBox__head-img shimmer__style animate--gray"></span>
                        <div className="para--column d-flex flex-column">
                          <span className="para__sm-gray animate--gray"></span>
                          <span className="para__sm-gray animate--gray"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sdl--combine amenities--combine">
              <div className="row row--space-10 sdl--row align-items-center flex-lg-row-reverse">
                <div className="col-12 col-lg-5 d-flex flex-column align-items-lg-end">
                  <div className="sdl--l text-center text-lg-left mb-20">
                    <span className="shimmer__heading-lg shimmer__style animate mb-4 mb-lg-0"></span>
                  </div>
                </div>
                <div className="col-12 col-lg-7 d-flex flex-column">
                  <div className="row row--space-10 justify-content-center justify-content-lg-start">
                    <div className="col col-12 col-sm-6">
                      <div className="specs--box-shimmer specsBox--shimmer-alt">
                        <div className="specsBox__head">
                          <span className="para__sm-gray my-auto shimmer__style animate--gray"></span>
                        </div>
                      </div>
                    </div>
                    <div className="col col-12 col-sm-6">
                      <div className="specs--box-shimmer specsBox--shimmer-alt">
                        <div className="specsBox__head">
                          <span className="para__sm-gray my-auto shimmer__style animate--gray"></span>
                        </div>
                      </div>
                    </div>
                    <div className="col col-12 col-sm-6">
                      <div className="specs--box-shimmer specsBox--shimmer-alt">
                        <div className="specsBox__head">
                          <span className="para__sm-gray my-auto shimmer__style animate--gray"></span>
                        </div>
                      </div>
                    </div>
                    <div className="col col-12 col-sm-6">
                      <div className="specs--box-shimmer specsBox--shimmer-alt">
                        <div className="specsBox__head">
                          <span className="para__sm-gray my-auto shimmer__style animate--gray"></span>
                        </div>
                      </div>
                    </div>
                    <div className="col col-12 col-sm-6">
                      <div className="specs--box-shimmer specsBox--shimmer-alt">
                        <div className="specsBox__head">
                          <span className="para__sm-gray my-auto shimmer__style animate--gray"></span>
                        </div>
                      </div>
                    </div>
                    <div className="col col-12 col-sm-6">
                      <div className="specs--box-shimmer specsBox--shimmer-alt">
                        <div className="specsBox__head">
                          <span className="para__sm-gray my-auto shimmer__style animate--gray"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sdl--combine shimmer--specs">
              <div className="row row--space-10 sdl--row align-items-center">
                <div className="col-12 col-lg-5 d-flex flex-column">
                  <div className="sdl--l text-center text-lg-left mb-20 mb-md-4 pb-md-4">
                    <span className="shimmer__heading-lg shimmer__style animate mb-4 mb-lg-0"></span>
                  </div>
                </div>
                <div className="col-12 col-lg-7 d-flex flex-column">
                  <div className="row row--space-10">
                    <div className="col col-6 col-md-4 col-lg-6 col-xl-4">
                      <div className="subTours--block-shimmer">
                        <div className="subTours--video-shimmer shimmer__style animate"></div>
                        <span className="para__sm-gray shimmer__style animate"></span>
                      </div>
                    </div>
                    <div className="col col-6 col-md-4 col-lg-6 col-xl-4">
                      <div className="subTours--block-shimmer">
                        <div className="subTours--video-shimmer shimmer__style animate"></div>
                        <span className="para__sm-gray shimmer__style animate"></span>
                      </div>
                    </div>
                    <div className="col col-6 col-md-4 col-lg-6 col-xl-4">
                      <div className="subTours--block-shimmer">
                        <div className="subTours--video-shimmer shimmer__style animate"></div>
                        <span className="para__sm-gray shimmer__style animate"></span>
                      </div>
                    </div>
                    <div className="col col-6 col-md-4 col-lg-6 col-xl-4">
                      <div className="subTours--block-shimmer">
                        <div className="subTours--video-shimmer shimmer__style animate"></div>
                        <span className="para__sm-gray shimmer__style animate"></span>
                      </div>
                    </div>
                    <div className="col col-6 col-md-4 col-lg-6 col-xl-4">
                      <div className="subTours--block-shimmer">
                        <div className="subTours--video-shimmer shimmer__style animate"></div>
                        <span className="para__sm-gray shimmer__style animate"></span>
                      </div>
                    </div>
                    <div className="col col-6 col-md-4 col-lg-6 col-xl-4">
                      <div className="subTours--block-shimmer">
                        <div className="subTours--video-shimmer shimmer__style animate"></div>
                        <span className="para__sm-gray shimmer__style animate"></span>
                      </div>
                    </div>
                    <div className="col col-6 col-md-4 col-lg-6 col-xl-4">
                      <div className="subTours--block-shimmer">
                        <div className="subTours--video-shimmer shimmer__style animate"></div>
                        <span className="para__sm-gray shimmer__style animate"></span>
                      </div>
                    </div>
                    <div className="col col-6 col-md-4 col-lg-6 col-xl-4">
                      <div className="subTours--block-shimmer">
                        <div className="subTours--video-shimmer shimmer__style animate"></div>
                        <span className="para__sm-gray shimmer__style animate"></span>
                      </div>
                    </div>
                    <div className="col col-6 col-md-4 col-lg-6 col-xl-4">
                      <div className="subTours--block-shimmer">
                        <div className="subTours--video-shimmer shimmer__style animate"></div>
                        <span className="para__sm-gray shimmer__style animate"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="subdomain__footer subdomain__footer-shimmer">
        <div className="container container--large">
          <div className="row justify-content-md-between">
            <div className="col-12 col-sm-5 col-lg-5">
              <div className="sd__footer-l">
                <span className="header__logo-shimmer shimmer__style animate mb-4"></span>
                <span className="sdf__para-shimmer shimmer__style animate"></span>
              </div>
            </div>
            <div className="col-12 col-sm-7 col-lg-5">
              <div className="fl--shimmer mb-4 mb-md-5">
                <span className="fl--shimmer-span shimmer__style animate"></span>
                <span className="fl--shimmer-span shimmer__style animate"></span>
                <span className="fl--shimmer-span shimmer__style animate"></span>
              </div>
              <div className="fp__box mb-0 mb-sm-5 pb-sm-1">
                <span className="fp__box-span shimmer__style animate"></span>
                <span className="fp__box-span shimmer__style animate"></span>
                <span className="fp__box-span shimmer__style animate"></span>
                <span className="fp__box-span shimmer__style animate"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SingleListingShimmer;
