import { GetState } from '../../types/GetStateInterface';
import { BuildingActions, BuildingStateInterface } from './building.types';

const defaultState: BuildingStateInterface = {
  allBuildings: GetState,
  singleBuilding: null,
};

export default function BuildingReducer(state = defaultState, action: any) {
  switch (action.type) {
    case BuildingActions.GET_ALL_BUILDINGS_INIT:
      return {
        ...state,
        allBuildings: {
          ...state.allBuildings,
          loading: true,
          error: null,
        },
      };

    case BuildingActions.GET_ALL_BUILDINGS_FAILURE:
      return {
        ...state,
        allBuildings: {
          ...state.allBuildings,
          loading: false,
          error: action.payload,
          hasData: true,
        },
      };

    case BuildingActions.GET_ALL_BUILDINGS_SUCCESSFUL:
      return {
        ...state,
        allBuildings: {
          ...state.allBuildings,
          loading: false,
          error: null,
          data: action.payload,
          hasData: true,
        },
      };

    case BuildingActions.GET_ALL_BUILDINGS_CLEAR:
      return {
        ...state,
        allBuildings: {
          ...state.allBuildings,
          loading: false,
          error: null,
          hasData: true,
          data: null,
        },
      };

    case BuildingActions.UPDATE_SINGLE_BUILDING:
      return {
        ...state,
        singleBuilding: action.payload,
      };

    default:
      return state;
  }
}
