import React, { useRef, useCallback, useEffect } from 'react';
import './index.css';

interface DropdownOption {
  label: string;
  value: any;
}

interface Props {
  options: DropdownOption[];
  onChange: (option: DropdownOption) => void;
  show: boolean;
  toggle: () => void;
}

const Dropdown: React.FC<Props> = ({ onChange, options, show, toggle }) => {
  const ref = useRef<HTMLDivElement>(null);
  const mouseEventListener = useCallback(
    (event: MouseEvent) => {
      const node = event?.target as Node;
      if (show && !ref.current?.contains(node)) toggle();
    },
    [show, toggle],
  );

  useEffect(() => {
    document.addEventListener('mousedown', mouseEventListener);
    return () => document.removeEventListener('mousedown', mouseEventListener);
  }, [toggle]);

  const handleSelect = (option: DropdownOption) => {
    onChange(option);
    toggle();
  };

  if (!show) return null;
  return (
    <div className="dropdown-items-container" ref={ref}>
      {options.map(({ label, value }, optionIndex) => (
        <>
          <span
            key={optionIndex}
            className="dropdown-item"
            onClick={() => handleSelect({ label, value })}
          >
            {label}
          </span>
          {optionIndex < options.length - 1 && <hr className="dropdown-item-hr" />}
        </>
      ))}
    </div>
  );
};

export default Dropdown;
