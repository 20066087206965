import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSubdomainBuilding } from '../../../../../state/subdomain/building/building.selector';
import BuildingFeaturesGrid from './BuildingFeaturesGrid';
import ContactBoard from './ContactBoard';
import Tabs from './Tabs';
import './index.css';
import Units from './Units';
import { WEBSITE_TABS } from './website.types';
import ClassicGallery from './ClassicGallery';
import ClassicHeadline from './ClassicHeadline';
import BuildingCommonSection from './BuildingCommonSection';
import { getPublishedUnits } from '../../../../../utils/misc.utils';

const ClassicTemplate = () => {
  const [activeIndex, setActiveIndex] = useState(WEBSITE_TABS.ABOUT_SECTION);
  const building = useSelector(selectSubdomainBuilding());
  return (
    <>
      <ClassicHeadline />
      <div className="mt-40 website-padding content-container">
        <div className="website-tabs-container">
          <Tabs
            onChange={setActiveIndex}
            tabs={['About this building', `Units (${getPublishedUnits(building?.listings) || 0})`]}
            activeIndex={activeIndex}
          />
          {activeIndex === WEBSITE_TABS.ABOUT_SECTION ? (
            <>
              <BuildingFeaturesGrid />
              <ClassicGallery />
              <BuildingCommonSection />
            </>
          ) : null}
          {activeIndex === WEBSITE_TABS.UNITS_SECTION ? <Units /> : null}
        </div>
        <ContactBoard />
      </div>
    </>
  );
};

export default ClassicTemplate;
