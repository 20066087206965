import React from 'react';
import { useSelector } from 'react-redux';
import { selectSubdomainBuilding } from '../../../../../../state/subdomain/building/building.selector';
import images from '../../../../../../utils/img.utils';
import './index.css';

const BasicBuildingName = () => {
  const building = useSelector(selectSubdomainBuilding());
  return (
    <div className="basic-building-name-container">
      <span className="basic-building-name">{building?.name}</span>
      <div className="basic-building-location-container">
        <img src={images.TemplateLocation} />

        <a
          href={`https://www.google.com/maps/place/${building?.location}`}
          className="basic-building-location"
          target="_blank"
        >
          {building?.location}
        </a>
      </div>
    </div>
  );
};

export default BasicBuildingName;
