import 'react-image-lightbox/style.css';
import React, { useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import { compileAvatarUrl } from '../../utils/avatar.utils';
import { UploadedMediaInterface } from '../../state/media/media.types';
import { DEFAULT_MAX_IMG_HEIGHT, DEFAULT_MAX_IMG_WIDTH } from '../../common/constants';

interface Props {
  data: UploadedMediaInterface[];
  index: number;
  close: (currentImage: UploadedMediaInterface, currentIndex: number) => void;
  showTitles?: boolean;
  onImageChange?: (
    prevImage: UploadedMediaInterface | null,
    currentImage: UploadedMediaInterface,
    currentIndex: number,
  ) => void;
}

export const AttachmentLightBox: React.FC<Props> = props => {
  const { showTitles, close, onImageChange } = props;
  const [photoIndex, setPhotoIndex] = useState(0);
  const [lastPhotoIndex, setLastPhotoIndex] = useState<number | undefined>();
  const [images, setImages] = useState([] as string[]);
  const [imageTitles, setImageTitles] = useState([] as string[]);
  useEffect(() => {
    setPhotoIndex(props.index);
    if (props.data.length) {
      const data = [] as string[];
      const dataTitle = [] as string[];
      for (const i in props.data) {
        data.push(
          compileAvatarUrl(props.data[i].url, {
            width: Math.min(DEFAULT_MAX_IMG_WIDTH, window.innerWidth),
            height: Math.min(DEFAULT_MAX_IMG_HEIGHT, window.innerHeight),
          }),
        );
        dataTitle.push(props.data[i].name);
      }
      setImages(data);
      setImageTitles(dataTitle);
    }
  }, [props.data]);

  useEffect(() => {
    if (onImageChange) {
      onImageChange(
        lastPhotoIndex || lastPhotoIndex === 0 ? props.data[lastPhotoIndex] : null,
        props.data[photoIndex],
        photoIndex,
      );
    }
  }, [lastPhotoIndex]);

  useEffect(() => {
    return () => {
      setLastPhotoIndex(photoIndex);
    };
  }, [photoIndex]);

  return (
    <React.Fragment>
      {images.length > 0 && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images.length > 1 ? images[(photoIndex + 1) % images.length] : undefined}
          prevSrc={
            images.length > 1 ? images[(photoIndex + images.length - 1) % images.length] : undefined
          }
          onCloseRequest={() => close(props.data[photoIndex], photoIndex)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          imageTitle={showTitles ? imageTitles[photoIndex] : ''}
        />
      )}
    </React.Fragment>
  );
};
