import { ProductActions, ProductStateInterface } from './product.types';
import { GetState } from '../../types/GetStateInterface';

const defaultState: ProductStateInterface = {
  product: GetState,
};

export default function ProductReducer(state = defaultState, action: any) {
  switch (action.type) {
    case ProductActions.PRODUCT_INIT:
      return {
        ...state,
        product: {
          ...state.product,
          loading: true,
          error: null,
          data: null,
          hasData: false,
        },
      };
    case ProductActions.PRODUCT_FAILURE:
      return {
        ...state,
        product: {
          ...state.product,
          loading: false,
          error: action.payload,
          hasData: true,
          data: null,
        },
      };
    case ProductActions.GET_SINGLE_PRICE:
      return {
        ...state,
        product: {
          ...state.product,
          loading: false,
          error: null,
          data: action.payload,
          hasData: true,
        },
      };
    default:
      return state;
  }
}
