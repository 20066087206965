import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSubdomainBuilding } from '../../../../../../state/subdomain/building/building.selector';
import ImgTile from '../../../../../media/imgTile';
import './index.css';
import Images from '../../../../../../utils/img.utils';
import Button from '../Button';
import TourRequestForm from '../../../../../subdomain/listing/listDetail/requestForm';
import TourContactForm from '../../../../../subdomain/listing/listDetail/contactForm';

const ClassicHeadline = () => {
  const [requestForm, setRequestForm] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);
  const tourRequestHandler = () => {
    setRequestForm(true);
  };

  const contactInfoHandler = () => {
    setShowContactForm(true);
  };
  const building = useSelector(selectSubdomainBuilding());
  const primaryMedia = building?.media.find(media => media.isPrimary);
  if (!primaryMedia) return null;
  return (
    <div className="headline-container website-padding">
      <ImgTile src={primaryMedia.url} className="headline-image" />
      <div className="headline-image-overlay" />
      <div className="headline-content-container">
        <span className="headline-title">{building?.name}</span>
        <img className="mr-10" src={Images.TemplateLocationWhite} />
        <span className="headline-subtitle">{building?.location}</span>
        <div className="mt-20 headline-action-container">
          <Button size="lg" variant="white" onClick={tourRequestHandler}>
            Request tour
          </Button>
          <Button size="lg" variant="white-outline" onClick={contactInfoHandler}>
            Request information
          </Button>
        </div>
      </div>
      {requestForm && (
        <TourRequestForm
          buildingId={building?._id}
          hideForm={() => {
            setRequestForm(false);
          }}
        />
      )}
      {showContactForm && <TourContactForm hideForm={() => setShowContactForm(false)} />}
    </div>
  );
};

export default ClassicHeadline;
