import React from 'react';
import { useSelector } from 'react-redux';
import { useToggle } from '../../../../../../hooks';
import { selectSubdomainBuilding } from '../../../../../../state/subdomain/building/building.selector';
import ImgTile from '../../../../../media/imgTile';
import ImagesLightbox from '../ImagesLightbox';
import './index.css';

const BasicGallery = () => {
  const [show, toggle] = useToggle();
  const building = useSelector(selectSubdomainBuilding());
  const images = building?.media || [];
  const primary = images.find(({ isPrimary }) => isPrimary);
  const secondaryImages = images.filter(({ isPrimary }) => !isPrimary);

  const gridImages = [];
  gridImages.push(secondaryImages.slice(0, 2));
  gridImages.push(secondaryImages.slice(2, 4));

  return (
    <>
      <ImagesLightbox show={show} toggle={toggle} images={images} />
      <div className="basic-gallery-container">
        <div className="basic-gallery-primary-image-container">
          <ImgTile className="basic-gallery-image" src={primary?.url} />
        </div>

        {images.length > 1 ? (
          <div className="basic-gallery-secondary-images-container">
            {gridImages.map((imagesCol, colIndex) => {
              if (!imagesCol.length) return null;
              return (
                <div className="basic-gallery-secondary-images-col">
                  {imagesCol.map((image, imageIndex) => (
                    <div className="basic-gallery-secondary-image-container">
                      <ImgTile className="basic-gallery-image" src={image.url} />
                      {images.length > 5 && colIndex === 1 && imageIndex === 1 ? (
                        <div onClick={toggle} className="basic-image-overlay">
                          See {images.length - 5} more
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default BasicGallery;
