import React from 'react';
import './index.css';
import cx from 'classnames';

interface Props {
  tabs: string[];
  activeIndex: number;
  onChange?: (activeIndex: number) => void;
}

const Tabs: React.FC<Props> = ({ tabs, activeIndex, onChange = () => null }) => {
  return (
    <div className="tabs-container">
      {tabs.map((tab, tabIndex) => {
        const itemClassName = cx('tab-item', { 'tab-item-active': activeIndex === tabIndex });
        return (
          <span key={tabIndex} onClick={() => onChange(tabIndex)} className={itemClassName}>
            {tab}
          </span>
        );
      })}
    </div>
  );
};

export default Tabs;
