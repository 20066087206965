import DOMPurify from 'dompurify';
import Quill, { Sources } from 'quill';

export const Delta = Quill.import('delta');
export const Clipboard = Quill.import('modules/clipboard');
export const Parchment = Quill.import('parchment');
export const Block = Quill.import('blots/block');
export const multiplier = 2.5;
export const levels = [1, 2, 3, 4, 5, 6, 7, 8];

export class QuillEnhancers extends Clipboard {
  static keepSelection = false;
  static allowed = [];

  constructor(quill: Quill, options: any) {
    super(quill, options);

    this.allowed = options.allowed;
    this.keepSelection = options.keepSelection;
  }

  onPaste(e: any) {
    e.preventDefault();
    const range = this.quill.getSelection();

    const text = e.clipboardData.getData('text/plain');
    const html = e.clipboardData.getData('text/html');

    let delta = new Delta().retain(range.index).delete(range.length);

    let content = text;
    if (html) {
      const allowed = this.getAllowed();
      content = DOMPurify.sanitize(html, allowed);
      delta = delta.concat(this.convert(content));
    } else {
      delta = delta.insert(content);
    }

    this.quill.updateContents(delta, 'user');

    // move cursor
    delta = this.convert(content);
    if (this.keepSelection) this.quill.setSelection(range.index, delta.length(), 'silent');
    else this.quill.setSelection(range.index + delta.length(), 'silent');

    this.quill.scrollIntoView();
  }

  getAllowed() {
    const tidy: any = {};

    if (this.allowed && this.allowed.tags) tidy.ALLOWED_TAGS = this.allowed.tags;
    if (this.allowed && this.allowed.attributes) tidy.ALLOWED_ATTR = this.allowed.attributes;

    if (tidy.ALLOWED_TAGS === undefined || tidy.ALLOWED_ATTR === undefined) {
      let undefinedTags = false;
      if (tidy.ALLOWED_TAGS === undefined) {
        undefinedTags = true;
        tidy.ALLOWED_TAGS = ['p', 'br', 'span'];
      }

      let undefinedAttr = false;
      if (tidy.ALLOWED_ATTR === undefined) {
        undefinedAttr = true;
        tidy.ALLOWED_ATTR = ['class'];
      }

      const toolbar = this.quill.getModule('toolbar');
      toolbar.controls.forEach((control: any) => {
        switch (control[0]) {
          case 'bold':
            if (undefinedTags) {
              tidy.ALLOWED_TAGS.push('b');
              tidy.ALLOWED_TAGS.push('strong');
            }
            break;

          case 'italic':
            if (undefinedTags) {
              tidy.ALLOWED_TAGS.push('i');
            }
            break;

          case 'underline':
            if (undefinedTags) {
              tidy.ALLOWED_TAGS.push('u');
            }
            break;

          case 'strike':
            if (undefinedTags) {
              tidy.ALLOWED_TAGS.push('s');
            }
            break;

          case 'color':
          case 'background':
            if (undefinedAttr) {
              tidy.ALLOWED_ATTR.push('style');
            }
            break;

          case 'script':
            if (undefinedTags) {
              if (control[1].value === 'super') {
                tidy.ALLOWED_TAGS.push('sup');
              } else if (control[1].value === 'sub') {
                tidy.ALLOWED_TAGS.push('sub');
              }
            }
            break;

          case 'header':
            if (undefinedTags) {
              const detectAllowedHeadingTag = (value: string) => {
                if (value === '1') {
                  tidy.ALLOWED_TAGS.push('h1');
                } else if (value === '2') {
                  tidy.ALLOWED_TAGS.push('h2');
                } else if (value === '3') {
                  tidy.ALLOWED_TAGS.push('h3');
                } else if (value === '4') {
                  tidy.ALLOWED_TAGS.push('h4');
                } else if (value === '5') {
                  tidy.ALLOWED_TAGS.push('h5');
                } else if (value === '6') {
                  tidy.ALLOWED_TAGS.push('h6');
                }
              };

              if (control[1].value) detectAllowedHeadingTag(control[1].value);
              else if (control[1].options && control[1].options.length) {
                [].forEach.call(control[1].options, (option: any) => {
                  if (option.value) detectAllowedHeadingTag(option.value);
                });
              }
            }
            break;

          case 'code-block':
            if (undefinedTags) {
              tidy.ALLOWED_TAGS.push('pre');
            }
            if (undefinedAttr) {
              tidy.ALLOWED_ATTR.push('spellcheck');
            }
            break;

          case 'list':
            if (undefinedTags) {
              if (control[1].value === 'ordered') {
                tidy.ALLOWED_TAGS.push('ol');
              } else if (control[1].value === 'bullet') {
                tidy.ALLOWED_TAGS.push('ul');
              }
              tidy.ALLOWED_TAGS.push('li');
            }
            break;

          case 'link':
            if (undefinedTags) {
              tidy.ALLOWED_TAGS.push('a');
            }
            if (undefinedAttr) {
              tidy.ALLOWED_ATTR.push('href');
              tidy.ALLOWED_ATTR.push('target');
              tidy.ALLOWED_ATTR.push('rel');
            }
            break;

          case 'image':
            if (undefinedTags) {
              tidy.ALLOWED_TAGS.push('img');
            }
            if (undefinedAttr) {
              tidy.ALLOWED_ATTR.push('src');
              tidy.ALLOWED_ATTR.push('title');
              tidy.ALLOWED_ATTR.push('alt');
            }
            break;

          case 'video':
            if (undefinedTags) {
              tidy.ALLOWED_TAGS.push('iframe');
            }
            if (undefinedAttr) {
              tidy.ALLOWED_ATTR.push('frameborder');
              tidy.ALLOWED_ATTR.push('allowfullscreen');
              tidy.ALLOWED_ATTR.push('src');
            }
            break;

          case 'blockquote':
            if (undefinedTags) {
              tidy.ALLOWED_TAGS.push(control[0]);
            }
            break;
        }
      });
    }

    return tidy;
  }
}

class IndentAttributor extends Parchment.Attributor.Style {
  constructor(name: string, style: string, params: any) {
    super(name, style, params);
  }

  add(node: any, value: number) {
    return super.add(node, `${value * multiplier}rem`);
  }

  value(node: any) {
    return parseFloat(super.value(node)) / multiplier || undefined;
  }
}

export const IndentStyle = new IndentAttributor('indent', 'margin-left', {
  scope: Parchment.Scope.BLOCK,
  whitelist: levels.map(value => `${value * multiplier}rem`),
});
