import { TOUR_TYPES, TOUR_CONTROLLER_TYPE } from '../../common/constants';
import { UploadedMediaInterface } from '../media/media.types';
import { GetStateInterface } from '../../types/GetStateInterface';
import { ListingInterface, PhotoInterface, User } from '../listing/listing.types';
import { UsersInterface } from '../users/users.types';
import { SubMarketInterface } from '../submarket/submarket.types';
import { SingleListingInterfaceNew } from '../building/building.types';

export enum TourActions {
  GET_ALL_TOURS = 'GET_ALL_TOURS',
  GET_SINGLE_TOUR = 'GET_SINGLE_TOUR',
  GET_SINGLE_TOUR_INIT = 'GET_SINGLE_TOUR_INIT',
  GET_SINGLE_TOUR_SUCCESSFUL = 'GET_SINGLE_TOUR_SUCCESSFUL',
  GET_SINGLE_TOUR_FAILURE = 'GET_SINGLE_TOUR_FAILURE',
  CHANGE_EDIT_TOUR_LISTING = 'CHANGE_EDIT_TOUR_LISTING',
  CHANGE_EDIT_TOUR_NEIGHBOUR = 'CHANGE_EDIT_TOUR_NEIGHBOUR',
  DELETE_SINGLE_TOUR = 'DELETE_SINGLE_TOUR',
  CHANGE_TOUR_TYPE = 'CHANGE_TOUR_TYPE',
  CHANGE_LISTING_IMAGES = 'CHANGE_LISTING_IMAGES',
  CHANGE_NEIGHBOUR_IMAGES = 'CHANGE_NEIGHBOUR_IMAGES',
  CHANGE_LISTING_PLANS = 'CHANGE_LISTING_PLANS',
  CHANGE_LISTING_PDFS = 'CHANGE_LISTING_PDFS',
  CHANGE_TOUR_LISTINGS = 'CHANGE_TOUR_LISTINGS',
  CHANGE_TOUR_USERS = 'CHANGE_TOUR_USERS',
  GET_ALL_NEIGHBOURS = 'GET_ALL_NEIGHBOURS',
  CREATE_NEIGHBOUR = 'CREATE_NEIGHBOUR',
  CHANGE_TOUR_INFO = 'CHANGE_TOUR_INFO',
  CHANGE_TOUR_CLIENT_INFO = 'CHANGE_TOUR_CLIENT_INFO',
}

export type TourSortBy = 'updatedAt' | 'createdAt' | 'tourType';

export type TourOrderBy = 'ascending' | 'descending';

export interface TourSortAndOrder {
  sortBy?: TourSortBy;
  orderBy?: TourOrderBy;
}

export interface GetAllToursQueryInterface extends TourSortAndOrder {
  subMarket?: string;
  search?: string;
  active?: 0 | 1;
  limit?: number;
  skip?: number;
}

export interface SubClientInterface {
  firstName?: string;
  lastName?: string;
  email?: string;
  company?: string;
  brokerCompany?: string;
  phone?: string;
  time?: string;
  questions?: string;
}

export interface RoomBuildingInterface {
  location: string;
}

export interface RoomListingsInterface {
  suiteNo: string;
  building: RoomBuildingInterface;
  location?: string;
  name?: string;
}

export interface GetAllToursDataInterface {
  _id: string;
  tourType: TOUR_TYPES.LIVE | TOUR_TYPES.PRESENT | TOUR_TYPES.BUILDING;
  listings: RoomListingsInterface[];
  clientInfo?: SubClientInterface;
  tourUrl?: string;
  expiredAt: number;
  createdBy: UsersInterface;
  createdAt: string;
}

export interface GetAllToursResponseInterface {
  hasMore: boolean;
  skip: number;
  data: GetAllToursDataInterface[];
}

export enum TOUR_SWITCHES {
  DETAILS = 'DETAILS',
  LOCATION = 'LOCATION',
  GALLERY = 'GALLERY',
  FLOOR_PLANS = 'FLOOR_PLANS',
  TOUR = 'TOUR',
  SPECS = 'SPECS',
  PDFS = 'PDFS',
}

export interface TourStateInterface {
  tourInfo: GetStateInterface<GetTourInfo>;
  tourListing: ListingInfo | null;
  tourNeighbour: TourNeighborhood | null;
  changeTour: boolean;
}

export interface GetTourInfo {
  data: any;
  _id: string;
  additionalUsers: AdditionalUser[];
  isActive: boolean;
  dailyCoRoom: any;
  listings: ListingInfo[];
  neighborhoods?: TourNeighborhood[];
  clientInfo?: ClientInfo;
  tourType: TOUR_TYPES;
  expiredAt: number;
  company: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  tourUrl?: string;
  __v: number;
}

export interface ClientInfo {
  _id?: string;
  company?: string;
  companyLogo?: UploadedMediaInterface | null;
}

export interface ListingInfo {
  _id: string;
  listing: SingleListingInterfaceNew;
  additionalMedia: UploadedMediaInterface[];
  additionalFloorPlans: UploadedMediaInterface[];
  additionalPdfs: UploadedMediaInterface[];
  createdAt?: string;
  updatedAt?: string;
}

interface LocationBoundary {
  lat: string;
  lng: string;
}

export interface TourListingInterface {
  _id: string;
  videoUrls?: VideoUrls[];
  videoStatus: string;
  isActive: boolean;
  status: string;
  createdBy: string;
  available: string;
  media: Media[];
  pdfs: UploadedMediaInterface[];
  videoDescription: string;
  matterPortLinks: Media[];
  description: string;
  metroProximity: string;
  condition: string;
  capacity: string;
  monthlyRent?: number;
  size: number;
  suiteNo: string;
  spaceUse: string;
  building: TourBuildingInterface;
  createdAt: string;
  updatedAt: string;
  __v: number;
  floorPlans: UploadedMediaInterface[];
  availableVisibility: boolean;
  capacityVisibility: boolean;
  conditionVisibility: boolean;
  descriptionVisibility: boolean;
  floorPlansVisibility: boolean;
  metroProximityVisibility: boolean;
  monthlyRentVisibility: boolean;
  sizeVisibility: boolean;
  spaceUseVisibility: boolean;
  suiteNoVisibility: boolean;
}

export interface TourNeighborhood {
  _id: string;
  neighborhood: TourNeighborhoodInterface;
  media: UploadedMediaInterface[];
  createdAt: string;
  updatedAt: string;
  role: string;
}

export interface TourNeighborhoodInterface {
  _id: string;
  name: string;
  centrePoint: LocationBoundary;
  zoomLevel: number;
  locationBoundaries: LocationBoundary[];
  market: string;
  __v: number;
}

export interface BuildingValueInNumber {
  value: number;
  visibility: boolean;
}

export interface BuildingValueInString {
  value: string;
  visibility: boolean;
}

export interface TourBuildingInterface {
  _id: string;
  buildingHours: BuildingValueInString[];
  buildingAmenities: BuildingValueInString[];
  isActive: boolean;
  location: string;
  longitude: BuildingValueInString;
  latitude: BuildingValueInString;
  media: Media[];
  floorPlans: UploadedMediaInterface[];
  fullLocation: any;
  subMarket: SubMarketInterface;
  yearBuilt: BuildingValueInNumber;
  yearRenovated: BuildingValueInNumber;
  floorsCount: BuildingValueInNumber;
  buildingSize: BuildingValueInNumber;
  averageFloor: BuildingValueInNumber;
  parkingRatio: BuildingValueInString;
  columnSpacingHeight: BuildingValueInNumber;
  columnSpacingWidth: BuildingValueInNumber;
  passengerElevators: BuildingValueInString;
  freightElevators: BuildingValueInString;
  telecomProviders: BuildingValueInString;
  leedCertification: BuildingValueInString;
  energyStarCertification: BuildingValueInString;
  company: BuildingValueInString;
  createdAt: BuildingValueInString;
  updatedAt: BuildingValueInString;
  __v: number;
  buildingAmenitiesMatterportLinks: UploadedMediaInterface[];
  other?: BuildingValueInString;
  slabToSlabHeight: BuildingValueInString;
  website?: BuildingValueInString;
}

export interface Media extends UploadedMediaInterface {
  isPrimary: boolean;
}

export interface TourMedia extends UploadedMediaInterface {
  isPrimary?: boolean;
  smallName?: string;
}

export interface VideoUrls extends PhotoInterface {
  uploadedByStaff: boolean;
}

export interface AdditionalUser {
  _id: string;
  avatar: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface EditTourListingMedia {
  listingId: string;
  roomId: string;
  media: UploadedMediaInterface[];
  floorPlans: UploadedMediaInterface[];
  pdfs: UploadedMediaInterface[];
}

export interface EditTourNeighbourMedia {
  neighborhoodId: string;
  roomId: string;
  media: UploadedMediaInterface[];
}

export interface TourHomeInterface {
  id: string;
  name: string;
  // url: string;
  location: BuildingLocation;
  listings: TourHomeListings[];
}
export interface TourHomeListings {
  id: string;
  name: string;
  url?: string;
}
export interface BuildingLocation {
  lat: number;
  lng: number;
}

export interface LocationWithId extends BuildingLocation {
  locations?: BuildingLocation[];
  id: string;
  zoom?: number;
}

export interface CreateNeighborhood {
  name: string;
  market: string;
  locationBoundaries: BuildingLocation[];
}

export interface TourNeighbours {
  _id: string;
  name: string;
}
export interface GetNeighborhood {
  _id?: string;
  market: string;
  docs: TourNeighbours[];
}

export interface TourNeighborhoodList {
  _id: string;
  name: string;
  market: string;
}
