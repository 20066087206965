import { AxiosPromise } from 'axios';
import api from '../../utils/api.utils';
import { UploadedMediaInterface } from './media.types';

export const uploadMediaAPI = (media: FormData): AxiosPromise<UploadedMediaInterface[]> => {
  return api.post('/media', media);
};

export const uploadSingleMediaAPI = (
  media: FormData,
  handleProgress?: (e: number) => void,
): AxiosPromise<UploadedMediaInterface> => {
  return api.post('/media/single', media, {
    onUploadProgress: progressEvent => {
      if (progressEvent.lengthComputable && handleProgress) {
        handleProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      }
    },
  });
};
