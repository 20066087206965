import React from 'react';
import { useSelector } from 'react-redux';
import { selectSubdomainCompanyInfo } from '../../../../../../state/subdomain/building/building.selector';
import './index.css';

const currentYear = new Date().getFullYear();

const Footer = () => {
  const company = useSelector(selectSubdomainCompanyInfo());
  return (
    <div className="footer-container">
      <div className="footer-company-container">
        <span className="footer-company-name">{company?.name}</span>
        <div className="footer-contact-container">
          <span className="footer-address">{company?.settings.officialAddress}</span>
          <span className="footer-phone-number">{company?.settings.officialPhone}</span>
        </div>
      </div>
      <div className="footer-copyright">
        © {currentYear} {company?.name}
      </div>
    </div>
  );
};

export default Footer;
