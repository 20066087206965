import React, { forwardRef, useState } from 'react';
import { useMediaSize } from '../../state/media/media.hook';
import { compileAvatarUrl } from '../../utils/avatar.utils';
import { DEFAULT_MAX_IMG_HEIGHT, DEFAULT_MAX_IMG_WIDTH } from '../../common/constants';
import SmallLoader from '../common/small.loader';

interface Props {
  src: string;
  alt?: string;
  className?: string;
  default?: string;
  imgWidth?: number;
  imgHeight?: number;
}
const ImgTile = forwardRef<Props, any>((props, ref) => {
  const { src, alt, className, default: defaultImg, imgWidth, imgHeight } = props;

  const { reference, width: calculatedWidth, height: calculatedHeight } = useMediaSize();
  const [loaded, setLoaded] = useState(false);
  const loadHandler = () => {
    setLoaded(true);
  };
  const width = imgWidth || calculatedWidth;
  const height = imgHeight || calculatedHeight;
  return (
    <React.Fragment>
      {!loaded && (
        <div className="flex justify-center items-center w-100 h-100">{/* <SmallLoader /> */}</div>
      )}

      {src !== undefined && (
        <img
          style={{ display: loaded ? 'block' : 'none' }}
          src={
            src && width && height
              ? compileAvatarUrl(src, { width, height })
              : src
              ? compileAvatarUrl(src, {
                  width: Math.min(DEFAULT_MAX_IMG_WIDTH, window.innerWidth),
                  height: Math.min(DEFAULT_MAX_IMG_HEIGHT, window.innerHeight),
                })
              : defaultImg || ''
          }
          ref={ref || reference}
          alt={alt || 'image'}
          onLoad={loadHandler}
          className={className || ''}
        />
      )}
    </React.Fragment>
  );
});

export default ImgTile;
