import * as yup from 'yup';

const requiredField = 'This field is required';

export enum TourForm {
  firstName = 'firstName',
  lastName = 'lastName',
  // companyName = 'companyName',
  email = 'email',
  phone = 'phone',
  time = 'time',
  // brokerCompany = 'brokerCompany',
  questions = 'questions',
}

export enum ContactForm {
  name = 'name',
  email = 'email',
  message = 'message',
}

export const ContactFormSchema = yup.object().shape({
  [ContactForm.name]: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z ]*$/, 'Only alphabets & spaces are allowed')
    .required(requiredField),
  [ContactForm.email]: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required(requiredField),
  [ContactForm.message]: yup
    .string()
    .trim()
    .required(requiredField),
});

export const TourFormSchema = yup.object().shape({
  [TourForm.firstName]: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z ]*$/, 'Only alphabets & spaces are allowed')
    .required(requiredField),
  [TourForm.lastName]: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z ]*$/, 'Only alphabets & spaces are allowed')
    .required(requiredField),
  // [TourForm.companyName]: yup
  //   .string()
  //   .trim()
  //   .matches(/^[a-zA-Z ]*$/, 'Only alphabets & spaces are allowed')
  //   .required(requiredField),
  // [TourForm.brokerCompany]: yup
  //   .string()
  //   .trim()
  //   .matches(/^[a-zA-Z ]*$/, 'Only alphabets & spaces are allowed'),
  [TourForm.time]: yup.string().required(requiredField),
  [TourForm.email]: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required(requiredField),
  [TourForm.phone]: yup
    .string()
    .trim()
    .matches(/^\([0-9]{3}\)\s[0-9]{3}[-][0-9]{4}$/i, 'Allowed format: (234) 456-7890')
    .required(requiredField),
  [TourForm.questions]: yup
    .string()
    .trim()
    .required(requiredField),
});
